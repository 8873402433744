import React, { FC, useEffect } from 'react';
import Backround from './HomePages/Backround/Backround';
import HomePagination from './HomePages/HomePagination/HomePagination';
import NavigateCarsHome from './NavigateCarsHome/NavigateCarsHome';
import s from './Home.module.scss'
import NewsHome from './HomePages/NewsHome/NewsHome';
import PriceHome from './HomePages/PriceHome/PriceHome';
import { Helmet } from 'react-helmet-async';
import FreeSixMonths from './HomePages/FreeSixMonths/FreeSixMonths';

const Home: FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Helmet>
                <link rel='canonical' href='https://juk.kg' />
                <title>Главная | Juk.kg </title>
            </Helmet>
            <Backround />
            <HomePagination />
            <NavigateCarsHome />
            <div className={s.box}>
                <div className={s.column}>
                    <h1>Преимущество</h1>
                    <div className={s.display}>
                        <div className={s.firstDiv}>
                            <h2>Быстрый поиск</h2>
                            <p>Множество данных про грузы и транспорт</p>
                        </div>
                        <div className={s.secondDiv}>
                            <h2>Удобные цены</h2>
                            <p>Наши цены подойдут для каждого пользователя</p>
                        </div>
                        <div className={s.threeDiv}>
                            <h2>Актуальные</h2>
                            <p>Наша нашем сайте публикуются самые самые свежие новости</p>
                        </div>
                    </div>
                </div>
            </div>
            <NewsHome />
            <section className={s.Tariff}>
                <div className={s.box}>
                    <h3 className={s.pTarif}>Прайс</h3>
                    {/* <PriceHome /> */}
                    <FreeSixMonths />
                </div>
            </section>
        </div>
    );
};

export default Home;