import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CargoValidate, HomePageCargo, } from "../modules";
import { authApi } from "../../axios";

type NewsState = {
    loading: boolean
    error: null | string | undefined
    cargoSearch: HomePageCargo[]

}

const initialState: NewsState = {
    error: null,
    loading: false,
    cargoSearch: [],

}

export const fetchByCargoSearch = createAsyncThunk<HomePageCargo[], CargoValidate, { rejectValue: string }>(
    'cargoSearch/fetchByCargoSearch',
    async (cargoP, { rejectWithValue }) => {
        const res = await authApi.getOrdersSearch(cargoP)
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.results
    }
)

const cargoSearchSlice = createSlice({
    name: 'cargoSearch',
    initialState,
    reducers: {
        filterCargo(state, action: PayloadAction<HomePageCargo[]>) {
            state.cargoSearch = action.payload
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByCargoSearch.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByCargoSearch.fulfilled, (state, action) => {
            state.cargoSearch = action.payload.reverse()
            state.loading = false
        })

        addCase(fetchByCargoSearch.rejected, (state, action) => {


            state.loading = false
            if (action.error.message?.includes('40')) {
                state.error = 'К сожалению грузов нет!'
            }
            else if (action.error.message?.includes('50')) {
                state.error = 'Сервер на данный момент не работает!'
            }
        })

    }

})

export const { filterCargo } = cargoSearchSlice.actions

export default cargoSearchSlice.reducer
