import React, { FC, useState } from 'react';
import s from './BodyCard.module.scss'
import { typeLoadingContainerTrailer, typeOfTrailer } from '../../assets';
import { CarCreation, ErrorText } from '../../../../../../store/modules';
interface BodyCardProps {
    getCarCreation: (key: string, value: string) => void
    carCreation: CarCreation
    setTypeCargo: (e: string[]) => void
    typeCargo: string[]
    errorText: ErrorText
}
const BodyCard: FC<BodyCardProps> = ({ getCarCreation, carCreation, setTypeCargo, typeCargo, errorText }) => {
    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedBrands(prevSelectedBrands => [...prevSelectedBrands, value]);
            setTypeCargo([...typeCargo, value]);
        } else {
            setSelectedBrands(prevSelectedBrands =>
                prevSelectedBrands.filter(selectedBrand => selectedBrand !== value)
            );
            setTypeCargo([...typeCargo.filter(type => type !== value)])
        }
    };

    return (
        <div className={s.BodyCard}>
            <h2 className={s.title_body}><span>&#9913;</span>Прицеп</h2>
            <div className={s.input_field}>
                <div className={s.selection_fields}>
                    <h2>Тип Прицепа</h2>
                    <select value={carCreation.type_cargo} onChange={(e) => getCarCreation('type_cargo', e.target.value)} className={errorText.all.includes('Вы не указали тип прицепа!') ? `${s.select} ${s.errorText}` : s.select}>
                        <option disabled value="">Тип прицепа</option>
                        {
                            typeOfTrailer.map((el, i) => <option value={el} key={i}>{el} </option>)
                        }
                    </select>
                    <div className={s.kinds_cargo}>
                        <label><input onChange={e => getCarCreation('type_transport', e.target.value)} name="cars" type="radio" value={'Полуприцеп'} />Полуприцеп</label>
                        <label><input onChange={e => getCarCreation('type_transport', e.target.value)} name="cars" type="radio" value={'Сцепка'} />Сцепка</label>
                        <label><input onChange={e => getCarCreation('type_transport', e.target.value)} name="cars" type="radio" value={'Грузовик'} />Грузовик</label>
                    </div>
                </div>
                <div className={s.loading_type}>
                    <h2>Тип погрузки</h2>
                    <div className={errorText.all.includes('Вы не указали тип погрузки!') ? `${s.type_load} ${s.errorText}` : s.type_load}>
                        {typeLoadingContainerTrailer.map((el, i) => <label key={i}><input onChange={handleCheckboxChange} type="checkbox" value={el} />{el}</label>)}
                    </div>
                </div>
                <div className={s.lifting_capacity}>
                    <h2>Грузоподъемность, т</h2>
                    <input className={errorText.all.includes('Вы не указали грузоподъёмность!') ? s.errorText : errorText.lifting_capacity.includes('Вес должен быть менее 60т!') ? s.errorText : ''} value={carCreation.lifting_capacity} onChange={e => getCarCreation('lifting_capacity', e.target.value)} type="number" placeholder='до 60т' />
                </div>
                <div className={s.lifting_capacity}>
                    <h2>Объем, м³</h2>
                    <input className={errorText.volume.includes('Объём должно быть менее 200м³!') ? s.errorText : errorText.all.includes('Вы не указали объём!') ? s.errorText : ''} placeholder='до 200м³' value={carCreation.volume} onChange={e => getCarCreation('volume', e.target.value)} type="number" />
                </div>
                <div className={s.body_dimensions}>
                    <h2>Внутренние габариты кузова (Д/Ш/В), м</h2>
                    <div className={s.inputs}>
                        <input className={errorText.length && errorText.length.includes('Длина должно быть менее 15м') ? s.errorText : errorText.all.includes('Вы не указали длину!') ? s.errorText : ''} placeholder='до 15м' value={carCreation.length} onChange={e => getCarCreation('length', e.target.value)} type="number" />
                        <input className={errorText.width && errorText.width.includes('Ширина должно быть менее 4м') ? s.errorText : errorText.all.includes('Вы не указали ширину!') ? s.errorText : ''} placeholder='до 4м' value={carCreation.width} onChange={e => getCarCreation('width', e.target.value)} type="number" />
                        <input className={errorText.height && errorText.height.includes('Высота должно быть менее 4м') ? s.errorText : errorText.all.includes('Вы не указали высоту!') ? s.errorText : ''} placeholder='до 4м' value={carCreation.height} onChange={e => getCarCreation('height', e.target.value)} type="number" />
                    </div>
                </div>
                <p className={s.p_dimensions} >Введите длину, ширину и высоту внутреннего пространства кузова в метрах.</p>
            </div>

        </div>
    );
};

export default BodyCard;