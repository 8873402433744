import React, { FC, useState } from 'react'
import s from './ShowDocument.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hooks'
import Loading from '../../../../../components/Loading/Loading'

import { Link } from 'react-router-dom'
import { fetchByDeleteDocs } from '../../../../../store/slice/personalDataSlice'
import DocumentCard from './DocumentCard/DocumentCard'

interface ShowDocumentProps {
	setAddDoc: (e: boolean) => void
}
const ShowDocument: FC<ShowDocumentProps> = ({ setAddDoc }) => {
	const { documents, loading } = useAppSelector(state => state.personalArea)

	return (
		<div className={s.ShowDocument}>
			<div className={s.top_ShowDocument}>
				<h2>Мои Документы</h2>
				<button onClick={() => setAddDoc(true)}>Добавить Документ </button>
			</div>
			<div className={s.doc_output}>
				<div className={s.blue_bg}> </div>
				<div className={s.output_card}>
					{documents.length > 0 &&
						documents.map(el => <DocumentCard key={el.id} el={el} />)}
				</div>
			</div>
			{loading && <Loading />}
		</div>
	)
}

export default ShowDocument
