import React, { FC, useEffect } from 'react'
import s from './ListCar.module.scss'
import { Link } from 'react-router-dom'
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../store/hooks/hooks'
import optimus from '../../../../../assets/PersonalArea/optimus.png'
import Loading from '../../../../../components/Loading/Loading'
import { fetchByGetAutoPark, setAutoDel } from '../../../../../store/slice/personalDataSlice'

const ListCar: FC = () => {
	const { carPark, loading, loadingPlace, delAuto } = useAppSelector(state => state.personalArea)
	const { user } = useAppSelector(state => state.user)
	const dispatch = useAppDispatch()
	useEffect(() => {
		user?.truck_user_data?.id &&
			dispatch(fetchByGetAutoPark(user.truck_user_data.id))
	}, [dispatch, user, loadingPlace])
	useEffect(() => {
		dispatch(setAutoDel(false))
	}, [delAuto])
	if (loading) {
		return <Loading />
	}
	return (
		<div className={s.car_output}>
			<div className={s.blue_bg}> </div>
			<div className={s.output_card}>
				{carPark.length > 0 &&
					carPark.map(el => (
						<Link
							to={`/personal-area/detail_card?id=${el.id}`}
							key={el.id}
							className={s.car_card}
						>
							<div className={s.p_car}>
								<p>{el.model} </p>
								<p>{el.type_truck} </p>
								<p>{el.state_number} </p>
							</div>
							<div className={s.img_car}>
								<img src={(el.image && el.image.startsWith('http')) ? `https${el.image.slice(4)}` : optimus} alt='car' />
							</div>
						</Link>
					))}
			</div>
		</div>
	)
}

export default ListCar
