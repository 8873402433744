import React, { FC, useEffect } from 'react';
import s from './SuccesfullCreation.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hooks';
import { setCreation, setDocsCreation, setErrorChange } from '../../../../../store/slice/personalDataSlice';
interface SuccesfullCreationProps {
    text: string
}
const SuccesfullCreation: FC<SuccesfullCreationProps> = ({ text }) => {
    const dispatch = useAppDispatch()
    const { error, dataCreation, docsCreation } = useAppSelector(state => state.personalArea)
    const handleCreation = () => {
        dispatch(setCreation(false))
        dispatch(setErrorChange(null))
        dispatch(setDocsCreation(false))
    }
    useEffect(() => {
        return () => {
            window.location.reload();
        }
    }, [])

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && handleCreation()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (dataCreation || error) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => handleCreation();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!dataCreation || error) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])
    return (
        <div onClick={handleCreation} className={`${s.SuccesfullCreation} ${s.backr}`}>
            <div onClick={(e) => e.stopPropagation()} className={(error?.includes('Упс что то пошло не так попробуйте снова!') || error?.includes('Данные не правильно введены!')) ? s.NotSuccessfull : s.box}>
                <span onClick={handleCreation} className={s.closed}>&#10006;</span>
                {(error?.includes('Упс что то пошло не так попробуйте снова!') || error?.includes('Данные не правильно введены!')) && <h2 className={s.textError}>{error} </h2>}
                {(dataCreation || docsCreation) && <h2 className={s.title_text}>{text} </h2>}
            </div>
        </div >
    );
};

export default SuccesfullCreation;