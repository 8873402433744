import React, { FC, useEffect, useState } from 'react';
import s from '../HistoryOfOrders.module.scss'
import { OrderStatus } from '../../../../../store/modules';
import deletedImg from '../../../../../assets/PersonalArea/deleted.png'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hooks';
import Delete from '../../../Delete/Delete';
import SuccesfullCreation from '../../CreateOrder/SuccesfullCreation/SuccesfullCreation';
import { fetchByDeleteCargo } from '../../../../../store/slice/personalDataSlice';
interface HistoryCargoCardrProps {
  el: OrderStatus,
  i: number
}
const HistoryCargoCard: FC<HistoryCargoCardrProps> = ({ el, i }) => {
  const { token } = useAppSelector(state => state.user)
  const dispatch = useAppDispatch()
  const { error, docsCreation } = useAppSelector(state => state.personalArea)
  const [delDocs, setDelDocs] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const deleteDocs = () => {
    setDelDocs(true)
  }
  useEffect(() => {
    if (deleted && token) {
      dispatch(fetchByDeleteCargo({ id: el.id, token }))
    }
  }, [deleted])
  return (
    <div className={i % 2 === 0 ? s.card_bg : s.card}>
      <div className={s.card_left}>
        <p>{el.city_country} {el.address} &#8594; {el.city_country_u} {el.address_u}</p>
        <p>{el.date}</p>
      </div>
      <div className={s.card_right}>
        <p>{el.type_cargo}</p>
        <p>{el.price}</p>
      </div>
      <div className={s.deleted} >
        <img onClick={deleteDocs} src={deletedImg} alt="basket" />
      </div>
      {delDocs && <Delete text={'Вы точно хотите удалить этот груз'} setDeleted={setDeleted} delDocs={delDocs} setDelDocs={setDelDocs} name={'груз'} />}
      {(docsCreation || error?.includes('Упс что то пошло не так попробуйте снова!')) && <SuccesfullCreation text={'Груз успешно удалён'} />}

    </div>
  );
};

export default HistoryCargoCard;