import React, { FC } from 'react';
import s from './RouteCard.module.scss'
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { ErrorText } from '../../../../../../store/modules';
import { options } from '../../../../../../other';
interface RouteCardProps {
    errorText: ErrorText
    setFromWhere: (e: string) => void
    setToWhere: (e: string) => void

}
const RouteCard: FC<RouteCardProps> = ({ errorText, setFromWhere, setToWhere }) => {
    return (
        <div className={s.RouteCard}>
            <h2 className={s.title_route}><span>&#9913;</span>Маршрут</h2>
            <div className={s.input_field}>
                <div className={s.where}>
                    <h2>Откуда (пункт)</h2>
                    <ReactGoogleAutocomplete
                        options={options}
                        className={errorText.all.includes('Вы не указали город отбывание!') ? `${s.country_city} ${s.errorText} ` : s.country_city}
                        onPlaceSelected={(place) => setFromWhere(place.formatted_address)}
                    />
                </div>
                <div className={s.where}>
                    <h2>Куда (пункт)</h2>
                    <ReactGoogleAutocomplete
                        options={options}
                        className={errorText.all.includes('Вы не указали город прибывание!') ? `${s.country_city} ${s.errorText} ` : s.country_city}
                        onPlaceSelected={(place) => setToWhere(place.formatted_address)}
                    />
                </div>
            </div>
        </div>
    );
};

export default RouteCard;