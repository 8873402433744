import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddAllDocsAuto, BrandCar, CarsName, DataForCarCreation, DataForCargoCreation, DeletedDocument, ForAddCar, ForAddDocs, GetAutoPark, GetDocument, OrderStatus, } from '../modules'
import { authApi } from '../../axios'

type PersonalDataState = {
	loading: boolean
	delAuto: boolean
	changeAuto: boolean
	loadingPlace: boolean
	docsCreation: boolean
	error: null | string
	rendering: boolean
	dataCreation: boolean
	carPark: GetAutoPark[]
	detailCar: GetAutoPark | null
	brand: BrandCar[]
	documents: GetDocument[]
	comentsStatus: OrderStatus[]
}

const initialState: PersonalDataState = {
	error: null,
	delAuto: false,
	changeAuto: false,
	loadingPlace: false,
	loading: false,
	docsCreation: false,
	rendering: false,
	dataCreation: false,
	carPark: [],
	detailCar: null,
	brand: [],
	documents: [],
	comentsStatus: [],
}

// Для создания заказ груза

export const fetchByDataForCargoCreation = createAsyncThunk<void, DataForCargoCreation, { rejectValue: string }>(
	'PersonalData/fetchDataForCargoCreation',
	async (forCargoCraetion, { rejectWithValue }) => {
		const res = await authApi.addCargoCreation(forCargoCraetion)
		console.log(res)
		if (res.status !== 201) {
			return rejectWithValue('Server error')
		}
	}
)
// для удаления груза

export const fetchByDeleteCargo = createAsyncThunk<void, DeletedDocument, { rejectValue: string }>(
	'PersonalData/fetchByDeleteCargo', async (ids, { rejectWithValue }) => {
		const res = await authApi.deleteCargo(ids)
	})

// Для создания заказ машины
export const fetchByDataForCarCreation = createAsyncThunk<void, DataForCarCreation, { rejectValue: string }>(
	'PersonalData/fetchByDataForCarCreation',
	async (forCarCreation, { rejectWithValue }) => {
		const res = await authApi.getDataForCarCreation(forCarCreation)
		// console.log(res)
		if (res.status !== 201) {
			return rejectWithValue('Server error')
		}
	}
)

// Для получения автопарка
export const fetchByGetAutoPark = createAsyncThunk<GetAutoPark[], number, { rejectValue: string }>(
	'PersonalData/fetchByGetAutoPark', async (id, { rejectWithValue }) => {
		const res = await authApi.getAutoPark(id)
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server error')
		}
		return res.data.results
	})

export const fetchByGetDetailAuto = createAsyncThunk<GetAutoPark, string, { rejectValue: string }>(
	'PersonalData/fetchByGetDetailAuto', async (id, { rejectWithValue }) => {
		const res = await authApi.getDetailAuto(id)
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server error')
		}
		return res.data as GetAutoPark
	})
// Для изменения машины
export const fetchByDataForChangeCreation = createAsyncThunk<void, DeletedDocument, { rejectValue: string }>(
	'PersonalData/fetchByDataForChangeCreation',
	async (changeAuto, { rejectWithValue }) => {
		const res = await authApi.changeCars(changeAuto)
		// console.log(res);

	}
)
// Для удаления машины
export const fetchByDataForDeleteCreation = createAsyncThunk<void, DeletedDocument, { rejectValue: string }>(
	'PersonalData/fetchByDataForDeleteCreation',
	async (forCarCreation, { rejectWithValue }) => {
		const res = await authApi.deleteCars(forCarCreation)
	}
)
// для добавление документа в машину
export const fetchByAddDocsAuto = createAsyncThunk<void, AddAllDocsAuto, { rejectValue: string }>(
	'PersonalData/fetchByAddDocsAuto', async (Alldocs, { rejectWithValue }) => {
		const res = await authApi.addDocsAuto(Alldocs)
		// console.log(res)
		// if (res.status !== 200) {
		//     return rejectWithValue('Server error')
		// }
	})

// для удаления документа в машину
export const fetchByDeleteDocsAuto = createAsyncThunk<void, DeletedDocument, { rejectValue: string }>(
	'PersonalData/fetchByDeleteDocsAuto', async (Alldocs, { rejectWithValue }) => {
		const res = await authApi.deleteCarsDocs(Alldocs)
		// console.log(res)
	})

// для получения марки

export const fetchByGetBrand = createAsyncThunk<BrandCar[], void, { rejectValue: string }>(
	'PersonalData/fetchByGetBrand', async (_, { rejectWithValue }) => {
		const res = await authApi.getBrand()
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server error')
		}
		return res.data
	})

// для создания авто парка

export const fetchByAddCAr = createAsyncThunk<void, ForAddCar, { rejectValue: string }>(
	'PersonalData/fetchByAddCAr', async (forAddCar, { rejectWithValue }) => {
		const res = await authApi.postAddCar(forAddCar)
		// console.log(res)
		if (res.status !== 201) {
			return rejectWithValue('Server error')
		}
	})

// для получения всех документов

export const fetchByGetDocument = createAsyncThunk<GetDocument[], number, { rejectValue: string }>(
	'PersonalData/fetchByGetDocument', async (id, { rejectWithValue }) => {
		const res = await authApi.getDocuments(id)
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server error')
		}
		return res.data.results
	})

// для создания документа

export const fetchByAddDocs = createAsyncThunk<void, ForAddDocs, { rejectValue: string }>(
	'PersonalData/fetchByAddDocs', async (forAddDocs, { rejectWithValue }) => {
		const res = await authApi.postAddDocs(forAddDocs)
		// console.log(res)
		if (res.status !== 201) {
			return rejectWithValue('Server error')
		}
	})
// для удаления документа

export const fetchByDeleteDocs = createAsyncThunk<void, number, { rejectValue: string }>(
	'PersonalData/fetchByDeleteDocs', async (id, { rejectWithValue }) => {
		const res = await authApi.deleteAddDocs(id)
		// console.log(res)
		// if (res.status !== 201) {
		// 	return rejectWithValue('Server error')
		// }
	})

// для получения истории груза
export const fetchByHistoryOrder = createAsyncThunk<OrderStatus[], DeletedDocument, { rejectValue: string }>(
	'PersonalData/fetchByHistoryOrder', async (orderHis, { rejectWithValue }) => {
		const res = await authApi.getOrdersStatus(orderHis)
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server Error')
		}
		return res.data.results
	})
const PersonalDataSlice = createSlice({
	name: 'PersonalData',
	initialState,
	reducers: {
		setErrorChange(state, action: PayloadAction<string | null>) {
			state.error = action.payload
		},
		setCreation(state, action: PayloadAction<boolean>) {
			state.dataCreation = action.payload
		},
		setDocsCreation(state, action: PayloadAction<boolean>) {
			state.docsCreation = action.payload
			state.loadingPlace = false
			state.changeAuto = false
		},
		setAutoDel(state, action: PayloadAction<boolean>) {
			state.delAuto = action.payload
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(fetchByDataForCargoCreation.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByDataForCargoCreation.fulfilled, (state, action) => {
			state.loading = false
			state.dataCreation = true
		})
		addCase(fetchByDataForCargoCreation.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('404')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			} else if (action.error.message?.includes('400')) {
				state.error = 'Данные не правильно введены!'
			} else if (action.error.message?.includes('50')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})
		// ==========
		addCase(fetchByDataForCarCreation.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByDataForCarCreation.fulfilled, (state, action) => {
			state.loading = false
			state.dataCreation = true
		})
		addCase(fetchByDataForCarCreation.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('404')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			} else if (action.error.message?.includes('400')) {
				state.error = 'Данные не правильно введены!'
			}
		})
		// ===================
		addCase(fetchByGetAutoPark.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByGetAutoPark.fulfilled, (state, action) => {
			state.loading = false
			state.carPark = action.payload.reverse()
		})
		addCase(fetchByGetAutoPark.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('400')) {
				state.error = 'Ничего не найдено.'
			}
		})
		// ================
		addCase(fetchByGetDetailAuto.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByGetDetailAuto.fulfilled, (state, action) => {
			state.loading = false
			state.detailCar = action.payload
		})
		addCase(fetchByGetDetailAuto.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('400')) {
				state.error = 'Ничего не найдено.'
			}
		})
		// ================
		addCase(fetchByAddDocsAuto.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByAddDocsAuto.fulfilled, (state, action) => {
			state.loading = false
			state.dataCreation = true
		})
		addCase(fetchByAddDocsAuto.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})
		// ==============
		addCase(fetchByGetBrand.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByGetBrand.fulfilled, (state, action) => {
			state.loading = false
			state.brand = action.payload
		})
		addCase(fetchByGetBrand.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('400')) {
				state.error = 'Упс что то пошло не так!'
			}
		})

		// ==============
		addCase(fetchByAddCAr.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByAddCAr.fulfilled, state => {
			state.loading = false
			state.dataCreation = true
		})
		addCase(fetchByAddCAr.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})

		// ==================
		addCase(fetchByGetDocument.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByGetDocument.fulfilled, (state, action) => {
			state.loading = false
			state.documents = action.payload
		})
		addCase(fetchByGetDocument.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так!'
			}
		})

		// ==============
		addCase(fetchByAddDocs.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByAddDocs.fulfilled, state => {
			state.loading = false
			state.dataCreation = true
		})
		addCase(fetchByAddDocs.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})

		// ==========
		addCase(fetchByDeleteDocs.pending, state => {
			state.loading = true
			state.error = null
			state.loadingPlace = true
		})
		addCase(fetchByDeleteDocs.fulfilled, state => {
			state.loading = false
			state.docsCreation = true
		})
		addCase(fetchByDeleteDocs.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})

		// ============
		addCase(fetchByHistoryOrder.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByHistoryOrder.fulfilled, (state, action) => {
			state.loading = false
			state.comentsStatus = action.payload.reverse()
		})
		addCase(fetchByHistoryOrder.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так!'
			}
		})

		// ==========
		addCase(fetchByDataForDeleteCreation.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByDataForDeleteCreation.fulfilled, state => {
			state.loading = false
			state.docsCreation = true
			state.delAuto = true
		})
		addCase(fetchByDataForDeleteCreation.rejected, (state, action) => {
			state.loading = false
			if (action.error.message) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})
		// ==========
		addCase(fetchByDataForChangeCreation.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByDataForChangeCreation.fulfilled, state => {
			state.loading = false
			state.changeAuto = true
		})
		addCase(fetchByDataForChangeCreation.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})
		// ==========
		addCase(fetchByDeleteCargo.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByDeleteCargo.fulfilled, state => {
			state.loading = false
			state.docsCreation = true
		})
		addCase(fetchByDeleteCargo.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})
		// ==========
		addCase(fetchByDeleteDocsAuto.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByDeleteDocsAuto.fulfilled, state => {
			state.loading = false
			state.loadingPlace = true
		})
		addCase(fetchByDeleteDocsAuto.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})
	},
})

export const { setErrorChange, setCreation, setDocsCreation, setAutoDel } = PersonalDataSlice.actions

export default PersonalDataSlice.reducer
