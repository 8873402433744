import React, { FC, useEffect } from 'react';
import s from './Registration.module.scss'
import Frame from '../../assets/Registration/Frame.png'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { toggleRedirect } from '../../store/slice/userSlice';
import Loading from '../../components/Loading/Loading';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import { Helmet } from 'react-helmet-async';


const Registration: FC = () => {
    const dispatch = useAppDispatch()
    const { redirect, loading } = useAppSelector(state => state.user)

    useEffect(() => {
        return () => {
            if (redirect) {
                dispatch(toggleRedirect(false))
            }
        }
    }, [dispatch, redirect])

    if (loading) {
        return <Loading />
    }
    return (
        <section className={s.Registration}>
            <Helmet>
                <link rel='canonical' href='https://juk.kg/registration' />
                <title>Регистрация | Juk.kg </title>
            </Helmet>
            <div className={s.box}>
                <RegistrationForm />
                <img className={s.scania} src={Frame} alt="scania" />
            </div>

        </section >
    );
};

export default Registration;