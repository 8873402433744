import React, { FC } from 'react';
import s from './InputPassword.module.scss'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface Props {
    text: string
    value: string
    meaning: string
    keys: string
    getUserData: (key: string, value: string) => void
    setShowPassword: (e: boolean) => void
    showPassword: boolean
}

const InputPassword: FC<Props> = ({ text, meaning, keys, getUserData, setShowPassword, showPassword, value }) => {

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <div className={s.InputPassword}>
            <h4 className={text.includes('Длина пароля должна') || text.includes('Пароли не совпадают') ? s.error_textInput : s.textInput}>{text}</h4>
            <FormControl sx={{ width: '100%' }} variant='outlined'>
                <InputLabel className={s.asdf} htmlFor='outlined-adornment-password'>
                    {meaning}
                </InputLabel>
                <OutlinedInput
                    onChange={e => getUserData(keys, e.target.value)}
                    className={s.input_password}
                    value={value}
                    // id='outlined-adornment-password'
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label='Password'
                />
            </FormControl>
        </div>
    );
};

export default InputPassword;