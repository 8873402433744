import React, { FC } from 'react';
import s from './PaymentHistory.module.scss'

const PaymentHistory: FC = () => {
    return (
        <div className={s.PaymentHistory}>
            <div className={s.payment}>
                <p>Дата</p>
                <p>Стоимость</p>
                <p>Тип</p>
                <p>Активно</p>
            </div>
            <div className={s.output}>
                <h2 className={s.title_empty}>Пока что пусто</h2>
            </div>
        </div>
    );
};

export default PaymentHistory;