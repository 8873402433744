import React, { FC } from 'react';
import s from './Authorization.module.scss';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { setEnter } from '../../../store/slice/userSlice';

const Authorization: FC = () => {
    const dispatch = useAppDispatch()
    const { enter } = useAppSelector(state => state.user)
    const handleLogin = () => {
        dispatch(setEnter(!enter))
    }
    return (
        <div className={s.authorization}>
            <button onClick={handleLogin} className={s.enter}>Вход </button>
            <NavLink className={s.registration} to={'/registration'} ><button className={s.registr}>Регистрация</button></NavLink>
        </div>
    );
};

export default Authorization; 