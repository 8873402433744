import React, { FC, useState } from 'react';
import s from './CarsPagination.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { filterCars } from '../../store/slice/carsSearchSlice';
import CarsSearchCard from './CarsSearchCard';

const CarsPagination: FC = () => {
    const { carsSearch, error } = useAppSelector(state => state.carsSearchTs)

    const dispatch = useAppDispatch()
    const [cargoId, setCargoId] = useState<number | null>(null)

    const handleFilter = (value: string) => {
        value.includes('date') &&
            dispatch(filterCars([...carsSearch].sort((a, b) => +new Date(b.created_at).getTime() - +new Date(a.created_at).getTime())))
        value.includes('price') &&
            dispatch(filterCars([...carsSearch].sort((a, b) => +b.price - +a.price)))
        value.includes('weight') &&
            dispatch(filterCars([...carsSearch].sort((a, b) => b.lifting_capacity - a.lifting_capacity)))
        value.includes('daterevers') &&
            dispatch(filterCars([...carsSearch].sort((a, b) => +new Date(a.created_at).getTime() - +new Date(b.created_at).getTime())))
        value.includes('pricerevers') &&
            dispatch(filterCars([...carsSearch].sort((a, b) => +a.price - +b.price)))
        value.includes('weightrevers') &&
            dispatch(filterCars([...carsSearch].sort((a, b) => a.lifting_capacity - b.lifting_capacity)))
    }

    return (
        <div className={s.disk}>
            <div className={s.displayFlex}>
                <h1 className={s.carsSearch}>Найдено {carsSearch.length === 0 ? 0 : carsSearch.length} Машин</h1>
                <div className={s.sel}>
                    <p>Упорядочить по</p>
                    <select onChange={(e) => handleFilter(e.target.value)}>
                        <option value="date">новой дате</option>
                        <option value="daterevers">старой дате</option>
                        <option value="price">наивысшей цене</option>
                        <option value="pricerevers">наименшей цене</option>
                        <option value="weight">наибольшему весу</option>
                        <option value="weightrevers">наименьшему весу</option>
                    </select>
                </div>
            </div>
            <div >
                <div className={s.FiveP}>
                    <p>Направление</p>
                    <p>Маршрут</p>
                    <p>Дата</p>
                    <p>Груз</p>
                    <p>Стоимость</p>
                </div>
                <div className={s.ul}>
                    {
                        error ?
                            <span className='error animate__backOutUp animate__animated'>{error}</span>
                            :
                            carsSearch && carsSearch?.length > 0 ?
                                carsSearch.map((el, i) => (
                                    <CarsSearchCard key={i} el={el} setCargoId={setCargoId} cargoId={cargoId} i={i} />
                                ))
                                :
                                <h2>Грузов нет</h2>
                    }
                </div>
            </div>
        </div >


    );
};

export default CarsPagination;