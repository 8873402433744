import React, { FC, useEffect, } from 'react';
import s from './NotFound.module.scss';
import notFound from '../../assets/404/4044.png'
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';


const NotFount: FC = () => {
    // const navigate = useNavigate()

    // useEffect(() => {
    //     setTimeout(() => {
    //         navigate('/', { replace: true })
    //     }, 4000)
    // }, [navigate])
    return (
        <div className={s.not_found}>
            <Helmet>
                <link rel='canonical' href='http://juk.kg/*' />
                <title>404 | Juk.kg </title>
            </Helmet>
            <div className={s.box}>
                <img src={notFound} alt="404" />
                <p>К сожалению, запрашиваемая страница не найдена.
                    Возможно, она была перемещена, удалена или временно недоступна.
                </p>
            </div>
        </div>
    );
};

export default NotFount;