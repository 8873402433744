import React, { FC } from 'react'
import s from '../PersonalData.module.scss'
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../store/hooks/hooks'
import { fetchByBecomeCC } from '../../../../../store/slice/userSlice'
interface CarParkPAProps {
	setCarsPark: (e: boolean) => void
}

const CarParkPA: FC<CarParkPAProps> = ({ setCarsPark }) => {
	const dispatch = useAppDispatch()
	const { user, token } = useAppSelector(state => state.user)
	const { carPark } = useAppSelector(state => state.personalArea)
	const hendleBecomeCargoCarrier = () => {
		token && user && dispatch(fetchByBecomeCC({ token, id: user.id }))
	}
	return (
		<div className={s.information}>
			<div className={s.car_park}>
				<div className={s.top_car_park}>
					<h2>Мой Автопарк</h2>
					{user?.truck_user_data === null ? (
						<button onClick={hendleBecomeCargoCarrier}>Стать водителем</button>
					) : (
						<span onClick={() => setCarsPark(true)} className={s.plus_car}>
							+
						</span>
					)}
				</div>
				{user?.truck_user_data !== null && (
					<div className={s.output}>
						{carPark.length > 0 &&
							carPark.map((el, i) => (
								<div key={el.id} className={s.card_car}>
									<h3>№{i + 1}</h3>
									<p>{el.model}</p>
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	)
}

export default CarParkPA
