import React, { FC, useEffect, useState } from 'react';
import { HomePageCargo, LoadingType, } from '../../store/modules';
import s from './CargoSearchCard.module.scss'
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks/hooks';
import OpenImgCargo from './OpenImgCargo/OpenImgCargo';
import telegram from '../../assets/Header/mobileHeader/telegram.png'
import whatsApp from '../../assets/Header/mobileHeader/whatsApp.png'
import viber from '../../assets/DetailView/viber.png'
import CommetsModal from './CommetsModal/CommetsModal';

interface OpenAvatarProps {
    setCargoId: (e: number | null) => void
    el: HomePageCargo
    cargoId: number | null
    i: number
}

const CargoSearchCard: FC<OpenAvatarProps> = ({ el, setCargoId, cargoId, i }) => {
    const { token } = useAppSelector(state => state.user)
    const [comment, setComment] = useState(false)
    const [switchAvatar, setSwitchAvatar] = useState(false)
    const [loadings, setLoadings] = useState<LoadingType[]>([])
    const [unLoading, setUnLoading] = useState<LoadingType[]>([])

    useEffect(() => {
        const arr = []
        // Проверяем наличие нужных ключей напрямую без итерации
        if (el.loading_address1 !== null) {
            arr.push(el.loading_address1)
        }
        if (el.loading_address2 !== null) {
            arr.push(el.loading_address2)
        }
        if (el.loading_address3 !== null) {
            arr.push(el.loading_address3)
        }
        if (el.loading_address4 !== null) {
            arr.push(el.loading_address4)
        }
        if (el.loading_address5 !== null) {
            arr.push(el.loading_address5)
        }
        setLoadings(arr)
    }, [el]);

    useEffect(() => {
        const arr = []
        // Проверяем наличие нужных ключей напрямую без итерации
        if (el.unloading_address1 !== null) {
            arr.push(el.unloading_address1)
        }
        if (el.unloading_address2 !== null) {
            arr.push(el.unloading_address2)
        }
        if (el.unloading_address3 !== null) {
            arr.push(el.unloading_address3)
        }
        if (el.unloading_address4 !== null) {
            arr.push(el.unloading_address4)
        }
        if (el.unloading_address5 !== null) {
            arr.push(el.unloading_address5)
        }
        setUnLoading(arr)
    }, [el]);

    return (
        <div key={el?.id} onClick={() => setCargoId(el.id === cargoId ? null : el.id)} className={i % 2 === 0 ? s.map_black : s.map}>
            <div className={(el.id === cargoId) ? s.cargo_search_card_open : s.cargo_search_card}>
                <div className={s.mapdd}>
                    <div className={s.firstDiv}>
                        <p>{`${el.city_country} ${el.address}`}</p>
                        <p>{`${el.city_country_u} ${el.address_u}`}</p>
                        <p>{el.date ? el.date : '-'}</p>
                    </div>
                    <div className={s.secondDiv}>
                        <p>{el.type_cargo} {el.weight}/т</p>
                        <p>{el.price}</p>
                    </div>
                </div>
                <div className={s.spaceBetwen}>
                    {token ?
                        <div className={s.cargo_card}>
                            <div className={s.adres_in_card}>
                                <div className={s.from_adres}>{loadings.map((el, i) => (
                                    <p key={i}>{`${i + 1} ${el.city_country}, ${el.address}`}</p>
                                ))} </div>
                                {(loadings.length > 0 || unLoading.length > 0) && <span className={s.array}>→</span>}
                                <div className={s.to_adres}>{unLoading.map((el, i) => (
                                    <p key={i}>{`${i + 1} ${el.city_country}, ${el.address}`}</p>
                                ))} </div>
                            </div>
                            <div className={s.info_field}>
                                <div className={s.left_card}>
                                    {el.note.length > 60 ? <p onClick={() => setComment(true)} title={el.note}>{`${el.note.slice(0, 60)}...`}</p> : el.note ? <p>{el.note}</p> : <p title={el.note}>Комментарий нет</p>}
                                </div>
                                <div className={s.card_right}>
                                    <div onClick={(e) => e.stopPropagation()}>
                                        {el.image && <p className={s.cursorPoint} onClick={() => setSwitchAvatar(true)}>Просмотр фото</p>}
                                        {el.whatsapp ? <a href={`https://wa.me/${el.whatsapp.startsWith('+') ? el.whatsapp.slice(1) : el.whatsapp}?text=Здравствуйте,%20я%20на%20счет%20груза/транспорта%20на%20сайте%20juk.kg.`}><img src={whatsApp} alt="whatsApp" /></a> : <p>WhatsApp не указано</p>}
                                        {el.telegram ? <a href={el?.telegram.startsWith('https') ? el?.telegram : `https://t.me/${el.telegram.startsWith('+') ? el.telegram : el.telegram.startsWith('@') ? el.telegram.slice(1) : el.telegram}`}><img src={telegram} alt="telegram" /></a> : <p>Telegram не указано</p>}
                                        {el.viber ? <a href={`viber://chat?number=%2B${el.viber.startsWith('+') ? el.viber.slice(1) : el.viber}`}><img src={viber} alt="viber" /></a> : <p>Viber не указано</p>}
                                    </div>
                                    <NavLink to={`/detailview/${el.user}`}>
                                        <p>Перейти к пользователю</p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={s.dopReg}>
                            <p className={s.reminder}>
                                Нужно зарегистрироваться что-бы увидеть доп-информацию!
                            </p>
                        </div>
                    }
                </div>
            </div>
            {switchAvatar && <OpenImgCargo image={el.image} switchAvatar={switchAvatar} setSwitchAvatar={setSwitchAvatar} />}
            {comment && <CommetsModal setComment={setComment} comment={comment} text={el.note} />}
        </div>
    );
};

export default CargoSearchCard;