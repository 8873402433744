
import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import s from './DetailedView.module.scss'
import { useParams } from 'react-router-dom';
import nullImg from '../../../assets/DetailView/nullImg.png'
import bottom from '../../../assets/DetailView/bottom.png'
import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchByComments, fetchByDetailView, fetchByRating } from '../../../store/slice/detailView';
import { CommentsDetail, RatingsDetail } from '../../../store/modules';
import { fetchByDetailOrder } from '../../../store/slice/comentsStatus';
import phone from '../../../assets/DetailView/phone.png'
import telegramm from '../../../assets/Header/mobileHeader/telegram.png'
import whatsapp from '../../../assets/Header/mobileHeader/whatsApp.png'
import viber from '../../../assets/DetailView/viber.png'


const DetailedView: FC = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const { detailView, redirect } = useAppSelector(state => state.detailViewTs)
    const { comentsStatus, } = useAppSelector(state => state.comentsStatusTs)
    const { token } = useAppSelector(state => state.user)
    const [btn, setBtn] = useState(true)
    const [button, setButton] = useState(false)
    const [start, setStart] = useState<RatingsDetail>({
        rating: 0,
        user_id: 1,
        on_whom: id ? +id : 0
    })
    const [coment, setComent] = useState<CommentsDetail>({
        text: '',
        user_id: id ? +id : 0,
    })

    const handleRating = () => {
        token &&
            dispatch(fetchByRating({ token, raiting: start }))
        setButton(false)
    }

    const handleComments: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        if (token && coment.text) {
            dispatch(fetchByComments({ token, coment }))
            setComent({ ...coment, text: '' })
        }
    }

    const rating = (id: number) => {
        setStart({ ...start, 'rating': id })
        setButton(true)
    }
    useEffect(() => {
        if (id) {
            dispatch(fetchByDetailView(id))
        }
    }, [dispatch, id])
    useEffect(() => {
        redirect && window.location.reload()
    }, [redirect])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        token && id &&
            dispatch(fetchByDetailOrder({ token, id: +id }))
    }, [dispatch, token])

    return (
        <section className={s.box}>
            <Helmet>
                <link rel='canonical' href={`https://juk.kg/detailview/${id}`} />
                <title>Грузы | Juk.kg</title>
            </Helmet>
            <div className={s.container}>
                {
                    <div className={s.profileDisplay}>
                        <div className={s.nameImg}>
                            <img className={s.profileImg} src={detailView?.img?.startsWith('http') ? `https${detailView?.img.slice(4)}` : nullImg} alt="img" />
                        </div>
                        <div className={s.nameDiv}>
                            <h1>{detailView?.username}</h1>
                            <div className={s.spans}>
                                <span className={start.rating >= 1 ? s.active_stae : s.not_active} onClick={() => rating(1)}>★</span>
                                <span className={start.rating >= 2 ? s.active_stae : s.not_active} onClick={() => rating(2)}>★</span>
                                <span className={start.rating >= 3 ? s.active_stae : s.not_active} onClick={() => rating(3)}>★</span>
                                <span className={start.rating >= 4 ? s.active_stae : s.not_active} onClick={() => rating(4)}>★</span>
                                <span className={start.rating >= 5 ? s.active_stae : s.not_active} onClick={() => rating(5)}>★</span>
                                {button && <button onClick={handleRating}>OK</button>}
                            </div>
                            <div className={s.phone}>
                                <div className={s.phoneDiv}>
                                    <img src={phone} alt="phone" />
                                    <a href={detailView?.phone}>{detailView?.phone}</a>
                                </div>
                                <div className={s.co}>
                                    {detailView?.whatsapp ? <a href={detailView?.whatsapp ? `https://wa.me/${detailView?.whatsapp.startsWith('+') ? detailView?.whatsapp.slice(1) : detailView?.whatsapp}?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.` : ''}><img src={whatsapp} alt="whatsapp" /></a> : ''}
                                    {detailView?.telegram ? <a href={detailView?.telegram.startsWith('https') ? detailView?.telegram : `https://t.me/${detailView.telegram.startsWith('+') ? detailView.telegram : detailView.telegram.startsWith('@') ? detailView.telegram.slice(1) : detailView.telegram}`}><img src={telegramm} alt="telegramm" /></a> : ""}
                                    {detailView?.viber ? <a href={`viber://chat?number=%2B${detailView.viber.startsWith('+') ? detailView.viber.slice(1) : detailView.viber}`}><img src={viber} alt="viber" /></a> : ''}
                                </div>
                            </div>
                            <form onClick={handleComments} className={s.otzyv}>
                                <input value={coment.text} onChange={(e) => setComent({ ...coment, text: e.target.value })} type="text" placeholder='Оставьте отзыв' />
                                {coment.text.length > 0 && <button >Оставить</button>}
                            </form>
                            <div className={btn ? s.bottom : s.close_bottom}>
                                <div className={s.top_comments}>
                                    <h2>Отзывы</h2>
                                    <img onClick={() => setBtn(!btn)} src={bottom} alt="" />
                                </div>
                                <div className={s.open} >
                                    {detailView?.comments.map((el, i) => (
                                        <div key={i}>
                                            <p>{el.text}</p>
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={s.zakaz}>
                    <h2>Выполненные заказы</h2>
                    <div>
                        <div className={s.FiveP}>
                            <p>Направление</p>
                            <p>Маршрут</p>
                            <p>Дата</p>
                            <p>Груз</p>
                            <p>Стоимость</p>
                        </div>
                        <div className={s.ul}>
                            {comentsStatus.map((el, i) => (
                                <div key={i} className={i % 2 === 0 ? s.mapBlack : s.map} >
                                    <div className={s.mapdd}>
                                        <div className={s.firstDiv}>
                                            <div className={s.mapdd}>
                                                <p>{el.city_country}</p>
                                                <p>{el.address}</p>
                                            </div>
                                            <div>
                                                <p>{el.city_country_u}</p>
                                                <p>{el.address_u}</p>
                                            </div>
                                            <div>
                                                <p>{el.date}</p>
                                            </div>
                                        </div>
                                        <div className={s.secondDiv}>
                                            <div>
                                                <p>{el.type_cargo}</p>
                                                <p></p>
                                            </div>
                                            <div>
                                                <p>{el.price}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default DetailedView;