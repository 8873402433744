import React, { FC } from 'react';
import s from './InputText.module.scss'
import { TextField } from '@mui/material';

interface Props {
    text: string
    value: string
    meaning: string
    keys: string
    getUserData: (key: string, value: string) => void
}

const InputText: FC<Props> = ({ meaning, text, keys, getUserData, value }) => {
    return (
        <div className={s.InputText}>
            <h4 className={text.includes('уникальны') ? s.error : s.text}>{text}</h4>
            <TextField
                className={meaning.includes('Введите') || meaning.includes('Некоректный') ? s.error_input_username : s.input_username}
                onChange={e => getUserData(keys, e.target.value)}
                // id='outlined-username-input'
                value={value}
                label={meaning}
                type='text'
                autoComplete='current-username'
            />
        </div>
    );
};

export default InputText;