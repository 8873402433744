import React, { FC, useState } from 'react';
import s from './MobileHeader.module.scss'
import { Link } from 'react-router-dom';
import logo from '../../../assets/Header/mobileHeader/logo.png'
import whatsApp from '../../../assets/Header/mobileHeader/whatsApp.png'
import telegram from '../../../assets/Header/mobileHeader/telegram.png'
import instagram from '../../../assets/Header/mobileHeader/instagram.png'
import enteris from '../../../assets/Header/mobileHeader/enter.png'
import menu from '../../../assets/Header/mobileHeader/menu.png'
import ikon from '../../../assets/Header/mobileHeader/ikon.png'
import BurgerMenu from './BurgerMenu/BurgerMenu';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { setEnter } from '../../../store/slice/userSlice';

const MobileHeader: FC = () => {
    const [burgerMenu, setBurgerMenu] = useState(false)
    const { token, enter } = useAppSelector(state => state.user)
    const dispatch = useAppDispatch()
    const handleLogin = () => {
        dispatch(setEnter(!enter))
    }
    return (
        <section className={s.mobile_container}>
            <Link to={'/'}><img className={s.logo} src={logo} alt="logo" /></Link>
            <div className={s.mobile_navigate}>
                <div className={s.mobile_ikons}>
                    <Link target='_blank' to="https://wa.me/996555888565?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.">
                        <img src={whatsApp} alt="WhatsApp" />
                    </Link>
                    <Link target='_blank' to="https://t.me/jukkg24?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.">
                        <img src={telegram} alt="Telegram" />
                    </Link>
                    <Link target='_blank' to={'https://www.instagram.com/p/C5YCSB5NWn0/?igsh=N25xcG8yOTI4d2g2'}>
                        <img src={instagram} alt="instagram" />
                    </Link>
                    {/* <Link target='_blank' to={'https://taplink.cc/juk.kg'}>
                        <p>TapLink</p>
                    </Link> */}
                </div>
                <div className={s.mobile_menu}>
                    {
                        token ?
                            <Link to={'/personal-area/personal_area'}><img className={s.mobi_ikon} src={ikon} alt="ikon" /></Link>
                            :
                            <img className={s.enter} onClick={handleLogin} src={enteris} alt="enter" />
                    }
                    <img className={s.burger_Menu} onClick={() => setBurgerMenu(!burgerMenu)} src={menu} alt="menu" />
                    {burgerMenu && <BurgerMenu burgerMenu={burgerMenu} setBurgerMenu={setBurgerMenu} />}
                </div>
            </div>
        </section>
    );
};

export default MobileHeader;