import React, { FC } from 'react';
import { NewsData } from '../../../../../store/modules';
import noImg from '../../../../../assets/Home/noImg.jpg'
import s from './NewsCardThree.module.scss'
import { Link } from 'react-router-dom';

interface Card {
    news: NewsData
}

const NewsCardThree: FC<Card> = ({ news }) => {

    return (
        <div className={s.backround}>
            <img src={(news?.image && news?.image.startsWith('http')) ? `https${news?.image.slice(4)}` : noImg} alt="img" />
            <div className={s.blur}>
                <h1>{news?.title ? news?.title : 'Новостей пока нет'}</h1>
                <Link to={'/news'}>
                    <p>Подробнее...</p>
                </Link>
            </div>
        </div>
    );
};

export default NewsCardThree;