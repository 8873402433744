import React, { FC, useState } from 'react';
import s from './WhenCard.module.scss'
import { CarCreation } from '../../../../../../store/modules';
import { dateString, dateString_max } from '../../assets';
interface WhenCardProps {
    getCarCreation: (key: string, value: string) => void
    carCreation: CarCreation
}
const WhenCard: FC<WhenCardProps> = ({ getCarCreation, carCreation }) => {
    // console.log(dateString, dateString_max);

    return (
        <div className={s.WhenCard}>
            <h2 className={s.title_when}><span>&#9913;</span>Когда</h2>
            <div className={s.input_field}>
                <select className={s.p_when} onChange={(e) => getCarCreation('readiness', e.target.value)}>
                    <option value="Всегда">Всегда</option>
                    <option value="Готов загрузке">Готов загрузке</option>
                </select>
                {
                    carCreation.readiness.includes('Всегда') ? <select onChange={(e) => getCarCreation('work_day', e.target.value)} className={s.p_when} >
                        <option value="По рабочим дням">По рабочим дням</option>
                        <option value="Ежедневно">Ежедневно</option>
                    </select> : carCreation.readiness.includes('loading_ready') && <input min={dateString} max={dateString_max} onChange={(e) => getCarCreation('date', e.target.value)} className={s.date_when} type="date" />
                }
            </div>
        </div>
    );
};

export default WhenCard;