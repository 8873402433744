import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import s from './Confirmation.module.scss'
import VerificationInput from 'react-verification-input';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchBySendEmail, setConfirmation, setNewPassword, setRestorePassword, toggleEmail } from '../../../store/slice/userSlice';


const Confirmation: FC = () => {
    const [time, setTime] = useState(60);
    const dispatch = useAppDispatch()
    const [value, setValue] = useState('')
    const [errorText, setErrorText] = useState('')
    const { code, email, confirmation } = useAppSelector(state => state.user)
    const tick = () => {
        if (time === 0) {
            reset()
        } else {
            setTime(time - 1);
        }
    };

    let reset = () => setTime(time)



    const hideModal = () => {
        dispatch(setConfirmation(false))
        dispatch(setNewPassword(true))
    }

    const actionOfModalWindows = () => {
        dispatch(setConfirmation(false))
        dispatch(setRestorePassword(true))
        dispatch(toggleEmail(false))
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        if (code === value) {
            hideModal()
        } else if (code !== value) {
            setErrorText('Неверный или истекший код подтверждения')
        }
    }

    const handleResend = () => {
        dispatch(fetchBySendEmail({ email }))
        setTime(60)
    }

    useEffect(() => {
        if (code === value) {
            setErrorText('')
        }
    }, [value])

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    }, [time]);

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && actionOfModalWindows()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (confirmation) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => actionOfModalWindows();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!confirmation) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    return (
        <div onClick={() => actionOfModalWindows()} className={s.Confirmation}>
            <div onClick={(e) => e.stopPropagation()} className={s.box}>
                <form onSubmit={handleSubmit} className={s.form}>
                    <span onClick={actionOfModalWindows} className={s.closed}>&#10006;</span>
                    <h2>Восстановить пароль </h2>
                    <h3>Введите код </h3>
                    <p className={errorText.includes('Неверный или истекший код подтверждения') ? s.error_text : ''}> {errorText?.includes('Неверный или истекший код подтверждения') ? errorText : 'Проверьте вашу почту'}</p>
                    <div className={s.inputs}>
                        <VerificationInput onChange={setValue} length={4} validChars="0-9" />
                    </div>
                    {time === 0 ? <p onClick={handleResend} className={s.cursor}>{`Отправить заново 0s`}</p> : <p>{`Отправить заново ${time}s`}</p>}

                    <div className={s.buttons}>
                        <button>Продолжить</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Confirmation;