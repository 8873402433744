import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import s from './CreateCarOrder.module.scss'
import BodyCard from './BodyCard/BodyCard';
import RouteCard from './RouteCard/RouteCard';
import WhenCard from './WhenCard/WhenCard';
import OtherData from './OtherData/OtherData';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hooks';
import { CarCreation, ErrorText } from '../../../../../store/modules';
import { fetchByDataForCarCreation } from '../../../../../store/slice/personalDataSlice';
import { dateString } from '../assets';
import Loading from '../../../../../components/Loading/Loading';
import SuccesfullCreation from '../SuccesfullCreation/SuccesfullCreation';

const CreateCarOrder: FC = () => {
    const { token, user } = useAppSelector(state => state.user)
    const { loading, error, dataCreation } = useAppSelector(state => state.personalArea)
    const [currency, setCurrency] = useState('Сом')
    const dispatch = useAppDispatch()
    const [price, setPrice] = useState('договорная')
    const [typeCargo, setTypeCargo] = useState<string[]>([])
    const [fromWhere, setFromWhere] = useState('')
    const [toWhere, setToWhere] = useState('')
    const [errorText, setErrorText] = useState<ErrorText>({
        all: '',
        lifting_capacity: '',
        volume: '',
        weight: '',
        height: '',
        length: '',
        width: '',
    })
    const [dop, setDop] = useState('')
    const [carCreation, setCarCreation] = useState<CarCreation>({
        type_body: '',
        type_cargo: '',
        lifting_capacity: '',
        volume: '',
        length: '',
        width: '',
        height: '',
        from_where: '',
        to_where: '',
        readiness: 'Всегда',
        work_day: 'По рабочим дням',
        price: '',
        user: null,
        date: '',
        name: '',
        note: '',
        phone_number: '',
        telegram: '',
        whatsapp: '',
        additionally: '',
        type_transport: '',
        viber: ''
    })

    const clearForm = () => {
        window.location.reload();
    }

    useEffect(() => {
        user &&
            setCarCreation({ ...carCreation, 'user': user?.id, 'name': user?.username, 'phone_number': user?.phone, date: dateString })
    }, [user])

    const getCarCreation = (key: string, value: string) => {
        setCarCreation({ ...carCreation, [key]: value })
    }

    if (errorText.all.includes('Вы не указали тип прицепа!')) {
        carCreation.type_cargo && setErrorText({ ...errorText, all: '' })
    } else if (errorText.all.includes('Вы не указали город отбывание!')) {
        fromWhere.length >= 3 && setErrorText({ ...errorText, all: '' })
    } else if (errorText.all.includes('Вы не указали город прибывание!')) {
        toWhere.length >= 3 && setErrorText({ ...errorText, all: '' })
    } else if (errorText.all.includes('стоимость')) {
        price && setErrorText({ ...errorText, all: '' })
    } else if (errorText.lifting_capacity.includes('Вес должен быть менее 60т!')) {
        +carCreation.lifting_capacity <= 60 && setErrorText({ ...errorText, lifting_capacity: '' })
    } else if (errorText.volume.includes('Объём должно быть менее 200м³')) {
        +carCreation.volume <= 200 && setErrorText({ ...errorText, volume: '' })
    } else if (errorText.all.includes('Вы не указали тип погрузки!')) {
        typeCargo.length > 0 && setErrorText({ ...errorText, all: '' })
    } else if (errorText.all.includes('Вы не указали грузоподъёмность!')) {
        carCreation.lifting_capacity.length > 0 && setErrorText({ ...errorText, all: '' })
    } else if (errorText.all.includes('Вы не указали объём!')) {
        carCreation.volume && setErrorText({ ...errorText, all: '' })
    } else if (errorText.all.includes('Вы не указали длину!')) {
        carCreation.length && setErrorText({ ...errorText, all: '' })
    } else if (errorText.all.includes('Вы не указали ширину!')) {
        carCreation.width && setErrorText({ ...errorText, all: '' })
    } else if (errorText.all.includes('Вы не указали высоту!')) {
        carCreation.height && setErrorText({ ...errorText, all: '' })
    } else if (errorText.length && errorText.length.includes('Длина должно быть менее 15м')) {
        + carCreation.length <= 15 && setErrorText({ ...errorText, length: '' })
    } else if (errorText.width && errorText.width.includes('Ширина должно быть менее 4м')) {
        +carCreation.width <= 4 && setErrorText({ ...errorText, width: '' })
    } else if (errorText.height && errorText.height.includes('Высота должно быть менее 4м')) {
        carCreation.height && setErrorText({ ...errorText, height: '' })
    }
    const handleChangeUserData: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        if (carCreation.type_cargo && typeCargo.length > 0 && +carCreation.lifting_capacity <= 60 && carCreation.lifting_capacity && carCreation.volume && +carCreation.volume <= 200
            && +carCreation.length <= 15 && carCreation.length && +carCreation.width <= 4 && carCreation.width && +carCreation.height <= 4 && carCreation.height && fromWhere.length >= 3
            && toWhere.length >= 3 && carCreation.readiness && price && carCreation.user === user?.id
            && carCreation.name && carCreation.phone_number && token) {
            dispatch(fetchByDataForCarCreation({
                token,
                carCreation: { ...carCreation, type_body: typeCargo.join(','), additionally: carCreation.additionally.includes('Несколько авто') ? `Несколько авто ${dop}` : carCreation.additionally, from_where: fromWhere, to_where: toWhere, price: (price.includes('договорная') ? 'договорная' : `${price} ${currency}`) }
            }));
            // console.log({ ...carCreation, type_body: typeCargo.join(','), from_where: fromWhere, to_where: toWhere, additionally: carCreation.additionally.includes('Несколько авто') ? `Несколько авто ${dop}` : carCreation.additionally, price: (price.includes('договорная') ? 'договорная' : `${price} ${currency}`) })
        } else if (!carCreation.type_cargo) {
            setErrorText({ ...errorText, all: 'Вы не указали тип прицепа!' })
        } else if (typeCargo.length === 0) {
            setErrorText({ ...errorText, all: 'Вы не указали тип погрузки!' })
        } else if (!carCreation.lifting_capacity) {
            setErrorText({ ...errorText, all: 'Вы не указали грузоподъёмность!' })
        } else if (!carCreation.volume) {
            setErrorText({ ...errorText, all: 'Вы не указали объём!' })
        } else if (!carCreation.length) {
            setErrorText({ ...errorText, all: 'Вы не указали длину!' })
        } else if (!carCreation.width) {
            setErrorText({ ...errorText, all: 'Вы не указали ширину!' })
        } else if (!carCreation.height) {
            setErrorText({ ...errorText, all: 'Вы не указали высоту!' })
        } else if (!fromWhere) {
            setErrorText({ ...errorText, all: 'Вы не указали город отбывание!' })
        } else if (!toWhere) {
            setErrorText({ ...errorText, all: 'Вы не указали город прибывание!' })
        } else if (!price) {
            setErrorText({ ...errorText, all: 'стоимость' })
        } else if (!carCreation.name) {
            setErrorText({ ...errorText, all: 'Вы не указали имя!' })
        } else if (!carCreation.phone_number) {
            setErrorText({ ...errorText, all: 'Вы не указали номер!' })
        }
    };
    // console.log({ ...carCreation, type_body: typeCargo.join(','), from_where: fromWhere, to_where: toWhere, price: (price.includes('договорная') ? 'договорная' : price + currency) });

    useEffect(() => {
        if (+carCreation.lifting_capacity > 60) {
            setErrorText({ ...errorText, lifting_capacity: 'Вес должен быть менее 60т!' })
        }
        if (+carCreation.volume > 200) {
            setErrorText({ ...errorText, volume: 'Объём должно быть менее 200м³!' })
        }
        if (+carCreation.length > 15) {
            setErrorText({ ...errorText, length: 'Длина должно быть менее 15м' })
        }
        if (+carCreation.width > 4) {
            setErrorText({ ...errorText, width: 'Ширина должно быть менее 4м' })
        }
        if (+carCreation.height > 4) {
            setErrorText({ ...errorText, height: 'Высота должно быть менее 4м' })
        }
    }, [carCreation.lifting_capacity, carCreation.volume, carCreation.length, carCreation.width, carCreation.height])

    return (
        <div className={s.CreateCarOrder}>
            <div className={s.add_car}>
                <h2>Добавить транспорт</h2>
                <button onClick={clearForm}>Очистить форму</button>
            </div>
            <form className={s.form_card} onSubmit={handleChangeUserData}>
                <div className={s.field_to_fill}>
                    <BodyCard errorText={errorText} typeCargo={typeCargo} setTypeCargo={setTypeCargo} carCreation={carCreation} getCarCreation={getCarCreation} />
                    <RouteCard setFromWhere={setFromWhere} setToWhere={setToWhere} errorText={errorText} />
                    <WhenCard carCreation={carCreation} getCarCreation={getCarCreation} />
                    <OtherData setDop={setDop} setPrice={setPrice} price={price} currency={currency} setCurrency={setCurrency} errorText={errorText} carCreation={carCreation} getCarCreation={getCarCreation} />
                </div>
                <div className={s.buttons}>
                    <h2 className={s.error_text} >{errorText.lifting_capacity ? errorText.lifting_capacity : errorText.volume ? errorText.volume : errorText.weight ? errorText.weight : errorText.all ? errorText.all : ''} </h2>
                    <button>Опубликовать транспорт</button>
                </div>
            </form>
            {loading && <Loading />}
            {(error || dataCreation) && <SuccesfullCreation text={'Заказ транспорта успешно создан!'} />}
        </div>
    );
};

export default CreateCarOrder;