import React, { FC } from 'react';
import s from './AdditionalInfo.module.scss'
import { typeLoadingContainerTrailer } from '../../assets';
import { CargoCreation, ErrorText } from '../../../../../../store/modules';
interface AdditionalInfoProps {
    getCargoCreation: (key: string, value: string) => void
    setCurrency: (e: string) => void
    setPrice: (e: string) => void
    errorText: ErrorText
    currency: string
    price: string
    cargoCreation: CargoCreation
    setDop: (e: string) => void
}
const AdditionalInfo: FC<AdditionalInfoProps> = ({ cargoCreation, getCargoCreation, errorText, setCurrency, currency, price, setPrice, setDop }) => {
    return (
        <div className={s.AdditionalInfo}>

            <div className={s.loading}>
                <h2>Загрузка</h2>
                <select value={cargoCreation.loading} onChange={e => getCargoCreation('loading', e.target.value)} className={s.price}>
                    <option disabled value="">Тип загрузки</option>
                    {
                        typeLoadingContainerTrailer.map((el, i) => <option key={i} value={el}>{el}</option>)
                    }
                </select>
            </div>
            <div className={s.loading}>
                <h2>Разгрузка</h2>
                <select value={cargoCreation.unloading} onChange={e => getCargoCreation('unloading', e.target.value)} className={s.price}>
                    <option disabled value="">Тип погрузки</option>
                    {
                        typeLoadingContainerTrailer.map((el, i) => <option key={i} value={el}>{el}</option>)
                    }
                </select>
            </div>
            <div className={s.loading}>
                <h2>Оплата</h2>
                <select value={cargoCreation.payment} onChange={e => getCargoCreation('payment', e.target.value)} className={s.price}>
                    <option disabled value="">Оплата</option>
                    <option value="Оплата при погрузке">Оплата при погрузке</option>
                    <option value="Аванс">Аванс</option>
                    <option value="Оплата при выгрузке">Оплата при выгрузке</option>
                    <option value="Оплата после выгрузки через несколько дней">Оплата после выгрузки через несколько дней</option>
                    <option value="Запрос ставки">Запрос ставки</option>
                </select>
            </div>
            <div className={s.loading}>
                <h2>Дополнительно</h2>
                <div className={s.select_fields}>
                    <label><input onChange={e => getCargoCreation('additionally', e.target.value)} name="hoh" type="radio" value={'Отдельное авто'} />Отдельное авто</label>
                    <label><input onChange={e => getCargoCreation('additionally', e.target.value)} name="hoh" type="radio" value={'Догруз'} />Догруз</label>
                    <label><input onChange={e => getCargoCreation('additionally', e.target.value)} name="hoh" type="radio" value={'Несколько авто'} />Несколько авто {cargoCreation.additionally.includes('Несколько авто') && <input onChange={e => setDop(e.target.value)} className={s.quantity} type="number" placeholder='количество' />}</label>
                    <label><input onChange={e => getCargoCreation('additionally', e.target.value)} name="hoh" type="radio" value={'Сборный груз'} />Сборный груз</label>
                </div>
            </div>
            <div className={s.loading}>
                <h2>Контакты</h2>
                <div className={s.select_fields}>
                    <h3>Укажите, к кому обратится по объявлению</h3>
                    <div className={s.inputs_for_user}>
                        <input className={errorText.all.includes('Вы не указали имя!') ? s.errorText : ''} value={cargoCreation.name} onChange={e => getCargoCreation('name', e.target.value)} type="text" placeholder='Имя ' />
                        <input className={errorText.all.includes('Вы не указали номер!') ? s.errorText : ''} value={cargoCreation.phone_number} onChange={e => getCargoCreation('phone_number', e.target.value)} type="tel" placeholder='Телефон' />
                    </div>
                    <div className={s.inputs_for_user}>
                        <input value={cargoCreation.telegram} onChange={e => getCargoCreation('telegram', e.target.value)} type="text" placeholder='Telegram ' />
                        <input value={cargoCreation.whatsapp} onChange={e => getCargoCreation('whatsapp', e.target.value)} type="tel" placeholder='WhatsApp' />
                    </div>
                    <div className={s.inputs_for_user}>
                        <input value={cargoCreation.viber} onChange={e => getCargoCreation('viber', e.target.value)} type="tel" placeholder='Viber' />
                    </div>
                </div>
            </div>
            <div className={s.loading}>
                <h2>Стоимость</h2>
                <div className={s.price_currency}>
                    <input value={price} onChange={e => setPrice(e.target.value)} className={s.prices} type="number" placeholder='Стоимость' />
                    <select onChange={(e) => setCurrency(e.target.value)} value={currency} className={s.currency}>
                        <option value="Сом">Сом</option>
                        <option value="$">$</option>
                        <option value="€">€</option>
                        <option value="Рубль">Рубль</option>
                        <option value="Тенге">Тенге</option>€
                    </select>
                </div>
            </div>
        </div>
    );
};

export default AdditionalInfo;