import React, { FC, useState } from 'react';
import s from './CreateOrder.module.scss'
import CreateCargoOrder from './CreateCargoOrder/CreateCargoOrder';
import CreateCarOrder from './CreateCarOrder/CreateCarOrder';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks/hooks';
interface CreateOrderProps {
    value: string
}
const CreateOrder: FC<CreateOrderProps> = ({ value }) => {
    const { user } = useAppSelector(state => state.user)
    return (
        <div className={s.CreateOrder}>
            {user?.truck_user_data !== null && <div className={s.change_oreder}>
                <Link to={'/personal-area/create_cargo_order'} className={value.includes('create_cargo_order') ? s.active : s.turned_off}>Грузы</Link>
                <Link to={'/personal-area/create_car_order'} className={value.includes('create_car_order') ? s.active : s.turned_off}>Транспорты</Link>
            </div>}
            <div className={s.output}>
                {
                    value.includes('create_cargo_order') ? <CreateCargoOrder /> :
                        value.includes('create_car_order') && <CreateCarOrder />
                }
            </div>
        </div>
    );
};

export default CreateOrder;