import React, { FC, useEffect, useState } from 'react';
import s from './NavigateInPA.module.scss'
import goOut from '../../../../src/assets/PersonalArea/goOut.png'
import arr from '../../../assets/PersonalArea/arr.png'
import { NavLink } from 'react-router-dom';
import icon_menu from '../../../assets/PersonalArea/icon_menu.png'
import empty_avatar from '../../../assets/PersonalArea/empty_avatar.png'
import { useAppSelector } from '../../../store/hooks/hooks';
import OpenAvatar from './OpenAvatar/OpenAvatar';
import LogOut from './LogOut/LogOut';

interface NavProps {
    setOpen: (e: boolean) => void
    open: boolean
}
const NavigateInPA: FC<NavProps> = ({ setOpen, open }) => {
    const { user } = useAppSelector(state => state.user)
    const [userOut, setUserOut] = useState(false)
    const [switchAvatar, setSwitchAvatar] = useState(false)
    const logOut = () => {
        setOpen(true)
        setUserOut(true)
    }
    useEffect(() => {
        document.addEventListener('click', () => setOpen(true));
    }, [])
    return (
        <div onClick={e => e.stopPropagation()} className={!open ? s.NavigateInPA_open : `${s.NavigateInPA_open} ${s.NavigateInPA_close}`}>
            <img className={s.icon_menu} onClick={() => setOpen(!open)} src={icon_menu} alt="ikon" />
            <img onClick={() => setSwitchAvatar(true)} className={s.avatar} src={(user?.img && user?.img.startsWith('http')) ? `https${user?.img.slice(4)}` : empty_avatar} alt="avatar" />
            <div className={open ? `${s.close_window} ${s.navigate_P_Area}` : s.navigate_P_Area}>
                <span onClick={() => setOpen(true)} className={s.closed}>&#10006;</span>
                <img onClick={() => setOpen(!open)} className={s.arrey} src={arr} alt="arrey" />
                <nav className={s.navigate_PA}>
                    <NavLink onClick={() => setOpen(true)} to={'/personal-area/personal_area'} className={({ isActive }) =>
                        isActive ? `${s.aсvtive} ${s.menu_PA}` : `${s.menu_PA}`
                    } >Личный кабинет</NavLink>
                    <NavLink onClick={() => setOpen(true)} to={'/personal-area/payment_history'} className={({ isActive }) =>
                        isActive ? `${s.aсvtive} ${s.menu_PA}` : `${s.menu_PA}`
                    } >История платежей</NavLink>
                    <NavLink onClick={() => setOpen(true)} to={'/personal-area/order_history'} className={({ isActive }) =>
                        isActive ? `${s.aсvtive} ${s.menu_PA}` : `${s.menu_PA}`
                    } >История заказов</NavLink>
                    <NavLink onClick={() => setOpen(true)} to={'/personal-area/create_cargo_order'} className={({ isActive }) =>
                        isActive ? `${s.aсvtive} ${s.menu_PA}` : `${s.menu_PA}`
                    } >Создать заказ</NavLink>
                    <NavLink onClick={() => setOpen(true)} to={'/personal-area/documents'} className={({ isActive }) =>
                        isActive ? `${s.aсvtive} ${s.menu_PA}` : `${s.menu_PA}`
                    } >Документы</NavLink>
                    {user?.truck_user_data !== null && <NavLink onClick={() => setOpen(true)} to={'/personal-area/car_park'} className={({ isActive }) =>
                        isActive ? `${s.aсvtive} ${s.menu_PA}` : `${s.menu_PA}`
                    } >Автопарк</NavLink>}
                </nav>
                <div onClick={logOut} className={s.goOut}>
                    <p className={s.enter_go_out} >Выйти </p>
                    <img src={goOut} alt="ikon" />
                </div>
            </div>
            {switchAvatar && <OpenAvatar switchAvatar={switchAvatar} setSwitchAvatar={setSwitchAvatar} />}
            {userOut && <LogOut userOut={userOut} setUserOut={setUserOut} />}
        </div>
    );
};

export default NavigateInPA;