import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CargoValidate, HomePageCargo, HomePageCars } from '../modules'
import { authApi } from '../../axios'

type NewsState = {
	loading: boolean
	error: null | string
	carsSearch: HomePageCars[]
}

const initialState: NewsState = {
	error: null,
	loading: false,
	carsSearch: [],
}

export const fetchByCarsSearch = createAsyncThunk<HomePageCars[], CargoValidate, { rejectValue: string }>(
	'carsSearch/fetchByCarsSearch',
	async (carsP, { rejectWithValue }) => {
		const res = await authApi.getCarsSearch(carsP)
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server Error')
		}
		return res.data.results
	})

const carsSearchSlice = createSlice({
	name: 'carsSearch',
	initialState,
	reducers: {
		filterCars(state, action: PayloadAction<HomePageCars[]>) {
			state.carsSearch = action.payload
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(fetchByCarsSearch.pending, state => {
			state.loading = true
			state.error = null
		})

		addCase(fetchByCarsSearch.fulfilled, (state, action) => {
			state.carsSearch = action.payload
			state.loading = false
		})

		addCase(fetchByCarsSearch.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'К сожалению машин нет!'
			}
			else if (action.error.message?.includes('50')) {
				state.error = 'Сервер на данный момент не работает!'
			}
		})
	},
})
export const { filterCars } = carsSearchSlice.actions

export default carsSearchSlice.reducer
