import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import newsSlice from "./slice/newsSlice";
import carsSlice from "./slice/carsSlice";
import homeSlice from "./slice/homeSlice";

import cargoSearchSlice from "./slice/cargoSearchSlice";
import carsSearchSlice from "./slice/carsSearchSlice";
import detailView from "./slice/detailView";
import comentsStatus from "./slice/comentsStatus";
import personalDataSlice from "./slice/personalDataSlice";


export const store = configureStore({
        // devTools:false
        reducer: {
                user: userSlice,
                news: newsSlice,
                cars: carsSlice,
                home: homeSlice,
                cargoSearchTs: cargoSearchSlice,
                carsSearchTs: carsSearchSlice,
                detailViewTs: detailView,
                comentsStatusTs: comentsStatus,
                personalArea: personalDataSlice
        }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
