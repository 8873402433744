import React, { FC } from 'react';
import s from './ShowCar.module.scss'
import { useAppSelector } from '../../../../../store/hooks/hooks';
import Loading from '../../../../../components/Loading/Loading';
import DetailCar from '../DetailCar/DetailCar';
import ListCar from '../ListCar/ListCar';

interface ShowCarProps {
    setCarsPark: (e: boolean) => void
    value: string
}
const ShowCar: FC<ShowCarProps> = ({ setCarsPark, value }) => {
    const { loading } = useAppSelector(state => state.personalArea)
    return (
        <div className={s.ShowCar}>
            {value.includes('car_park') ?
                <>
                    <div className={s.top_ShowCar}>
                        <h2>Мой Автопарк</h2>
                        <button onClick={() => setCarsPark(true)}>Добавить транспорт</button>
                    </div>
                    < ListCar />
                </> :
                value.includes('detail_card') ? < DetailCar /> : ''
            }
            {loading && <Loading />}
        </div >
    );
};

export default ShowCar;