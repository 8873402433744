import React, { FC, useEffect, useState } from 'react';
import s from '../ShowDocument.module.scss'
import { Link } from 'react-router-dom';
import { GetDocument } from '../../../../../../store/modules';
import docs_white from '../../../../../../assets/PersonalArea/docs_white.png'
import docs from '../../../../../../assets/PersonalArea/docs.jpg'
import deletedimg from '../../../../../../assets/PersonalArea/deleted.png'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks/hooks';
import Delete from '../../../../Delete/Delete';
import { fetchByDeleteDocs } from '../../../../../../store/slice/personalDataSlice';
import SuccesfullCreation from '../../../CreateOrder/SuccesfullCreation/SuccesfullCreation';
interface DocumentCardProps {
  el: GetDocument
}
const DocumentCard: FC<DocumentCardProps> = ({ el }) => {
  const dispatch = useAppDispatch()
  const { error, docsCreation } = useAppSelector(state => state.personalArea)
  const [delDocs, setDelDocs] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const deleteDocs = () => {
    setDelDocs(true)
  }

  useEffect(() => {
    if (deleted) {
      dispatch(fetchByDeleteDocs(el.id))
    }
  }, [deleted])
  return (
    <div className={s.docs_card}>
      <Link to={`https${el.document.slice(4)}`} target='_blank'>
        <img src={el.document.endsWith('.pdf') ? docs_white : docs} alt='document' />{' '}
        <p title={el.title} >{el.title.length > 15 ? el.title.slice(0, 15) + '...' : el.title} </p>
      </Link>
      <img onClick={deleteDocs} className={s.delete} src={deletedimg} alt="box" />
      {delDocs && <Delete text={'Вы точно хотите удалить этот документ'} setDeleted={setDeleted} delDocs={delDocs} setDelDocs={setDelDocs} name={el.title} />}
      {(docsCreation || error?.includes('Упс что то пошло не так попробуйте снова!')) && <SuccesfullCreation text={'Документ успешно удалён'} />}
    </div>
  );
};

export default DocumentCard;