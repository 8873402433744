import React, { FC } from 'react';
import s from './WhenCard.module.scss'
import { CargoCreation, ErrorText } from '../../../../../../store/modules';
import { dateString, dateString_max } from '../../assets';
interface WhenCardProps {
    getCargoCreation: (key: string, value: string) => void
    cargoCreation: CargoCreation
    errorText: ErrorText
}
const WhenCard: FC<WhenCardProps> = ({ cargoCreation, getCargoCreation, errorText }) => {
    return (
        <div className={s.WhenCard}>
            <h2 className={s.title_when}><span>&#9913;</span>Когда</h2>
            <div className={s.input_field}>
                <select value={cargoCreation.readiness} className={errorText.all.includes('всегда') ? `${s.readiness} ${s.errorText}` : s.readiness} onChange={(e) => getCargoCreation('readiness', e.target.value)}>
                    <option value="Всегда">Всегда</option>
                    <option value="Готов загрузке">Готов загрузке</option>
                    <option value="Срочно">Срочно</option>
                </select>
                {
                    cargoCreation.readiness.includes('Всегда') ? <select className={s.readiness} onChange={(e) => getCargoCreation('work_day', e.target.value)}  >
                        <option value="По рабочим дням">По рабочим дням</option>
                        <option value="Ежедневно">Ежедневно</option>
                    </select> : (cargoCreation.readiness.includes('Готов загрузке') || cargoCreation.readiness.includes('Строчно')) && <input min={dateString} max={dateString_max} onChange={(e) => getCargoCreation('date', e.target.value)} className={s.date_when} type="date" />
                }
            </div>
        </div>
    );
};

export default WhenCard;