import React, { FC } from 'react'
import s from '../PersonalData.module.scss'
import ArrUp from '../../../../../assets/PersonalArea/ArrUp.png'
import { useAppSelector } from '../../../../../store/hooks/hooks'
interface CommentsProps {
	setChangeComments: (e: boolean) => void
	changeComments: boolean
}
const Comments: FC<CommentsProps> = ({ changeComments, setChangeComments }) => {
	const { user } = useAppSelector(state => state.user)
	return (
		<div className={changeComments ? s.open_reviews : s.reviews}>
			<div className={s.title_comments}>
				<h2>Отзывы</h2>
				<img
					className={changeComments ? s.img_open : s.img_clouse}
					onClick={() => setChangeComments(!changeComments)}
					src={ArrUp}
					alt='arrey'
				/>
			</div>
			<div className={s.comments}>
				{user?.comments && user?.comments?.length > 0 ? (
					user?.comments?.map(el => <p key={el.id}>{el.text ? el.text : ''}</p>)
				) : (
					<p>Отзывов нету</p>
				)}
			</div>
		</div>
	)
}

export default Comments
