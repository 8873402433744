import React, { FC, useEffect, useState } from 'react';
import s from '../DetailCar.module.scss'
import { Link } from 'react-router-dom';
import { TruckDocs } from '../../../../../../store/modules';
import docs_white from '../../../../../../assets/PersonalArea/docs_white.png'
import deleteds from '../../../../../../assets/PersonalArea/deleted.png'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks/hooks';
import Delete from '../../../../Delete/Delete';
import SuccesfullCreation from '../../../CreateOrder/SuccesfullCreation/SuccesfullCreation';
import { fetchByDeleteDocsAuto } from '../../../../../../store/slice/personalDataSlice';
interface DocumentCarCardProps {
  el: TruckDocs
  i: number
}
const DocumentCarCard: FC<DocumentCarCardProps> = ({ el, i }) => {
  const dispatch = useAppDispatch()
  const { token } = useAppSelector(state => state.user)
  const { error, loadingPlace } = useAppSelector(state => state.personalArea)
  const [delDocs, setDelDocs] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const deleteDocs = () => {
    setDelDocs(true)
  }
  useEffect(() => {
    if (deleted && token) {
      dispatch(fetchByDeleteDocsAuto({ id: el.id, token }))
    }
  }, [deleted])
  return (
    <div className={s.docs_card}>
      <Link to={`https${el.docs.slice(4)}`} key={el.id} className={s.card_docs} target='_blank'>
        <img src={docs_white} alt='document' />
        <h3>Документ {i + 1}</h3>
      </Link>
      <img onClick={deleteDocs} className={s.deleted} src={deleteds} alt="basket" />
      {delDocs && <Delete text={'Вы точно хотите удалить этот документ'} setDeleted={setDeleted} delDocs={delDocs} setDelDocs={setDelDocs} name={`${i + 1}`} />}
      {(loadingPlace || error?.includes('Упс что то пошло не так попробуйте снова!')) && <SuccesfullCreation text={'Документ успешно удалён'} />}
    </div>
  );
};

export default DocumentCarCard;