import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CargoCarrier, ChangePassword, ChangeUserData, EmailSend, ForChangeUD, GetUserData, LocalUser, Message, UserData, UserGetData, UserToken, } from '../modules'
import { authApi } from '../../axios'
import { removeLSToken, setLSToken } from '../../LS'

type UserState = {
	loading: boolean
	sentEmail: boolean
	closeWindow: boolean
	loading_user: boolean
	error: null | string
	token: null | string
	redirect: boolean
	redirectTracks: boolean
	user: null | GetUserData
	localUser: null | LocalUser
	registrationLocalUser: null | UserData
	localChangeUser: null | ChangeUserData
	email: string
	code: string
	enter: boolean
	restorePassword: boolean
	confirmation: boolean
	newPassword: boolean
}

const initialState: UserState = {
	error: null,
	closeWindow: false,
	sentEmail: false,
	loading_user: false,
	loading: false,
	token: null,
	redirect: false,
	redirectTracks: false,
	user: null,
	localUser: null,
	registrationLocalUser: null,
	localChangeUser: null,
	email: '',
	code: '',
	confirmation: false,
	enter: false,
	newPassword: false,
	restorePassword: false,
}

// регистрация

export const fetchByAddNewUser = createAsyncThunk<UserToken, UserData, { rejectValue: string }>(
	'user/fetchByAddNewUser', async (UserData, { rejectWithValue }) => {
		const res = await authApi.addNewUser(UserData)
		// console.log(res)
		if (res.status !== 201) {
			return rejectWithValue('Server error')
		}
		return res.data as UserToken
	})

// для отправки email

export const fetchBySendEmail = createAsyncThunk<Message, EmailSend, { rejectValue: string }>(
	'user/fetchBySendEmail', async (email, { rejectWithValue }) => {
		const res = await authApi.sendEmail(email)
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server error')
		}
		return res.data
	})

// для изменения пароля

export const fetchChangePassword = createAsyncThunk<Message, ChangePassword, { rejectValue: string }>(
	'user/fetchChangePassword', async (email, { rejectWithValue }) => {
		const res = await authApi.changePassword(email)
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server error')
		}
		return res.data
	})
// Авторизация

export const fetchByLogin = createAsyncThunk<UserToken, UserGetData, { rejectValue: string }>(
	'user/fetchByLogin', async (userGetData, { rejectWithValue }) => {
		const res = await authApi.login(userGetData)
		// console.log(res)
		if (res.status !== 201) {
			return rejectWithValue('Server error')
		}
		return res.data as UserToken
	})

// Информация по пользователя

export const fetchByUserData = createAsyncThunk<GetUserData, string, { rejectValue: string }>(
	'user/fetchByUserData', async (token, { rejectWithValue }) => {
		const res = await authApi.getUserData(token)
		// console.log(res)
		if (res.status !== 200) {
			return rejectWithValue('Server error')
		}
		return res.data as GetUserData
	})

// Для изменения данных пользователя

export const fetchByChangeUserData = createAsyncThunk<void, ForChangeUD, { rejectValue: string }>(
	'user/fetchByChangeUserData', async (userDAta, { rejectWithValue }) => {
		const res = await authApi.getChangeUserData(userDAta)
		// console.log(res)
		// if (res.status !== 200) {
		// 	return rejectWithValue('Server error')
		// }
		// return res.data as GetUserData
	})

//  для становление грузоперевозчиком
export const fetchByBecomeCC = createAsyncThunk<void, CargoCarrier, { rejectValue: string }>(
	'PersonalData/fetchByBecomeCC', async (cargoCargo, { rejectWithValue }) => {
		const res = await authApi.getBecomeCargoCarrier(cargoCargo)
		// console.log(res)
	})

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		toggleRedirect(state, action: PayloadAction<boolean>) {
			state.redirect = action.payload
		},
		toggleEmail(state, action: PayloadAction<boolean>) {
			state.sentEmail = action.payload
			state.closeWindow = action.payload
		},
		toggleRedirectTracks(state, action: PayloadAction<boolean>) {
			state.redirectTracks = action.payload
		},
		setToken(state, action: PayloadAction<string | null>) {
			state.token = action.payload
		},
		logOut(state) {
			state.token = null
			state.user = null
			removeLSToken()
		},
		setErrorChange(state, action: PayloadAction<string | null>) {
			state.error = action.payload
		},
		setLocalUser(state, action: PayloadAction<LocalUser | null>) {
			state.localUser = action.payload
		},
		setRegistLocalUser(state, action: PayloadAction<UserData | null>) {
			state.registrationLocalUser = action.payload
		},
		setLocalChangeUser(state, action: PayloadAction<ChangeUserData | null>) {
			state.localChangeUser = action.payload
		},
		setEmailChange(state, action: PayloadAction<string>) {
			state.email = action.payload
		},
		setEnter(state, action: PayloadAction<boolean>) {
			state.enter = action.payload
		},
		setRestorePassword(state, action: PayloadAction<boolean>) {
			state.restorePassword = action.payload
		},
		setConfirmation(state, action: PayloadAction<boolean>) {
			state.confirmation = action.payload
		},
		setNewPassword(state, action: PayloadAction<boolean>) {
			state.newPassword = action.payload
		},
	},
	extraReducers: ({ addCase }) => {
		addCase(fetchByAddNewUser.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByAddNewUser.fulfilled, (state, action) => {
			state.loading = false
			if (action.payload.access) {
				state.token = action.payload.access
				setLSToken(action.payload.access)
				state.redirect = true
				state.error = null
			}
		})
		addCase(fetchByAddNewUser.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('400')) {
				state.redirect = false
				state.error = `Значения должны быть уникальны!`
			}
		})
		// ===============
		addCase(fetchBySendEmail.pending, state => {
			state.error = null
		})
		addCase(fetchBySendEmail.fulfilled, (state, action) => {
			if (action.payload.detail.includes("Код верификации отправлен успешно")) {
				state.sentEmail = true
				state.code = action.payload.verification_code
			}
		})
		addCase(fetchBySendEmail.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.redirect = false
				state.error = `Эл. почта не правильно введён!`
			}
		})
		// ===============
		addCase(fetchChangePassword.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchChangePassword.fulfilled, (state, action) => {
			state.loading = false
			if (action.payload.detail.includes("Пароль успешно изменен")) {
				state.closeWindow = true
			}
		})
		addCase(fetchChangePassword.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('400')) {
				state.redirect = false
				state.error = "Неверный или истекший код подтверждения"
			}
		})
		// =============
		addCase(fetchByLogin.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByLogin.fulfilled, (state, action) => {
			state.loading = false
			state.error = null
			if (action.payload.access) {
				state.token = action.payload.access
				state.redirect = true
				state.enter = false
				setLSToken(action.payload.access)
			}
		})
		addCase(fetchByLogin.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('401')) {
				state.error = 'Пароль не правильно введен!'
				state.enter = true
				state.token = null
				removeLSToken()
			} else if (action.error.message?.includes('404')) {
				state.error = 'Имя не правильно введен!'
				state.enter = true
				state.token = null
				removeLSToken()
			}
		})
		// ====================
		addCase(fetchByUserData.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByUserData.fulfilled, (state, action) => {
			state.loading = false
			state.user = action.payload
		})
		addCase(fetchByUserData.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('401')) {
				removeLSToken()
				state.redirect = true
				state.token = null
				state.error = 'Пользователь не найден!'
			}
		})
		// ======================
		addCase(fetchByChangeUserData.pending, state => {
			state.loading = true
			state.error = null
			state.loading_user = true
		})
		addCase(fetchByChangeUserData.fulfilled, (state, action) => {
			state.loading = false
			state.loading_user = false
			// state.user = action.payload
		})
		addCase(fetchByChangeUserData.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('40')) {
				state.error = 'Упс что то пошло не так попробуйте снова!'
			}
		})

		// ==============
		addCase(fetchByBecomeCC.pending, state => {
			state.loading = true
			state.error = null
		})
		addCase(fetchByBecomeCC.fulfilled, state => {
			state.loading = false
			state.redirectTracks = true
		})
		addCase(fetchByBecomeCC.rejected, (state, action) => {
			state.loading = false
			if (action.error.message?.includes('400')) {
				state.error = 'Упс что то пошло не так!'
			}
		})
	},
})

export const { toggleRedirect, toggleRedirectTracks, setToken, logOut, setErrorChange, setLocalUser, setRegistLocalUser, setLocalChangeUser, toggleEmail, setEmailChange, setEnter, setRestorePassword, setConfirmation, setNewPassword, } = userSlice.actions

export default userSlice.reducer
