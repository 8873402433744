import React, { FC, useEffect } from 'react';
import s from './BurgerMenu.module.scss'
import { Link, NavLink } from 'react-router-dom';
import whatsApp from '../../../../assets/Header/mobileHeader/whatsApp.png'
import telegram from '../../../../assets/Header/mobileHeader/telegram.png'
import instagram from '../../../../assets/Header/mobileHeader/instagram.png'
import tochka from '../../../../assets/Header/mobileHeader/tochka.png'
import phone from '../../../../assets/Header/mobileHeader/phone.png'

interface BurgerMenuProps {
    setBurgerMenu: (e: boolean) => void
    burgerMenu: boolean
}

const BurgerMenu: FC<BurgerMenuProps> = ({ burgerMenu, setBurgerMenu }) => {
    const onClick = () => {
        window.scrollTo({
            top: 8000,
            behavior: "smooth",
        });
        setBurgerMenu(false)
    }

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && setBurgerMenu(false)
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (burgerMenu) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => setBurgerMenu(false);
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!burgerMenu) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    return (
        <div>
            <div onClick={() => setBurgerMenu(false)} className={s.burgerMenu}>
                <nav onClick={(e) => e.stopPropagation()} className={s.nav}>
                    <span onClick={() => setBurgerMenu(false)} className={s.closed}>&#10006;</span>
                    <ul className={s.ul}>
                        <li><NavLink onClick={() => setBurgerMenu(false)} className={({ isActive }) =>
                            isActive ? `${s.active} ${s.menu_item}` : `${s.menu_item}`
                        } to={'/'} >Главная</NavLink></li>
                        <li ><NavLink onClick={() => setBurgerMenu(false)} className={({ isActive }) =>
                            isActive ? `${s.active} ${s.menu_item}` : `${s.menu_item}`
                        } to={'/cargo'} >Грузы</NavLink></li>
                        <li><NavLink onClick={() => setBurgerMenu(false)} className={({ isActive }) =>
                            isActive ? `${s.active} ${s.menu_item}` : `${s.menu_item}`
                        } to={'/cars'} >Транспорты</NavLink></li>
                        <li><NavLink onClick={() => setBurgerMenu(false)} className={({ isActive }) =>
                            isActive ? `${s.active} ${s.menu_item}` : `${s.menu_item}`
                        } to={'/news'}>Новости</NavLink></li>
                        <li><NavLink onClick={() => setBurgerMenu(false)} className={({ isActive }) =>
                            isActive ? `${s.active} ${s.menu_item}` : `${s.menu_item}`
                        } to={'/tariff'} >Тарифы</NavLink></li>
                        <li onClick={onClick}><p className={s.menu_item}>Контакты</p></li>
                        <li className={s.address}><img src={tochka} alt="dot" />
                            <p className={s.city_by}>г. Бишкек</p>
                            <p>Ул. Пригородная-2я. Дом 55</p>
                        </li>
                        <li className={s.phone_number}><img src={phone} alt="phone" /><a href="tel:+996555888565">+996 555 888 565</a></li>
                        <li className={s.ikons}>
                            <Link target='_blank' to="https://wa.me/996555888565?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.">
                                <img src={whatsApp} alt="WhatsApp" />
                            </Link>
                            <Link target='_blank' to="https://t.me/jukkg24?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.">
                                <img src={telegram} alt="Telegram" />
                            </Link>
                            <Link target='_blank' to={'https://www.instagram.com/p/C5YCSB5NWn0/?igsh=N25xcG8yOTI4d2g2'}>
                                <img src={instagram} alt="instagram" />
                            </Link>
                            {/* <Link target='_blank' to={'https://taplink.cc/juk.kg'}>
                                <p>TapLink</p>
                            </Link> */}
                        </li>
                    </ul>
                </nav>
            </div>
        </div >
    );
};

export default BurgerMenu;