import React, { FC, useState } from 'react'
import s from './Footer.module.scss'
import { Link } from 'react-router-dom'
import ikon from '../../assets/Footer/ikon.png'
import mail from '../../assets/Footer/mail.png'
import tochka from '../../assets/Footer/tochka.png'
import instagram from '../../assets/Footer/instagram.png'
import whatsapp from '../../assets/Footer/whatsapp.png'
import telegram from '../../assets/Footer/telegram.png'
import MobileFooter from './MobileFooter/MobileFooter'
import PrivacyPolicy from '../../modal/footerModal/PrivacyPolicy/PrivacyPolicy'
import TermsOfUse from '../../modal/footerModal/TermsOfUse/TermsOfUse'

const Footer: FC = () => {
	const [privacyPolicy, setPrivacyPolicy] = useState(false)
	const [termsOfUse, setTermsOfUse] = useState(false)

	return (
		<footer className={s.footer}>
			<div className={s.questions}>
				<div className={s.question_card}>
					<p>Как зарегистрироваться на сайт?</p>
					<a target='_blank' href="https://youtube.com/shorts/i-TL8XuT7Uc?feature=share">https://www.youtube.com</a>
				</div>
				<div className={s.question_card}>
					<p>Как сменить пароль, если потеряли?</p>
					<a target='_blank' href="https://youtu.be/6YrEgYL2BFA">https://www.youtube.com</a>
				</div>
				<div className={s.question_card}>
					<p>Как создать груз?</p>
					<a target='_blank' href="https://www.youtube.com/watch?v=LSTe5LHrlgA">https://www.youtube.com</a>
				</div>
				<div className={s.question_card}>
					<p>Как создать транспорт?</p>
					<a target='_blank' href="https://www.youtube.com/watch?v=LBR_4PWY1Cw">https://www.youtube.com</a>
				</div>
				<div className={s.question_card}>
					<p>Как оставить отзыв/рейтинг?</p>
					<a target='_blank' href="https://www.youtube.com/watch?v=nY4NVDPfKJI">https://www.youtube.com</a>
				</div>
			</div>
			<div className={s.mobile_footer}>
				<MobileFooter setPrivacyPolicy={setPrivacyPolicy} setTermsOfUse={setTermsOfUse} />
			</div>
			<div className={s.navbar}>
				<div className={s.box}>
					<div className={s.footer_top}>
						<div className={s.address}>
							<img src={tochka} alt='dot' />
							<p className={s.city_by}>г. Бишкек</p>
							<p>Ул. Пригородная-2я. Дом 55</p>
						</div>
						<Link to={'/'}>
							<img className={s.ikon} src={ikon} alt='logo' />
						</Link>
						<div className={s.right}>
							<Link target='_blank' to='mailto:/jukkg24@gmail.com' className={s.mail}>
								<img src={mail} alt='mail' />
								<p>jukkg24@gmail.com</p>
							</Link>
							<div className={s.ikons}>
								<Link target='_blank' to='https://wa.me/996555888565?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.'>
									<img src={whatsapp} alt='WhatsApp' />
								</Link>
								<Link target='_blank' to='https://t.me/jukkg24?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.'>
									<img src={telegram} alt='Telegram' />
								</Link>
								<Link target='_blank' to={'https://www.instagram.com/p/C5YCSB5NWn0/?igsh=N25xcG8yOTI4d2g2'}>
									<img src={instagram} alt='instagram' />
								</Link>
								{/* <Link target='_blank' to={'https://taplink.cc/juk.kg'}>
									<p>TapLink</p>
								</Link> */}
							</div>
						</div>
					</div>
					<div className={s.footer_bottom}>
						<p
							className={s.footer_modal}
							onClick={() => setPrivacyPolicy(true)}
						>
							Политика конфиденциальность
						</p>
						<p className={s.footer_modal} onClick={() => setTermsOfUse(true)}>
							Условия использования
						</p>
						<p>2024© Все права защищены</p>
					</div>
				</div>
			</div>
			{privacyPolicy && (
				<PrivacyPolicy
					privacyPolicy={privacyPolicy}
					setPrivacyPolicy={setPrivacyPolicy}
				/>
			)}
			{termsOfUse && (
				<TermsOfUse termsOfUse={termsOfUse} setTermsOfUse={setTermsOfUse} />
			)}
		</footer>
	)
}

export default Footer
