import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import s from './RestorePassword.module.scss';
import { validateEmail } from '../../../other';
import { fetchBySendEmail, setConfirmation, setEmailChange, setEnter, setRestorePassword, toggleEmail } from '../../../store/slice/userSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import Loading from '../../../components/Loading/Loading';

const RestorePassword: FC = () => {
    const [email, setEmail] = useState('')
    const { loading, error, sentEmail, restorePassword } = useAppSelector(state => state.user)
    const [errorText, setErrorText] = useState('')
    const dispatch = useAppDispatch()
    if (errorText.includes('Некоректный email!')) {
        !validateEmail(email) && setErrorText('')
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        if (!validateEmail(email)) {
            dispatch(fetchBySendEmail({ email }))
            dispatch(setEmailChange(email))
        } else if (validateEmail(email)) {
            setErrorText('Некоректный email!')
        } else if (!validateEmail(email)) {
            setErrorText('')
        }
        // dispatch(fetchByAddNewUser(userData))
    }

    const actionOfModalWindows = () => {
        dispatch(setRestorePassword(false))
        dispatch(setEnter(true))
    }

    useEffect(() => {
        if (sentEmail) {
            dispatch(setRestorePassword(false))
            dispatch(setConfirmation(true))
        }
        return () => {
            dispatch(toggleEmail(false))
        }
    }, [sentEmail])

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && actionOfModalWindows()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (restorePassword) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => actionOfModalWindows();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!restorePassword) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])
    return (
        <div onClick={() => actionOfModalWindows()} className={s.RestorePassword}>
            <div onClick={(e) => e.stopPropagation()} className={s.box}>
                <form onSubmit={handleSubmit} className={s.form}>
                    <span onClick={actionOfModalWindows} className={s.closed}>&#10006;</span>
                    <h2 className={s.password}>Восстановить пароль </h2>
                    <h3 className={s.send}>Мы отправим вам код на почту</h3>
                    <div className={s.mail}>
                        <h4 className={errorText.includes('Некоректный email') ? s.error_email_input : error?.includes('Эл. почта не правильно введён!') ? s.error_email_input : s.email_input}>{errorText.includes('Некоректный email') ? errorText : error?.includes('Эл. почта не правильно введён!') ? error : 'Эл. почта'}</h4>
                        <input value={email} onChange={e => setEmail(e.target.value.trimStart().toLowerCase())} type="text" placeholder='Email' />
                    </div>
                    <div className={s.error}>
                        <button>Продолжить</button>
                    </div>
                </form>
            </div>
            {loading && <Loading />}
        </div>
    );
};

export default RestorePassword;