import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import s from './NewPassword.module.scss'
import { UserPassword } from '../../../store/modules';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { fetchChangePassword, setConfirmation, setNewPassword, toggleEmail } from '../../../store/slice/userSlice';
import Loading from '../../../components/Loading/Loading';

const NewPassword: FC = () => {
    const [userData, setUserData] = useState<UserPassword>({
        password: '',
        password2: '',
    })
    const dispatch = useAppDispatch()
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)
    const [errorText, setErrorText] = useState('')
    const getUserData = (key: string, value: string) => {
        setUserData({ ...userData, [key]: value.trimStart() })
    }
    const { email, code, error, loading, sentEmail, closeWindow, newPassword } = useAppSelector(state => state.user)
    const handleClickShowPassword = () => setShowPassword1((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

    const actionOfModalWindows = () => {
        dispatch(setNewPassword(false))
        dispatch(setConfirmation(true))
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    if (errorText.includes('Длина пароля должна')) {
        userData.password.length >= 8 && setErrorText('')
    } else if (errorText.includes('Пароли не совпадают')) {
        userData.password === userData.password2 && setErrorText('')
    }
    // console.log(email, code);

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        if (userData.password.length >= 8 && userData.password2.length >= 8 && userData.password === userData.password2 && code && email) {
            dispatch(fetchChangePassword({ verification_code: code, email: email, new_password: userData.password }))
        } else if (userData.password.length < 8 && userData.password2.length <= 8) {
            setErrorText('Длина пароля должна быть не менее 8 символов!')
        } else if (userData.password !== userData.password2) {
            setErrorText('Пароли не совпадают!')
        }
        // dispatch(fetchByAddNewUser(userData))
    }

    useEffect(() => {
        if (closeWindow) {
            dispatch(setNewPassword(false))
        }
        if (error?.includes('Неверный или истекший код подтверждения')) {
            actionOfModalWindows()
        }
        return () => {
            dispatch(toggleEmail(false))
        }
    }, [sentEmail, closeWindow, error])

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && actionOfModalWindows()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (newPassword) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => actionOfModalWindows();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!newPassword) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    return (
        <div onClick={() => actionOfModalWindows()} className={s.NewPassword}>
            <div onClick={(e) => e.stopPropagation()} className={s.box}>
                <form onSubmit={handleSubmit} className={s.form}>
                    <span onClick={actionOfModalWindows} className={s.closed}>&#10006;</span>
                    <h2>Введите новый пароль</h2>
                    <div className={s.inputs}>
                        <h3 className={errorText.includes('Длина пароля должна') ? s.error_input_password : s.input_password}>{errorText.includes('Длина пароля должна') ? errorText : 'Новый Пароль'}</h3>
                        <FormControl sx={{ width: '100%' }} variant='outlined'>
                            <InputLabel htmlFor='outlined-adornment-password'>
                                Пароль
                            </InputLabel>
                            <OutlinedInput
                                onChange={e => getUserData('password', e.target.value)}
                                className={s.input_password}
                                // id='outlined-adornment-password'
                                type={showPassword1 ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge='end'
                                        >
                                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label='Password'
                            />
                        </FormControl>
                    </div>
                    <div className={s.inputs}>
                        <h3 className={errorText.includes('Пароли не совпадают') ? s.error_input_password2 : s.input_password2}>{errorText.includes('Пароли не совпадают') ? errorText : 'Повторите Пароль'}</h3>
                        <FormControl sx={{ width: '100%' }} variant='outlined'>
                            <InputLabel htmlFor='outlined-adornment-password'>
                                Пароль
                            </InputLabel>
                            <OutlinedInput
                                onChange={e => getUserData('password2', e.target.value)}
                                className={s.input_password}
                                // id='outlined-adornment-password'
                                type={showPassword2 ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position='end'>
                                        <IconButton
                                            aria-label='toggle password visibility'
                                            onClick={handleClickShowPassword2}
                                            onMouseDown={handleMouseDownPassword}
                                            edge='end'
                                        >
                                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label='Password'
                            />
                        </FormControl>
                    </div>
                    <div className={s.error}>
                        <button>Сохранить</button>
                    </div>
                </form>
            </div>
            {loading && <Loading />}
        </div>
    );
};

export default NewPassword;