import React, { FC, useEffect } from 'react';
import s from './Delete.module.scss'
interface DeleteProps {
  setDelDocs: (e: boolean) => void
  setDeleted: (e: boolean) => void
  delDocs: boolean
  name: string
  text: string
}
const Delete: FC<DeleteProps> = ({ delDocs, setDelDocs, name, setDeleted, text }) => {
  const solution = () => {
    setDeleted(true)
    setDelDocs(false)
  }
  useEffect(() => {
    // При рождении убрать скрол
    document.body.style.overflow = 'hidden'
    // При нажатии на ESC закрыть модальное окно
    document.addEventListener('keydown', (e) => {
      e.code === 'Escape' && setDelDocs(false)
    })
    // При рождении навесит другое событие на кнопку назад у браузера
    if (delDocs) {
      window.history.pushState(null, '', window.location.href)
      window.onpopstate = () => setDelDocs(false);
    }
    return () => {
      // При закрытии  модального окна вернуть скролл
      document.body.style.overflow = 'auto'
      // При закрытии убрать действия с кнопки ESC
      document.removeEventListener('keydown', () => { })
      // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
      if (!delDocs) window.history.back();
      window.onpopstate = () => { };
    }
  }, [])
  return (
    <div className={s.DelDocs} onClick={() => setDelDocs(false)} >
      <div className={s.TermsOfUse_card} onClick={(e) => e.stopPropagation()}>
        <h2>{text} ({name})</h2>
        <div className={s.bittons}>
          <button onClick={solution} >Да</button><button onClick={() => setDelDocs(false)}>Нет</button>
        </div>
      </div>
    </div>
  );
};

export default Delete;