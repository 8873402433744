import React, { FC } from 'react';
import s from './AddDocument.module.scss'
import urban from '../../../../../assets/PersonalArea/urban.png'
interface AddDocumentProps {
    setAddDoc: (e: boolean) => void
}
const AddDocument: FC<AddDocumentProps> = ({ setAddDoc }) => {
    return (
        <div className={s.AddDocument}>
            <div className={s.block_in_AddDocument}>
                <img src={urban} alt="photo" />
                <p>Добавьте свои документы уже сейчас!
                    Не упустите возможность хранить всю необходимую информацию в одном месте.</p>
                <button onClick={() => setAddDoc(true)} className={s.add_doc}>Добавить Документ</button>
            </div>
        </div>
    );
};

export default AddDocument;