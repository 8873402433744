import React, { FC } from 'react';
import s from './Price.module.scss'
import Card from './Card/Card';
import CardFree from './CardFree/CardFree';


const Price: FC = () => {
    return (
        <div className={s.Price}>
            {/* <Card /> */}
            {/* <Card /> */}
            {/* <Card /> */}
            {/* <Card /> */}
            {/* <Card /> */}
            {/* <Card /> */}
            <CardFree />
            <CardFree />
            <CardFree />
        </div>
    );
};

export default Price;