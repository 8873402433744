import React, { ChangeEventHandler, FC, useRef } from 'react'
import s from './NoteCard.module.scss'
import { CargoCreation, ErrorText } from '../../../../../../store/modules'
interface NoteCardProps {
	getCargoCreation: (key: string, value: string) => void
	errorText: ErrorText
	cargoCreation: CargoCreation
	handleChange: ChangeEventHandler
	file: File | null
}

const NoteCard: FC<NoteCardProps> = ({
	cargoCreation,
	errorText,
	getCargoCreation,
	handleChange,
	file,
}) => {
	const filePicker = useRef<HTMLInputElement>(null)
	const handlePick = () => {
		if (filePicker.current) {
			// использование filePicker.current без предупреждений о типе
			filePicker.current.focus()
			filePicker.current.click()
		}
	}
	return (
		<div className={s.NoteCard}>
			<div className={s.note_note}>
				<h2>Примечание</h2>
				<textarea onChange={e => getCargoCreation('note', e.target.value)} value={cargoCreation.note} rows={4} cols={50} placeholder='Описание заказа' className={errorText.all.includes('примечание') ? `${s.textinput} ${s.errorText}` : s.textinput}></textarea>
			</div>
			<div className={s.add_file}>
				<h2>Добавить</h2>
				<input ref={filePicker} className={s.hidden} onChange={handleChange} type='file' accept='image/*,.png,.jpg,.jpeg,.gif,.wep' />
				<div onClick={handlePick} className={s.add_photo}>
					<span>+</span> Фото{''}
				</div>
				<h2>{file ? 'файл выбран' : ''} </h2>
			</div>
		</div>
	)
}

export default NoteCard
