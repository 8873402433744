import React, { FC, useEffect } from 'react';
import s from './OpenAvatar.module.scss'
import { useAppSelector } from '../../../../store/hooks/hooks';
import empty_avatar from '../../../../assets/PersonalArea/empty_avatar.png'

interface OpenAvatarProps {
    setSwitchAvatar: (e: boolean) => void
    switchAvatar: boolean
}
const OpenAvatar: FC<OpenAvatarProps> = ({ setSwitchAvatar, switchAvatar }) => {
    const { user } = useAppSelector(state => state.user)
    console.log(user?.img);

    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && setSwitchAvatar(false)
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (switchAvatar) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => setSwitchAvatar(false);
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!switchAvatar) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    return (
        <div onClick={() => setSwitchAvatar(false)} className={s.open_avatar}>
            <div onClick={(e) => e.stopPropagation()} className={s.when_open_avatar}>
                <img src={(user?.img && user?.img.startsWith('http')) ? `https${user?.img.slice(4)}` : empty_avatar} alt="avatar" />
            </div>
        </div>
    );
};

export default OpenAvatar;