export const validateEmail = (email: string) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
        return false;
    } else {
        return true;
    }
}

export const clearForm = () => {
    window.location.reload()
}
export const options = {
    types: ['country', 'continent', 'locality']
}
export const name = {
    name: ['aaaaa']
}