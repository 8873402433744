import React, { FC, useState } from 'react';
import { HomePageCars } from '../../store/modules';
import s from './CarSearchCard.module.scss'
import { NavLink } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks/hooks';
import telegram from '../../assets/Header/mobileHeader/telegram.png'
import whatsApp from '../../assets/Header/mobileHeader/whatsApp.png'
import viber from '../../assets/DetailView/viber.png'
import CommetsModal from '../Cargo/CommetsModal/CommetsModal';
interface OpenAvatarProps {
    setCargoId: (e: number | null) => void
    el: HomePageCars
    cargoId: number | null
    i: number
}

const CarsSearchCard: FC<OpenAvatarProps> = ({ el, setCargoId, cargoId, i, }) => {
    const [comment, setComment] = useState(false)

    const { token } = useAppSelector(state => state.user)
    return (
        <div onClick={() => setCargoId(el.id === cargoId ? null : el.id)} className={i % 2 === 0 ? s.map_black : s.map}>
            <div className={(el.id === cargoId) ? s.cargo_search_card_open : s.cargo_search_card}>
                <div className={s.mapdd}>
                    <div className={s.firstDiv}>
                        <p>{el.from_where}</p>
                        <p>{el.to_where}</p>
                        <p>{el.date}</p>
                    </div>
                    <div className={s.secondDiv}>
                        <p> {el.weight} {el.type_body} {el.lifting_capacity}/т</p>
                        <p>{el.price}</p>
                    </div>
                </div>
                <div className={s.spaceBetwen}>
                    {token ?
                        <>
                            <div className={s.left_card}>
                                {el.note.length > 60 ? <p onClick={() => setComment(true)} title={el.note}>{`${el.note?.slice(0, 60)}...`}</p> : el.note ? <p>{el.note}</p> : <p title={el.note}>Комментарий нет</p>}
                            </div>
                            <div className={s.card_right}>
                                <div onClick={(e) => e.stopPropagation()}>
                                    {el.whatsapp ? <a href={`https://wa.me/${el.whatsapp.startsWith('+') ? el.whatsapp.slice(1) : el.whatsapp}?text=Здравствуйте,%20я%20на%20счет%20груза/транспорта%20на%20сайте%20juk.kg.`}><img src={whatsApp} alt="whatsApp" /></a> : <p>WhatsApp не указано</p>}
                                    {el.telegram ? <a href={el?.telegram.startsWith('https') ? el?.telegram : `https://t.me/${el.telegram.startsWith('+') ? el.telegram : el.telegram.startsWith('@') ? el.telegram.slice(1) : el.telegram}`}><img src={telegram} alt="telegram" /></a> : <p>Telegram не указано</p>}
                                    {el.viber ? <a href={`viber://chat?number=%2B${el.viber.startsWith('+') ? el.viber.slice(1) : el.viber}`}><img src={viber} alt="viber" /></a> : <p>Viber не указано</p>}
                                </div>
                                <NavLink to={`/detailview/${el.user}`}>
                                    <p>Перейти к пользователю</p>
                                </NavLink>
                            </div>
                        </>
                        : <div className={s.dopReg}>
                            <p >
                                нужно зарегистрироваться что-бы увидеть доп-информацию!
                            </p>
                        </div>
                    }
                </div>
            </div>
            {comment && <CommetsModal setComment={setComment} comment={comment} text={el.note} />}
        </div>
    );
};

export default CarsSearchCard;