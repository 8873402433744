import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CarsName, } from "../modules";
import { authApi } from "../../axios";

type CarsNameM = {
    loading: boolean
    error: null | string | undefined
    cars: CarsName[]
}

const initialState: CarsNameM = {
    error: null,
    loading: false,
    cars: [],
}

export const fetchByNameCars = createAsyncThunk<CarsName[], void, { rejectValue: string }>(
    'news/fetchByNameCars',
    async (_, { rejectWithValue }) => {
        const res = await authApi.getNameCars()
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data
    }
)

const carsSlice = createSlice({
    name: 'cars',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByNameCars.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByNameCars.fulfilled, (state, action) => {
            state.cars = action.payload
            state.loading = false
        })

        addCase(fetchByNameCars.rejected, (state, action) => {
            // console.log(state);
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'No Broouuuu,No Cars!'
            } else {
                state.error = action.payload
            }
        })
    }

})


export default carsSlice.reducer
