import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AllCommentsDetail, AllRatings, CommentsDetail, DetailViewModules, OrderStatus, } from "../modules";
import { authApi } from "../../axios";

type NewsState = {
    loading: boolean
    error: null | string | undefined
    detailView: DetailViewModules | null
    commentsDetail: CommentsDetail | null
    redirect: boolean
    comentsStatus: OrderStatus | null
}

const initialState: NewsState = {
    error: null,
    loading: false,
    detailView: null,
    commentsDetail: null,
    redirect: false,
    comentsStatus: null,
}

export const fetchByDetailView = createAsyncThunk<DetailViewModules, string, { rejectValue: string }>(
    'detailView/fetchByDetailView',
    async (id, { rejectWithValue }) => {
        const res = await authApi.getDetailView(id)
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data as DetailViewModules
    }
)

export const fetchByRating = createAsyncThunk<void, AllRatings, { rejectValue: string }>(
    'detailView/fetchByRating',
    async (allRatings, { rejectWithValue }) => {
        const res = await authApi.getRatingDetail(allRatings)
        // console.log(res);


    }
)
export const fetchByComments = createAsyncThunk<void, AllCommentsDetail, { rejectValue: string }>(
    'detailView/fetchByComments',
    async (Allcoment, { rejectWithValue }) => {
        const res = await authApi.getcommentsDetail(Allcoment)
        console.log(res);
    }
)





const detailView = createSlice({
    name: 'detailView',
    initialState,
    reducers: {
        toggleRedirect(state, action: PayloadAction<boolean>) {
            state.redirect = action.payload
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByDetailView.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByDetailView.fulfilled, (state, action) => {
            state.detailView = action.payload
            state.loading = false
        })

        addCase(fetchByDetailView.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'No Broouuuu,No News!'
            } else {
                state.error = action.payload
            }
        })

        addCase(fetchByComments.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByComments.fulfilled, (state, action) => {
            state.loading = false
            state.redirect = true
        })

        addCase(fetchByComments.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'No Broouuuu,No News!'
            } else {
                state.error = action.payload
            }
        })

    }

})



export const { toggleRedirect } = detailView.actions
export default detailView.reducer
