import React, { FC, useEffect } from 'react';
import s from './HistoryOfOrders.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import Loading from '../../../../components/Loading/Loading';
import { fetchByHistoryOrder } from '../../../../store/slice/personalDataSlice';
import HistoryCargoCard from './HistoryCargoCard/HistoryCargoCard';

const HistoryOfOrders: FC = () => {
    const dispatch = useAppDispatch()
    const { token, user } = useAppSelector(state => state.user)
    const { comentsStatus, loading, docsCreation } = useAppSelector(state => state.personalArea)
    useEffect(() => {
        token && user &&
            dispatch(fetchByHistoryOrder({ token, id: user.id }))
    }, [dispatch, docsCreation, token, user])
    return (
        <div className={s.HistoryOfOrders}>
            <div className={s.orders}>
                <p>Маршрут</p>
                <p>Дата</p>
                <p>Груз</p>
                <p>Стоимость</p>
            </div>
            <div className={s.output}>
                {comentsStatus.length > 0 ?
                    comentsStatus.map((el, i) => <HistoryCargoCard key={el.id} i={i} el={el} />)
                    : <h2 className={s.title_empty}>Пока что пусто</h2>}
            </div>
            {loading && <Loading />}
        </div >
    );
};

export default HistoryOfOrders;
