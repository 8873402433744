import React, { FC, useEffect, useState } from 'react';
import s from './CarPark.module.scss'
import ShowCar from './ShowCar/ShowCar';
import AddCar from './AddCar/AddCar';
import AddCarModal from '../../../../modal/PAModal/AddCarModal/AddCarModal';
import { useAppSelector } from '../../../../store/hooks/hooks';
import Loading from '../../../../components/Loading/Loading';

interface CarParkProps {
    value: string
}

const CarPark: FC<CarParkProps> = ({ value }) => {
    const { carPark, loading } = useAppSelector(state => state.personalArea)
    const [carsPark, setCarsPark] = useState(false)
    return (
        <div className={s.CarPark}>
            {carPark.length > 0 ? <ShowCar value={value} setCarsPark={setCarsPark} /> : <AddCar setCarsPark={setCarsPark} />}
            {carsPark && <AddCarModal carsPark={carsPark} setCarsPark={setCarsPark} />}
            {loading && <Loading />}
        </div>
    );
};

export default CarPark;