import React, { FC, useState } from 'react';
import s from './UnloadingCard.module.scss'
import { CargoCreation, ErrorText, LoadingType } from '../../../../../../store/modules';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { options } from '../../../../../../other';
interface UnLoadingCardProps {
    getCargoCreation: (key: string, value: string) => void
    setUnLoading: (e: LoadingType[]) => void
    unLoading: LoadingType[]
    errorText: ErrorText
    setToWhere: (e: string) => void
    cargoCreation: CargoCreation
}
const UnloadingCard: FC<UnLoadingCardProps> = ({ cargoCreation, errorText, getCargoCreation, setToWhere, setUnLoading, unLoading }) => {
    const [addAddress, setAddAddress] = useState(false)
    const [loadingType, setLoadingType] = useState<LoadingType>({
        city_country: '',
        address: '',
        time: '',
    })
    const handleOk = () => {
        if (loadingType.address || loadingType.city_country || loadingType.time) {
            setUnLoading([...unLoading, loadingType])
            setLoadingType({ ...loadingType, address: '', time: '' })
        }
    }
    const handleDelete = (index: number) => {
        setUnLoading([...unLoading.filter((el, i) => i !== index)])
    }
    return (
        <div className={s.UnloadingCard}>
            <h2 className={s.title_unloading}><span>&#9913;</span>Разгрузка</h2>
            <div className={s.field_for_info}>
                <div className={s.input_field}>
                    <div className={s.address}>
                        <ReactGoogleAutocomplete
                            options={options}
                            className={errorText.all.includes('Вы не указали город прибывание!') ? `${s.country_city} ${s.errorText} ` : s.country_city}
                            onPlaceSelected={(place) => setToWhere(place.formatted_address)}
                        />
                        <input value={cargoCreation.address_u} onChange={e => getCargoCreation('address_u', e.target.value)} type="text" placeholder='Адрес в населенном пункте' />
                    </div>
                    <div className={s.loading_info}>
                        <div className={s.loading_time}>
                            <h2>Время Разгрузки</h2>
                            <input value={cargoCreation.time_unloading} onChange={e => getCargoCreation('time_unloading', e.target.value)} type="time" />
                        </div>
                        <span onClick={() => setAddAddress(true)} className={addAddress ? s.add_address_none : s.add_address} >Добавить адрес</span>
                    </div>
                </div>
                <div className={addAddress ? s.input_field : s.input_field_none}>
                    <div className={s.address}>
                        <ReactGoogleAutocomplete
                            options={options}
                            className={s.country_city}
                            onPlaceSelected={(place) => setLoadingType((prev) => ({ ...prev, city_country: place.formatted_address }))}
                            language='ru'
                        />
                        <input value={loadingType.address} onChange={e => setLoadingType({ ...loadingType, address: e.target.value })} type="text" placeholder='Адрес в населенном пункте' />
                    </div>
                    <div className={s.loading_info}>
                        <div className={s.loading_time}>
                            <h2>Время Загрузки</h2>
                            <input value={loadingType.time} onChange={e => setLoadingType({ ...loadingType, time: e.target.value })} type="time" />
                        </div>
                        {unLoading.length < 5 && <span onClick={handleOk} className={s.add_address} >Добавить</span>}
                    </div>
                    {unLoading.map((el, i) => (
                        <div className={s.card_places} key={i}>
                            <span onClick={() => handleDelete(i)} className={s.closed}>&#10006;</span>
                            <span className={s.bird}>✓</span>
                            <div className={s.places}>
                                <p className={s.title_in_places}><span className={s.span_in_places}>Страна,город:</span> {el.city_country ? el.city_country : 'не указано'} </p>
                                <p className={s.title_in_places}><span className={s.span_in_places}>Адрес:</span> {el.address ? el.address : 'адрес не указано'} </p>
                                <p className={s.title_in_places}><span className={s.span_in_places}>Время загрузки:</span> {el.time ? el.time : 'время не указано'} </p>
                            </div>
                        </div>
                    ))}
                </div>
                <p className={s.check_for_completion}><span>Убедитесь, что адрес заполнен.</span> Так водитель будет знать, куда ехать</p>
            </div>
        </div >
    );
};

export default UnloadingCard;