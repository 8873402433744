import React, { FC, useEffect } from 'react';
import s from './Backround.module.scss'
import SwiperHomeFirst from './SwiperHomeFirst';
import './Backround.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { fetchByHomeAddUp } from '../../../../store/slice/homeSlice';
import Loading from '../../../../components/Loading/Loading';

const Backround: FC = () => {
    const dispatch = useAppDispatch()
    const { homeAddUp, loading } = useAppSelector(state => state.home)

    useEffect(() => {
        dispatch(fetchByHomeAddUp())
    }, [dispatch])

    if (loading) {
        <Loading />
    }

    return (
        <div className={s.ts}>
            <div className={s.box}>
                <div className={s.back}>
                    <div className='aaa'>
                        <Swiper
                            modules={[Autoplay]}
                            autoplay={{
                                delay: 2500,
                            }}
                            className="mySwiper"
                        >
                            {homeAddUp.map((el, i) => (
                                <SwiperSlide key={el.id}>
                                    <a target='_blank' href={el.link}>
                                        <img src={(el.image && el.image.startsWith('http')) ? `https${el.image.slice(4)}` : ''} alt="reklama" />
                                    </a>
                                </SwiperSlide>
                            ))
                            }
                        </Swiper>
                        <div className={s.absolut}>
                            <SwiperHomeFirst />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Backround;
