import React, { FC } from 'react';
import s from './FreeSixMonths.module.scss'
const FreeSixMonths: FC = () => {
  return (
    <div className={s.FreeSixMonths}>
      <div className={s.card_free_left}>
        <div className={s.in_card}>
          <h2>Бесплатно 6 месяцев</h2>
          <div className={s.card_price}>
            <span>$</span>
            <h2>0</h2>
            <div className={s.right_field}>
              <p>per user</p>
              <p>per month</p>
            </div>
          </div>
          <p>В связи с открытием 6 месяцев бесплатно</p>
          <button>Купить <span> </span></button>
        </div>
      </div>
      <div className={s.card_free_blue}>
        <div className={s.in_card}>
          <h2>Бесплатно 6 месяцев</h2>
          <div className={s.card_price}>
            <span>$</span>
            <h2>0</h2>
            <div className={s.right_field}>
              <p>per user</p>
              <p>per month</p>
            </div>
          </div>
          <p>В связи с открытием 6 месяцев бесплатно</p>
          <button>Купить <span> </span></button>
        </div>
      </div>
      <div className={s.card_free}>
        <div className={s.in_card}>
          <h2>Бесплатно 6 месяцев</h2>
          <div className={s.card_price}>
            <span>$</span>
            <h2>0</h2>
            <div className={s.right_field}>
              <p>per user</p>
              <p>per month</p>
            </div>
          </div>
          <p>В связи с открытием 6 месяцев бесплатно</p>
          <button>Купить <span> </span></button>
        </div>
      </div>
    </div>
  );
};

export default FreeSixMonths;