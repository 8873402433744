import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AddFull, HomePageCargo, HomePageCars, } from "../modules";
import { authApi } from "../../axios";

type NewsState = {
    loading: boolean
    error: null | string | undefined
    home: HomePageCargo[]
    homeCars: HomePageCars[]
    homeAddUp: AddFull[]
    homeAddDown: AddFull[]
}

const initialState: NewsState = {
    error: null,
    loading: false,
    home: [],
    homeCars: [],
    homeAddUp: [],
    homeAddDown: [],

}

export const fetchByHome = createAsyncThunk<HomePageCargo[], void, { rejectValue: string }>(
    'home/fetchByHome',
    async (_, { rejectWithValue }) => {
        const res = await authApi.getHomeOrders()
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.results
    }
)
export const fetchByHomeCars = createAsyncThunk<HomePageCars[], void, { rejectValue: string }>(
    'home/fetchByHomeCars',
    async (_, { rejectWithValue }) => {
        const res = await authApi.getHomeCarsOrders()
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.results
    }
)
export const fetchByHomeAddUp = createAsyncThunk<AddFull[], void, { rejectValue: string }>(
    'home/fetchByHomeAddUp',
    async (_, { rejectWithValue }) => {
        const res = await authApi.addUp()
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.results
    }
)
export const fetchByHomeAddDown = createAsyncThunk<AddFull[], void, { rejectValue: string }>(
    'home/fetchByHomeAddDown',
    async (_, { rejectWithValue }) => {
        const res = await authApi.addDown()
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.results
    }
)

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByHome.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByHome.fulfilled, (state, action) => {
            state.home = action.payload?.reverse()
            state.loading = false
        })

        addCase(fetchByHome.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'No Broouuuu,No News!'
            } else {
                state.error = action.payload
            }
        })
        addCase(fetchByHomeCars.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByHomeCars.fulfilled, (state, action) => {
            state.homeCars = action.payload.reverse()
            state.loading = false
        })

        addCase(fetchByHomeCars.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'No Broouuuu,No News!'
            } else {
                state.error = action.payload
            }
        })
        addCase(fetchByHomeAddUp.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByHomeAddUp.fulfilled, (state, action) => {
            state.homeAddUp = action.payload
            state.loading = false
        })

        addCase(fetchByHomeAddUp.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'No Broouuuu,No News!'
            } else {
                state.error = action.payload
            }
        })
        addCase(fetchByHomeAddDown.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByHomeAddDown.fulfilled, (state, action) => {
            state.homeAddDown = action.payload
            state.loading = false
        })

        addCase(fetchByHomeAddDown.rejected, (state, action) => {
            state.loading = false
            if (action.payload?.includes('404')) {
                state.error = 'No Broouuuu,No News!'
            } else {
                state.error = action.payload
            }
        })
    }

})


export default homeSlice.reducer
