import React, { FC, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../../pages/Home/Home'
import Cargo from '../../pages/Cargo/Cargo'
import Cars from '../../pages/Cars/Cars'
import News from '../../pages/News/News'
import PersonalArea from '../../pages/PersonalArea/PersonalArea'
import NotFount from '../../pages/NotFound/NotFound'
import Registration from '../../pages/Registration/Registration'
import Loading from '../Loading/Loading'
import { fetchByAllNews } from '../../store/slice/newsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks'
import { getLSToken } from '../../LS'
import { fetchByUserData, setToken } from '../../store/slice/userSlice'
import Tariff from '../../pages/Tariff/Tariff'
import DetailedView from '../../pages/Cargo/DetailedView/DetailedView'

const Main: FC = () => {
	const { loading, token, loading_user } = useAppSelector(state => state.user)
	const dispatch = useAppDispatch()

	useEffect(() => {
		let lsToken = getLSToken()
		if (lsToken !== null && lsToken !== undefined) {
			dispatch(setToken(lsToken))
		}
	}, [dispatch])

	useEffect(() => {
		if (token) {
			dispatch(fetchByUserData(token))
		}
	}, [token, loading_user])

	useEffect(() => {
		dispatch(fetchByAllNews())
	}, [dispatch])

	return (
		<main className='main'>
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/detailview/:id' element={<DetailedView />} />
				<Route path='/cargo' element={<Cargo />} />
				<Route path='/cars' element={<Cars />} />
				<Route path='/news' element={<News />} />
				<Route path='/tariff' element={<Tariff />} />
				<Route path='/registration' element={<Registration />} />
				<Route path='/personal-area/:pages' element={<PersonalArea />} />
				<Route path='/*' element={<NotFount />} />
			</Routes>
			{loading && <Loading />}
		</main>
	)
}

export default Main
