import React, { useState, FC } from 'react';
import s from './CargoPagination.module.scss'

import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { filterCargo } from '../../store/slice/cargoSearchSlice';
import CargoSearchCard from './CargoSearchCard';




const CargoPagination: FC = () => {
    const { cargoSearch, error } = useAppSelector(state => state.cargoSearchTs)
    const dispatch = useAppDispatch()
    const [cargoId, setCargoId] = useState<number | null>(null)
    // console.log(cargoSearch);

    const handleFilter = (value: string) => {
        value.includes('date') &&
            dispatch(filterCargo([...cargoSearch].sort((a, b) => +new Date(b.created_at).getTime() - +new Date(a.created_at).getTime())))
        value.includes('price') &&
            dispatch(filterCargo([...cargoSearch].sort((a, b) => +b.price - +a.price)))
        value.includes('weight') &&
            dispatch(filterCargo([...cargoSearch].sort((a, b) => b.weight - a.weight)))
        value.includes('daterevers') &&
            dispatch(filterCargo([...cargoSearch].sort((a, b) => +new Date(a.created_at).getTime() - +new Date(b.created_at).getTime())))
        value.includes('pricerevers') &&
            dispatch(filterCargo([...cargoSearch].sort((a, b) => +a.price - +b.price)))
        value.includes('weightrevers') &&
            dispatch(filterCargo([...cargoSearch].sort((a, b) => a.weight - b.weight)))
    }



    return (
        <div className={s.disk}>
            <div className={s.displayFlex}>
                <h1 className={s.carsSearch}>Найдено {cargoSearch.length === 0 ? 0 : cargoSearch.length} Грузов</h1>
                <div className={s.sel}>
                    <p>Упорядочить по</p>
                    <select onChange={(e) => handleFilter(e.target.value)}>
                        <option value="date">новой дате</option>
                        <option value="daterevers">старой дате</option>
                        <option value="price">наивысшей цене</option>
                        <option value="pricerevers">наименшей цене</option>
                        <option value="weight">наибольшему весу</option>
                        <option value="weightrevers">наименьшему весу</option>
                    </select>
                </div>
            </div>
            <div >
                <div className={s.FiveP}>
                    <p>Направление</p>
                    <p>Маршрут</p>
                    <p>Дата</p>
                    <p>Груз</p>
                    <p>Стоимость</p>
                </div>
                <div className={s.ul}>
                    {
                        error ?
                            <span className='error animate__backOutUp animate__animated'>{error}</span>
                            :
                            cargoSearch && cargoSearch?.length > 0 ?
                                cargoSearch?.map((el, i) => (
                                    <CargoSearchCard key={i} el={el} setCargoId={setCargoId} cargoId={cargoId} i={i} />
                                ))
                                :
                                <h2> No Broouuuu,No Cargo!</h2>
                    }
                </div>
            </div>
        </div>

    );
};
export default CargoPagination;