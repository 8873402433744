import React, { FC } from 'react';
import { useAppSelector } from '../../../../store/hooks/hooks';
import s from './NewsHome.module.scss'
import NewsCard from './NewsCard/NewsCard';
import NewsCardSecond from './NewsCard/NewsCardSecond';
import NewsCardThree from './NewsCard/NewsCardThree';
import Loading from '../../../../components/Loading/Loading';
const NewsHome: FC = () => {
    const { error, news, loading } = useAppSelector(state => state.news)

    if (loading) {
        return <Loading />
    }
    return (
        <div className={s.backround}>
            <h1>Новости</h1>
            <div className={s.container}>
                <div className={s.pro}>
                    <NewsCard news={news[0]} />
                </div>
                <div className={s.column}>
                    <NewsCardSecond news={news[1]} />
                    <NewsCardThree news={news[2]} />
                </div>
            </div>
        </div>
    );
};

export default NewsHome;