import React, { FC, useEffect, useState } from 'react';
import s from './PersonalArea.module.scss'
import { Helmet } from 'react-helmet-async';
import Output from './Output/Output';
import NavigateInPA from './NavigateInPA/NavigateInPA';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { fetchByGetAutoPark } from '../../store/slice/personalDataSlice';
import Loading from '../../components/Loading/Loading';
import { toggleRedirect } from '../../store/slice/userSlice';

const PersonalArea: FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { loading } = useAppSelector(state => state.personalArea)
    const { user, token, redirect } = useAppSelector(state => state.user)
    const [value, setValue] = useState('')
    const [open, setOpen] = useState(false)
    const { pages } = useParams()

    useEffect(() => {
        if (!token && redirect) {
            navigate('/')
        }
    }, [token])

    useEffect(() => {
        setValue(pages ? pages : 'Личный кабинет')
    }, [pages])

    useEffect(() => {
        if (user?.truck_user_data?.id) {
            dispatch(fetchByGetAutoPark(user?.truck_user_data?.id))
        }
    }, [dispatch, user?.truck_user_data?.id])

    return (
        <div className={s.PersonalArea}>
            <Helmet>
                <link rel='canonical' href={`https://juk.kg/personal-area/${pages}`} />
                <title>Личный кабинет | Juk.kg </title>
            </Helmet>
            <div className={s.areaPA}>
                <NavigateInPA open={open} setOpen={setOpen} />
                <Output open={open} value={value} />
            </div>
            {loading && <Loading />}
        </div>
    );
};

export default PersonalArea;