import axios from 'axios'
import { UserData, CargoValidate, AllRatings, AllCommentsDetail, AddAllDocsAuto, UserGetData, ForChangeUD, DataForCarCreation, CargoCarrier, ForAddCar, ForAddDocs, DataForCargoCreation, DeletedDocument, EmailSend, ChangePassword } from '../store/modules'

const instance = axios.create({
	baseURL: 'https://juk.kg:443/api',
	headers: {
		'Content-Type': 'application/json',
	},
})

export const authApi = {
	addNewUser(userData: UserData) {
		// const headers = { "Content-Type": "multipart/form-data" }
		return instance.post('/user/registration/', userData)
	},
	login(userGetData: UserGetData) {
		return instance.post('/user/authentication/', userGetData)
	},
	sendEmail(email: EmailSend) {
		return instance.post('/user/send-code/', email)
	},
	changePassword(password: ChangePassword) {
		return instance.post('/user/reset-password/', password)
	},
	getUserData(token: string) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.get(`/user/user/profile/`, { headers })
	},
	getChangeUserData({ changeUD, token }: ForChangeUD) {
		const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', }
		return instance.put(`/user/user/profile/`, changeUD, { headers })
	},
	getBecomeCargoCarrier({ token, id }: CargoCarrier) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.post(`/user/truck_user/`, { base_user: id }, { headers })
	},
	getAllNews() {
		return instance.get('/news/news/?page_size=20')
	},
	getNameCars() {
		return instance.get('/truck/brand_trucks/')
	},
	getHomeOrders() {
		return instance.get('/order/add_cargo/?limit=50')
	},
	getHomeCarsOrders() {
		return instance.get('/order_cars/add_car/?limit=50')
	},
	getOrdersSearch({ country, countryWhere, weight, weightBefore, volume, volumeBefore, date, dateBefore, }: CargoValidate) {
		return instance.get(
			`/order/add_cargo/?city_country=${country}&city_country_u=${countryWhere}&weight_gt=${weight}&weight_lt=${weightBefore}&volume_gt=${volume}&volume_lt=${volumeBefore}&date_gt=${date}&date_lt=${dateBefore}&limit=500`
		)
	},
	getCarsSearch({ from_where, to_where, weightBefore, weight, volume, volumeBefore, date, dateBefore, Loading, typeCargoCars, }: CargoValidate) {
		return instance.get(
			`/order_cars/add_car/?from_where=${from_where}&type_cargo=${Loading}&to_where=${to_where}&volume_gt=${volume}&volume_lt=${volumeBefore}&date_gt=${date}&date_lt=${dateBefore}&lifting_capacity_gt=${weight}&lifting_capacity_lt=${weightBefore}&type_body=${typeCargoCars}&limit=500`
		)
	},
	getDetailView(id: string) {
		return instance.get(`/user/users/${id}/`)
	},
	getRatingDetail({ token, raiting }: AllRatings) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.post('/rating/ratings/', raiting, { headers })
	},
	getcommentsDetail({ coment, token }: AllCommentsDetail) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.post('/comment/comments/', coment, { headers })
	},
	getOrdersStatus({ token, id }: DeletedDocument) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.get(`/order/order_history/?search=${id}&limit=500`, { headers })
	},
	addCargoCreation({ token, cargoCreation }: DataForCargoCreation) {
		const headers = {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'multipart/form-data',
		}
		return instance.post('/order/add_cargo/', cargoCreation, { headers })
	},
	getDataForCarCreation({ carCreation, token }: DataForCarCreation) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.post('/order_cars/add_car/', carCreation, { headers })
	},
	addDocsAuto({ document, token }: AddAllDocsAuto) {
		const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', }
		return instance.post('/truck/truck_docs/', document, { headers })
	},
	getAutoPark(id: number) {
		return instance.get(`/truck/trucks/?search=${id}&limit=20`)
	},
	getDetailAuto(id: string) {
		return instance.get(`/truck/trucks/${id}/`)
	},
	getBrand() {
		return instance.get(`/truck/brand_trucks/`)
	},
	postAddCar({ addCar, token }: ForAddCar) {
		const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', }
		return instance.post(`/truck/trucks/`, addCar, { headers })
	},
	getDocuments(id: number) {
		return instance.get(`/truck/my_documents/?search=${id}&limit=20`)
	},
	postAddDocs({ addDocs, token }: ForAddDocs) {
		const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', }
		return instance.post(`/truck/my_documents/`, addDocs, { headers })
	},
	deleteAddDocs(id: number) {
		return instance.delete(`/truck/my_documents/${id}/`)
	},
	deleteCarsDocs({ id, token }: DeletedDocument) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.delete(`/truck/truck_docs/${id}/`, { headers })
	},
	deleteCars({ id, token }: DeletedDocument) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.delete(`/truck/trucks/${id}/`, { headers })
	},
	deleteCargo({ id, token }: DeletedDocument) {
		const headers = { Authorization: `Bearer ${token}` }
		return instance.delete(`/order/add_cargo/${id}/`, { headers })
	},
	changeCars({ id, token }: DeletedDocument) {
		const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data', }
		return instance.put(`/truck/trucks/${id}/`, { headers })
	},
	addUp() {
		return instance.get('/reclame/up_reclames//')
	},
	addDown() {
		return instance.get('/reclame/down_reclames//')
	},
}