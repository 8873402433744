import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import s from '../Cargo/Cargo.module.scss'
import CarsPagination from './CarsPagination';
import { fetchByNameCars } from '../../store/slice/carsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { CargoValidate } from '../../store/modules';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { fetchByCarsSearch } from '../../store/slice/carsSearchSlice';
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/Loading/Loading';
import { dateString_max, dateString_min, typeLoadingContainerTrailer } from '../PersonalArea/Output/CreateOrder/assets';
import { typeOfTrailer } from '../Cargo/CargoModules';
import { options } from '../../other';
import { error } from 'console';



const Cars: FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const dispatch = useAppDispatch()
    const { loading, carsSearch, error } = useAppSelector(state => state.carsSearchTs)

    useEffect(() => {
        dispatch(fetchByNameCars())
    }, [dispatch])

    const [errorText, setErrorText] = useState('')
    const [carsP, setcarsP] = useState<CargoValidate>({
        country: '',
        countryWhere: '',
        region: '',
        regionWhere: '',
        city: '',
        cityWhere: '',
        weight: '',
        weightBefore: '',
        volume: '',
        volumeBefore: '',
        date: '',
        dateBefore: '',
        Loading: '',
        type: '',
        typeCargoCars: '',
        from_where: '',
        to_where: '',
        lifting_capacity: '',
    })

    const getUserData = (key: string, value: string) => {
        setcarsP({ ...carsP, [key]: value })
    }

    const handelValidate: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        dispatch(fetchByCarsSearch(carsP))
        setcarsP({
            ...carsP, country: '',
            countryWhere: '',
            region: '',
            regionWhere: '',
            city: '',
            cityWhere: '',
            weight: '',
            weightBefore: '',
            volume: '',
            volumeBefore: '',
            date: '',
            dateBefore: '',
            Loading: '',
            type: '',
            typeCargoCars: '',
            from_where: '',
            to_where: '',
            lifting_capacity: '',
        })
    }

    if (loading) {
        return <Loading />
    }

    return (
        <section className={s.box}>
            <Helmet>
                <link rel='canonical' href='https://juk.kg/cars' />
                <title>Машины | Juk.kg </title>
            </Helmet>
            <div className={s.container}>

                <form onSubmit={handelValidate} className={s.column}>
                    <div className={s.cargoSearch}>
                        <h1>Найти транспорт</h1>
                    </div>
                    <div className={s.disColumn}>
                        <div className={s.map}>
                            <div className={s.mapLeft}>
                                <h2 >Откуда</h2>
                                <ReactGoogleAutocomplete
                                    options={options}
                                    className={errorText.includes('Введите страну отбытия!') ? s.errorRed : s.inputText}
                                    onPlaceSelected={(place) => setcarsP((prev) => ({ ...prev, country: place.formatted_address }))} />
                            </div>

                            <div className={s.weightFirst}>
                                <h2 >Масса</h2>
                                <div >
                                    <p >От</p>
                                    <input value={carsP.weight} onChange={(e) => getUserData('weight', e.target.value)} type="number" placeholder='Масса кг' />
                                    <p >До</p>
                                    <input value={carsP.weightBefore} onChange={(e) => getUserData('weightBefore', e.target.value)} type="number" placeholder='Масса кг' />
                                </div>
                            </div>
                            <div className={s.type}>
                                <h2>Тип прицепа</h2>
                                <select value={carsP.typeCargoCars} onChange={(e) => getUserData('typeCargoCars', e.target.value)}>
                                    <option value="" disabled>Выбрать тип прицепа</option>
                                    {
                                        typeOfTrailer.map((el, i) => <option value={el} key={i}>{el} </option>)
                                    }
                                </select>

                            </div>
                            <div className={s.dateFirst}>
                                <h2 >Дата</h2>
                                <div >
                                    <p >C</p>
                                    <input value={carsP.date} onChange={(e) => getUserData('date', e.target.value)} min={dateString_min} max={dateString_max} type="date" />
                                    <p >По</p>
                                    <input value={carsP.dateBefore} onChange={(e) => getUserData('dateBefore', e.target.value)} min={dateString_min} max={dateString_max} type="date" />
                                </div>
                            </div>


                        </div>
                        <div className={s.mapTwo}>
                            <div className={s.mapRight}>
                                <h2 >Куда</h2>
                                <ReactGoogleAutocomplete
                                    options={options}
                                    className={errorText.includes('Введите страну прибытия!') ? s.errorRed : s.inputText}
                                    onPlaceSelected={(place) => setcarsP((prev) => ({ ...prev, countryWhere: place.formatted_address }))}
                                />
                            </div>
                            <div className={s.weightSecond}>
                                <h2 >Обьем</h2>
                                <div >
                                    <p >От</p>
                                    <input value={carsP.volume} onChange={(e) => getUserData('volume', e.target.value)} type="number" placeholder='Объем m³' />
                                    <p >м³</p>
                                    <p >До</p>
                                    <input value={carsP.volumeBefore} onChange={(e) => getUserData('volumeBefore', e.target.value)} type="number" placeholder='Объем m³' />
                                    <p >м³</p>
                                </div>
                            </div>
                            <div className={s.dateSecond}>
                                <h2 >Погрузка</h2>
                                <div>
                                    <select value={carsP.Loading} onChange={(e) => getUserData('Loading', e.target.value)}>
                                        <option value="" disabled>Выбрать погрузку</option>
                                        {
                                            typeLoadingContainerTrailer.map((el, i) => <option key={i} value={el}>{el}</option>)
                                        }

                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={s.btnDiv}>
                        <button className={s.btn}>
                            <p >Найти </p>
                        </button>
                    </div>

                </form>
                {carsSearch.length > 0 ?
                    <div className={s.paginateDisplay}>
                        <CarsPagination />
                    </div>
                    :
                    <div className={s.errorDiv}>
                        <p>{error}</p>
                    </div>
                }
            </div>
        </section >
    );
};

export default Cars;