import React, { FC, FormEventHandler, useEffect, useState } from 'react'
import s from './Login.module.scss'
import { UserGetData } from '../../../store/modules'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks'
import { fetchByLogin, setEnter, setErrorChange, setLocalUser, setRestorePassword, toggleRedirect, } from '../../../store/slice/userSlice'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '../../../components/Loading/Loading'

const Login: FC = () => {
	const { error, loading, localUser, redirect, enter } = useAppSelector(
		state => state.user
	)
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const [userGetData, setUserGetData] = useState<UserGetData>({
		username: '',
		password: '',
	})
	const [showPassword1, setShowPassword1] = useState(false)
	const [errorText, setErrorText] = useState('')
	const getUserGetData = (key: string, value: string) => {
		setUserGetData({ ...userGetData, [key]: value.trim() })
	}

	if (errorText.includes('Введите username!')) {
		userGetData.username && setErrorText('')
	}

	const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault()
		if (userGetData.username) {
			dispatch(setLocalUser(userGetData))
			dispatch(fetchByLogin(userGetData))
			!error && setEnter(false)
			// console.log(error)
		} else if (!userGetData.username) {
			setErrorText('Введите username!')
		}
	}

	const handleClickShowPassword = () => setShowPassword1(show => !show)

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault()
	}

	const hideModal = () => {
		dispatch(setEnter(false))
		dispatch(setRestorePassword(true))
		dispatch(setErrorChange(null))
		dispatch(setLocalUser(null))
	}

	const hideCloseModal = () => {
		dispatch(setEnter(false))
		dispatch(setErrorChange(null))
		dispatch(setLocalUser(null))
	}

	useEffect(() => {
		if (redirect) {
			navigate('/', { replace: true })
		}
		return () => {
			if (redirect) {
				dispatch(toggleRedirect(false))
			}
		}
	}, [dispatch, redirect])

	useEffect(() => {
		if (localUser !== null) {
			setUserGetData({
				...userGetData,
				username: localUser?.username,
				password: localUser?.password,
			})
			// console.log(userGetData);
		}
	}, [localUser])

	useEffect(() => {
		if (error) {
			!localUser?.username.includes(userGetData?.username) &&
				dispatch(setErrorChange(null))
			!localUser?.password.includes(userGetData?.password) &&
				dispatch(setErrorChange(null))
		}
	}, [error, userGetData.username, userGetData.password])


	useEffect(() => {
		// При рождении убрать скрол
		document.body.style.overflow = 'hidden'
		// При нажатии на ESC закрыть модальное окно
		document.addEventListener('keydown', e => {
			e.code === 'Escape' && hideCloseModal()
		})
		// При рождении навесит другое событие на кнопку назад у браузера
		if (enter) {
			window.history.pushState(null, '', window.location.href)
			window.onpopstate = () => hideCloseModal()
		}
		return () => {
			// При закрытии  модального окна вернуть скролл
			document.body.style.overflow = 'auto'
			// При закрытии убрать действия с кнопки ESC
			document.removeEventListener('keydown', () => { })
			// При закрытии вернуть действие по умолчанию на кнопку назад в браузере
			if (!enter) window.history.back()
			window.onpopstate = () => { }
		}
	}, [])

	if (loading) {
		return <Loading />
	}
	return (
		<div onClick={hideCloseModal} className={s.login}>
			<div onClick={e => e.stopPropagation()} className={s.box}>
				<form onSubmit={handleSubmit} className={s.form}>
					<span onClick={hideCloseModal} className={s.closed}>
						&#10006;
					</span>
					<h2>Вход</h2>
					<div className={s.username}>
						<h3
							className={
								error?.includes('Имя не правильно введен!')
									? s.title_username_error
									: errorText.includes('Введите username')
										? s.title_username_error
										: s.title_username
							}
						>
							{error?.includes('Имя не правильно введен!')
								? error
								: errorText.includes('Введите username')
									? errorText
									: 'Введите логин'}
						</h3>
						<TextField
							className={s.input_username}
							value={userGetData.username}
							onChange={e => getUserGetData('username', e.target.value)}
							label='Логин'
							type='text'
							autoComplete='current-username'
						/>
					</div>
					<div className={s.password}>
						<h3
							className={
								error?.includes('Пароль не правильно введен!')
									? s.title_password_error
									: s.title_password
							}
						>
							{error?.includes('Пароль не правильно введен!')
								? error
								: 'Введите Пароль'}
						</h3>
						<FormControl sx={{ width: '100%' }} variant='outlined'>
							<InputLabel htmlFor='outlined-adornment-password'>
								Пароль
							</InputLabel>
							<OutlinedInput
								value={userGetData.password}
								onChange={e => getUserGetData('password', e.target.value)}
								className={s.input_password}
								type={showPassword1 ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge='end'
										>
											{showPassword1 ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
								label='Password'
							/>
						</FormControl>
					</div>
					<div className={s.error_area}>
						<p onClick={hideModal}>Забыли пароль?</p>
					</div>
					<div className={s.bottom_login}>
						<button>Вход</button>
						<div className={s.bottom}>
							<p>Вы еще не зарегистрированы?</p>
							<Link onClick={hideCloseModal} to={'/registration'}>
								<p className={s.path}>Зарегистрироваться</p>
							</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}

export default Login
