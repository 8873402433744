import React, { FC, useEffect } from 'react';
import s from './LogOut.module.scss'
import { useAppDispatch } from '../../../../store/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../../../store/slice/userSlice';
interface LogOutProps {
  setUserOut: (e: boolean) => void
  userOut: boolean
}
const LogOut: FC<LogOutProps> = ({ setUserOut, userOut }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleOut = () => {
    navigate('/')
    dispatch(logOut())
  }
  useEffect(() => {
    // При рождении убрать скрол
    document.body.style.overflow = 'hidden'
    // При нажатии на ESC закрыть модальное окно
    document.addEventListener('keydown', (e) => {
      e.code === 'Escape' && setUserOut(false)
    })
    // При рождении навесит другое событие на кнопку назад у браузера
    if (userOut) {
      window.history.pushState(null, '', window.location.href)
      window.onpopstate = () => setUserOut(false);
    }
    return () => {
      // При закрытии  модального окна вернуть скролл
      document.body.style.overflow = 'auto'
      // При закрытии убрать действия с кнопки ESC
      document.removeEventListener('keydown', () => { })
      // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
      if (!userOut) window.history.back();
      window.onpopstate = () => { };
    }
  }, [])

  return (
    <div className={s.LogOut} onClick={() => setUserOut(false)} >
      <div className={s.LogOut_card} onClick={(e) => e.stopPropagation()}>
        {/* <span onClick={() => setUserOut(false)} className={s.closed}>&#10006;</span> */}
        <h2 className={s.title_out}>Выход из аккаунта</h2>
        <div className={s.buttons}>
          <button onClick={() => setUserOut(false)} className={s.cancellation}>Отмена</button>
          <button onClick={handleOut} className={s.log_out}>Выйти</button>
        </div>
      </div>
    </div>
  );
};

export default LogOut;