import React, { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react'
import s from './DetailCar.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector, } from '../../../../../store/hooks/hooks'
import { fetchByAddDocsAuto, fetchByDataForDeleteCreation, fetchByGetDetailAuto, } from '../../../../../store/slice/personalDataSlice'
import optimus from '../../../../../assets/PersonalArea/optimus.png'
import SuccesfullCreation from '../../CreateOrder/SuccesfullCreation/SuccesfullCreation'
import DocumentCarCard from './DocumentCarCard/DocumentCarCard'
import Delete from '../../../Delete/Delete'
import EditCar from '../../../../../modal/PAModal/EditCar/EditCar'
const DetailCar: FC = () => {
	const dispatch = useAppDispatch()
	const [carsPark, setCarsPark] = useState(false)
	const [searchParams] = useSearchParams()
	const [query] = useState(searchParams.get('id'))
	const { token } = useAppSelector(state => state.user)
	const filePicker = useRef<HTMLInputElement>(null)
	const [docsa, setDocsa] = useState<File>()
	const { detailCar, dataCreation, error, loadingPlace, docsCreation, delAuto } = useAppSelector(state => state.personalArea)
	const [delDocs, setDelDocs] = useState(false)
	const [deleted, setDeleted] = useState(false)
	const navigate = useNavigate()
	const deleteDocs = () => {
		setDelDocs(true)
	}
	useEffect(() => {
		query && dispatch(fetchByGetDetailAuto(query))
	}, [dispatch, query, loadingPlace])
	useEffect(() => {
		if (deleted && token && detailCar) {
			dispatch(fetchByDataForDeleteCreation({ token, id: detailCar?.id }))
		}
	}, [deleted])
	useEffect(() => {
		if (query && docsa && token) {
			dispatch(
				fetchByAddDocsAuto({
					document: { truck: query, docs: docsa },
					token: token,
				})
			)
		}
	}, [dispatch, query, docsa])
	const handlePick = () => {
		if (filePicker.current) {
			// использование filePicker.current без предупреждений о типе
			filePicker.current.focus()
			filePicker.current.click()
		}
	}
	const handleChangeDocs: ChangeEventHandler<HTMLInputElement> = e => {
		if (e.target.files) {
			setDocsa(e.target.files[0])
		}
	}
	// console.log(error);
	useEffect(() => {
		if (delAuto) {
			navigate(-1)
		}
	}, [delAuto])
	return (
		<div className={s.DetailCar}>
			<div className={s.card_car_detail}>
				<div className={s.detail_info}>
					<div className={s.info_personal}>
						<h2>Марка Машины </h2>
						<p>{detailCar?.model} </p>
					</div>
					<div className={s.info_personal}>
						<h2>Модель Машины</h2>
						<p>{detailCar?.brand_title} </p>
					</div>
					<div className={s.info_personal}>
						<h2>Гос номер</h2>
						<p>{detailCar?.state_number} </p>
					</div>
					<div className={s.info_pers_file}>
						<h2>Хотите добавить документ</h2>
						<div className={s.file_input}>
							<input className={s.input_doc} onClick={handlePick} type='button' value={'Выберите файл'} />
							<input className={s.hidden} onChange={handleChangeDocs} type='file' ref={filePicker} placeholder='Долумент' accept='.docx,.pdf' />
						</div>
					</div>
				</div>
				<div className={s.detail_img}>
					<img src={(detailCar?.image && detailCar?.image.startsWith('http')) ? `https${detailCar?.image.slice(4)}` : optimus} alt='car' />
				</div>
			</div>
			<div className={s.deleted_btn}>
				<button className={s.deleted_car} onClick={deleteDocs}>Удалить машину</button>
				{/* <button onClick={() => setCarsPark(true)}>Редактировать</button> */}
			</div>
			<div className={s.output_docs}>
				<div className={s.blue_bg}> </div>
				{detailCar?.truck_docs && detailCar?.truck_docs?.length > 0 ?
					detailCar?.truck_docs.map((el, i) => (
						<DocumentCarCard key={el.id} i={i} el={el} />
					)) : <h2 className={s.before}>Документов на этой машине нет.</h2>
				}

			</div>
			{(error?.includes('Упс что то пошло не так попробуйте снова!') || dataCreation) && (
				<SuccesfullCreation text='Документ успешно добавлен' />
			)}
			{delDocs && <Delete text={'Вы точно хотите удалить эту машину'} setDeleted={setDeleted} delDocs={delDocs} setDelDocs={setDelDocs} name={detailCar?.model ? detailCar?.model : ''} />}
			{(docsCreation || error?.includes('Упс что то пошло не так попробуйте снова!')) && <SuccesfullCreation text={'Машина успешно удалена'} />}
			{carsPark && <EditCar detailCar={detailCar} carsPark={carsPark} setCarsPark={setCarsPark} />}
		</div>
	)
}

export default DetailCar
