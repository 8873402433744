import React from 'react';
import s from './PersonalAccaunt.module.scss'
import ikon from '../../../assets/Header/ikon.png';
import { NavLink } from 'react-router-dom';
const PersonalAccaunt = () => {
    return (
        <NavLink to={'/personal-area/personal_area'} ><img className={s.ikon} src={ikon} alt="ikon" /></NavLink>
    );
};

export default PersonalAccaunt;