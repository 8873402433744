export const arrMassiv = ['автовоз', 'автовышка', 'автотранспортер', 'бетоновоз', 'битумовоз', 'бензовоз', 'вездеход', 'газовоз', 'зерновоз', 'коневоз', 'контейнеровоз', 'кормовоз', 'кран', 'лесовоз', 'ломовоз', 'манипулятор', 'микроавтобус', 'муковоз', 'панелевоз', 'пикап', 'пухтовоз', 'пирамида', 'рулоновоз', 'седельныйтягач', 'скотовоз', 'стекловоз', 'трубовоз', 'цементовоз', 'автоцистерна', 'щеповоз', 'эвакуатор', 'грузопассажирский', 'клюшковоз', 'мусоровоз', 'jumbo', '(20) танк - контейнер', '(40) танк - контейнер', 'мега фура', 'допельшток', 'Раздвижной полуприцеп 20(/40)',]

export const typeOfTrailer = ['Крытое', 'Открытое', 'Цистерна', 'Автовоз', 'Рефрижератор', 'Спец техника']

export const popularCargo = ['ТНП', 'Сборные груз', 'Оборудование', 'Стройматериал', 'Металл', 'Продукты', 'Запчасти', 'Сырье', 'Мебель', 'Трубы', 'Автомобиль', 'Контейнер']
export const typeOfCargo = ['Арматура', 'Автопогрузчик', 'Аккумуляторы', 'Антифриз', 'Шины', 'Алкогольные напитки', 'Безалкогольные напитки', 'Бумага', 'Бытовая техника', 'Бытовая химия', 'Двп', 'Лдсп', 'Двери', 'Древесина', 'Древесный уголь', 'Животное', 'Зерно и семена', 'Игрушки', 'Канц.товары', 'Кирпич', 'Консервы', 'Кондитерские изделия', 'Кормовые\пищевые добавки', 'Фрукты', 'Молочные продукты', 'Мороженое', 'Ковры', 'Крупа', 'Макулатура', 'Медикаменты', 'Мясо', 'Нефтепродукты', 'Медицинское оборудование', 'Одежда', 'Овощи', 'Парфюмерия и косметика', 'Пенопласт', 'Песок', 'Пиво', 'Пиломатериалы', 'Поддоны', 'Рыба', 'Сантехника', 'Сахар', 'Соки', 'Соль', 'Стекло', 'Фарфор', 'Стеклотара', 'Сэндвич - панели', 'Табачные изделия', 'Тара и упаковка', 'Текстиль', 'Транспортные средства', 'Трубы', 'Спецтехника', 'Удобрения', 'Утеплитель', 'Фанера', 'Xим продукты опасные', 'Xим продукткы неопасные', 'Xозтовары', 'Xолодильное оборудование', 'Цветы', 'Цемент', 'Чипсы', 'ЖБИ', 'Щебень', 'Электроника', 'Ягоды', 'Другое']


export const boxTrailer = ['Стандартный фургон', 'Фургон с боковыми дверями', 'Фургон с раздвижным верхом', 'Фургон с подъемной платформой', 'Фургон с климатическим контролем']

export const refrigeratedTrailer = ['Стандартные прицепы-рефрижераторы', 'Многокамерные прицепы-рефрижераторы', 'Прицепы-рефрижераторы с двойной температурой', 'Специализированные прицепы-рефрижераторы', 'Прицепы-рефрижераторы с технологиями отслеживания и мониторинга']

export const flatbedTrailer = ['Стандартные плоскобоковые прицепы', 'Расширяемые прицепы с платформой', 'Низкоподдонные прицепы с платформой', 'Прицепы с платформой для перевозки контейнеров', 'Прицепы с платформой для перевозки автомобилей', 'Прицепы с платформой для перевозки строительного материала']

export const lowboyTrailer = ['Стандартный низкорамный прицеп', 'Расширяемый низкорамный прицеп', 'Низкорамный прицеп с телескопической платформой', 'Низкорамный прицеп с гидравлическим подъемом', 'Низкорамный прицеп с крюком-гусеницей', 'Низкорамный прицеп с распорными колесами']

export const tankerTrailer = ['Цистерна для воды', 'Нефтяная цистерна', 'Химическая цистерна', 'Цистерна для пищевых продуктов', 'Цистерна для хранения и перевозки газов', 'Битумная цистерна']

export const containerTrailer = ['Стандартные контейнеровозы', 'Малотоннажные контейнеровозы', 'Рефрижераторные контейнеровозы', 'Химические и опасные грузы контейнеровозы', 'Контейнеровозы с высокими крышами', 'Специализированные контейнеровозы']

export const livestockTrailer = ['Прицепы для лошадей', 'Прицепы для скота', 'Прицепы для свиней и овец', 'Прицепы для собак', 'Прицепы для птиц и мелких животных']


export const allTypseOfLoading = [...boxTrailer, ...refrigeratedTrailer, ...flatbedTrailer, ...lowboyTrailer, ...tankerTrailer, ...containerTrailer, ...livestockTrailer]

export const typeLoadingBoxTrailer = ['Тент', 'Реф', 'Изотерм', 'Цельнометал', 'Контейнер']

export const typeLoadingRefrigeratedTrailer = ['Бортовая/открытая', 'Платформа', 'Трал', 'Самосвал']

export const typeLoadingFlatbedTrailer = ['Автоцистерна', 'Молоковоз', 'Бензовоз', 'Бетоновоз', 'Цементовоз', 'Газовоз']

export const typeLoadingLowboyTrailer = ['Эвакуатор', 'Лесовоз', 'Негабарит', 'Скотовоз', 'Яхтовоз']

export const typeLoadingTankerTrailer = ['Заливка сверху', 'Наполнение снизу', 'Загрузка с боков', 'Погрузка с использованием насосов или компрессоров', 'Использование специализированного оборудования']

export const typeLoadingContainerTrailer = ['Задняя', 'Боковая', 'Верхняя', 'Задняя/Боковая ', 'Задняя/Боковая/Верхняя', 'Со снятием стоек', 'Со снятием поперечин', 'Без ворот', 'С полной растентовкой', ' С бортами,', ' С обрешеткой', 'Гидроборт']

// export const typeLoadingLivestockTrailer = ['Прямая загрузка через двери', 'Использование пандусов или лебедо', 'Использование специализированного оборудования']


// export const typeLoadingdd = [...typeLoadingContainerTrailer, ...typeLoadingRefrigeratedTrailer, ...typeLoadingFlatbedTrailer, ...typeLoadingLowboyTrailer, ...typeLoadingTankerTrailer, ...typeLoadingBoxTrailer, ...typeLoadingLivestockTrailer]


// export let typeLoading = typeLoadingdd.filter((item, index) => typeLoadingdd.indexOf(item) === index);


const currentDate = new Date();
const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1; // Месяцы начинаются с 0, поэтому добавляем 1
const day = currentDate.getDate();
export const dateString_min = (year - 1) + '-' + (month < 10 ? '0' + month : month) + '-' + day;
export const dateString = year + '-' + (month < 10 ? '0' + month : month) + '-' + day;
export const dateString_max = (year + 1) + '-' + (month < 10 ? '0' + month : month) + '-' + day;



