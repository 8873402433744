import React, { FC } from 'react'
import s from '../PersonalData.module.scss'
import { useAppSelector } from '../../../../../store/hooks/hooks'

const Rattings: FC = () => {
	const { user } = useAppSelector(state => state.user)
	return (
		<div className={s.rating}>
			<h2>Рейтинг</h2>
			<div className={s.rating_star}>
				<span
					className={
						Math.ceil(Number(user?.average_rating)) >= 1
							? s.star_block
							: s.star_filled
					}
				>
					★
				</span>
				<span
					className={
						Math.ceil(Number(user?.average_rating)) >= 2
							? s.star_block
							: s.star_filled
					}
				>
					★
				</span>
				<span
					className={
						Math.ceil(Number(user?.average_rating)) >= 3
							? s.star_block
							: s.star_filled
					}
				>
					★
				</span>
				<span
					className={
						Math.ceil(Number(user?.average_rating)) >= 4
							? s.star_block
							: s.star_filled
					}
				>
					★
				</span>
				<span
					className={
						Math.ceil(Number(user?.average_rating)) >= 5
							? s.star_block
							: s.star_filled
					}
				>
					★
				</span>
			</div>
		</div>
	)
}

export default Rattings
