import React, { FC } from 'react';
import s from './OtherData.module.scss'
import { CarCreation, ErrorText } from '../../../../../../store/modules';
interface OtherDataProps {
    getCarCreation: (key: string, value: string) => void
    setCurrency: (e: string) => void
    setPrice: (e: string) => void
    setDop: (e: string) => void
    price: string
    currency: string
    carCreation: CarCreation
    errorText: ErrorText
}
const OtherData: FC<OtherDataProps> = ({ setDop, getCarCreation, carCreation, errorText, currency, setCurrency, price, setPrice }) => {
    return (
        <div className={s.OtherData}>
            <div className={s.price}>
                <h2>Дополнительно</h2>
                {/* <select className={s.paragraph} onChange={e => getCarCreation('additionally', e.target.value)}>
                    <option value="Догруз">Догруз</option>
                    <option value="Сборный груз">Сборный груз</option>
                    <option value="Отдельное авто">Отдельное авто</option>
                    <option value="Несколько авто">Несколько авто</option>
                </select> */}
                <div className={s.select_fields}>
                    <label><input className={s.input_in_label} onChange={e => getCarCreation('additionally', e.target.value)} name="hoh" type="radio" value={'Отдельное авто'} />Отдельное авто</label>
                    <label><input className={s.input_in_label} onChange={e => getCarCreation('additionally', e.target.value)} name="hoh" type="radio" value={'Догруз'} />Догруз</label>
                    <label><input className={s.input_in_label} onChange={e => getCarCreation('additionally', e.target.value)} name="hoh" type="radio" value={'Несколько авто'} />Несколько авто {carCreation.additionally.includes('Несколько авто') && <input onChange={e => setDop(e.target.value)} className={s.quantity} type="number" placeholder='количество' />}</label>
                    <label><input className={s.input_in_label} onChange={e => getCarCreation('additionally', e.target.value)} name="hoh" type="radio" value={'Сборный груз'} />Сборный груз</label>
                </div>
            </div>
            <div className={s.price}>
                <h2>Стоимость</h2>
                <input className={errorText.all.includes('стоимость') ? s.errorText : ''} value={price} onChange={e => setPrice(e.target.value)} type="number" placeholder='Стоимость' />
                <select onChange={(e) => setCurrency(e.target.value)} value={currency} className={s.currency}>
                    <option value="Сом">Сом</option>
                    <option value="$">$</option>
                    <option value="€">€</option>
                    <option value="Рубль">Рубль</option>
                    <option value="Тенге">Тенге</option>
                </select>
            </div>
            <div className={s.note}>
                <h2>Примечание</h2>
                <textarea
                    onChange={e => getCarCreation('note', e.target.value)}
                    value={carCreation.note}
                    rows={4}
                    cols={50}
                    placeholder='Описание заказа'
                    className={errorText.all.includes('примечание') ? `${s.textinput} ${s.errorText}` : s.textinput}
                ></textarea>
                {/* <input className={errorText.all.includes('примечание') ? s.errorText : ''} value={carCreation.note} onChange={e => getCarCreation('note', e.target.value)} type="text" /> */}
            </div>
            <div className={s.contacts}>
                <h2>Контакты</h2>
                <div className={s.indication}>
                    <p>Укажите, к кому обратится по объявлению</p>
                    <div className={s.inputs}>
                        <input className={errorText.all.includes('Вы не указали имя!') ? s.errorText : ''} value={carCreation.name} onChange={e => getCarCreation('name', e.target.value)} type="text" placeholder='Имя' />
                        <input className={errorText.all.includes('Вы не указали номер!') ? s.errorText : ''} value={carCreation.phone_number} onChange={e => getCarCreation('phone_number', e.target.value)} type="text" placeholder='Телефон' />
                    </div>
                    <div className={s.inputs}>
                        <input value={carCreation.telegram} onChange={e => getCarCreation('telegram', e.target.value)} type="url" placeholder='Telegram' />
                        <input value={carCreation.whatsapp} onChange={e => getCarCreation('whatsapp', e.target.value)} type="phone" placeholder='WhatsApp' />
                    </div>
                    <div className={s.inputs}>
                        <input value={carCreation.viber} onChange={e => getCarCreation('viber', e.target.value)} type="text" placeholder='Viber' />
                    </div>
                </div>
            </div>
        </div >
    );
};

export default OtherData;