import React, { FC, useEffect } from 'react';
import s from './CommetsModal.module.scss'
interface CommetsModalProps {
  setComment: (e: boolean) => void
  comment: boolean
  text: string
}
const CommetsModal: FC<CommetsModalProps> = ({ comment, setComment, text }) => {
  useEffect(() => {
    // При рождении убрать скрол
    document.body.style.overflow = 'hidden'
    // При нажатии на ESC закрыть модальное окно
    document.addEventListener('keydown', (e) => {
      e.code === 'Escape' && setComment(false)
    })
    // При рождении навесит другое событие на кнопку назад у браузера
    if (comment) {
      window.history.pushState(null, '', window.location.href)
      window.onpopstate = () => setComment(false);
    }
    return () => {
      // При закрытии  модального окна вернуть скролл
      document.body.style.overflow = 'auto'
      // При закрытии убрать действия с кнопки ESC
      document.removeEventListener('keydown', () => { })
      // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
      if (!comment) window.history.back();
      window.onpopstate = () => { };
    }
  }, [])
  return (
    <div className={s.Comment} onClick={() => setComment(false)} >
      <div className={s.Comment_card} onClick={(e) => e.stopPropagation()}>
        <span onClick={() => setComment(false)} className={s.closed}>&#10006;</span>
        <p>{text} </p>
      </div>
    </div >
  );
};

export default CommetsModal;