import React, { FC, useEffect, useState } from 'react';
import s from './Header.module.scss';
import Login from '../../modal/authOffice/Login/Login';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import RestorePassword from '../../modal/authOffice/RestorePassword/RestorePassword';
import Confirmation from '../../modal/authOffice/Confirmation/Confirmation';
import NewPassword from '../../modal/authOffice/NewPassword/NewPassword';
import HeaderTop from './HeaderTop/HeaderTop';
import Navigate from './Navigate/Navigate';
import PersonalAccaunt from './PersonalAccaunt/PersonalAccaunt';
import Authorization from './Authorization/Authorization';
import MobileHeader from './MobileHeader/MobileHeader';


const Header: FC = () => {
    const { token, enter, restorePassword, confirmation, newPassword } = useAppSelector(state => state.user)
    return (
        <header>
            <div className={s.mobile_header}>
                <MobileHeader />
            </div>
            <div className={s.header_basic}>
                <HeaderTop />
                <div className={s.header_bottom}>
                    <div className={s.navbar}>
                        <div className={s.box}>
                            <Navigate />
                            <div className={s.user_field}>
                                {
                                    token ?
                                        <PersonalAccaunt />
                                        :
                                        <Authorization />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {enter && <Login />}
            {restorePassword && <RestorePassword />}
            {confirmation && <Confirmation />}
            {newPassword && <NewPassword />}
        </header>
    );
};

export default Header;