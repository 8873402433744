import React, { FC, useEffect, useState } from 'react';
import s from './HomePagination.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { fetchByHome, fetchByHomeCars } from '../../../../store/slice/homeSlice';
import Loading from '../../../../components/Loading/Loading';
import CargoSearchCard from '../../../Cargo/CargoSearchCard';
import CarsSearchCard from '../../../Cars/CarsSearchCard';

const HomePagination: FC = () => {
    const [dot, setDot] = useState(true);
    const [cargoId, setCargoId] = useState<number | null>(null)
    const dispatch = useAppDispatch()
    const { home, homeCars, loading } = useAppSelector(state => state?.home)

    useEffect(() => {
        dispatch(fetchByHome())
        dispatch(fetchByHomeCars())
    }, [dispatch])

    if (loading) {
        return <Loading />
    }
    return (
        <div className={s.disk}>
            <div className={s.container}>

                <div className={s.displayFlex}>
                    <h2 className={s.currentOffers}>Актуальные предложения по грузам для перевозки</h2>
                </div>
                <div className={s.twoButton}>
                    <button className={dot ? s.active : s.neactive} onClick={() => setDot(true)}>Грузы</button>
                    <button className={!dot ? s.active : s.neactive} onClick={() => setDot(false)}>Транспорты</button>
                </div>
                <div>
                    <div className={s.FiveP}>
                        <p>Направление</p>
                        <p>Маршрут</p>
                        <p>Дата</p>
                        <p>Груз</p>
                        <p>Стоимость</p>
                    </div>
                    {
                        dot ?
                            <div className={s.ul}>
                                {home.length > 0 && home?.slice(0, 10)?.map((el, i) => (
                                    <CargoSearchCard key={i} el={el} setCargoId={setCargoId} cargoId={cargoId} i={i} />
                                ))}
                            </div>
                            :
                            <div className={s.ull}>
                                {homeCars.length > 0 && homeCars?.slice(0, 10)?.map((el, i) => (
                                    <CarsSearchCard key={i} el={el} setCargoId={setCargoId} cargoId={cargoId} i={i} />
                                ))}
                            </div>
                    }
                </div>
            </div>
        </div >
    );
};
export default HomePagination;