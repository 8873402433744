import React, { FC, useEffect, useState } from 'react';
import s from './News.module.scss'
import { useAppSelector } from '../../store/hooks/hooks';
import { Helmet } from 'react-helmet-async';
import Loading from '../../components/Loading/Loading';
import { Pagination } from '@mui/material';

const News: FC = () => {
    const [id, setId] = useState('')
    const [newsPage, setNewsPage] = useState(1)
    const [pageNews] = useState(3)
    const { error, news, loading } = useAppSelector(state => state.news)

    const pag = (e: React.ChangeEvent<unknown>, page: number) => {
        setNewsPage(page)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <div className={s.box}>
            <Helmet>
                <link rel='canonical' href='https://juk.kg/news' />
                <title>Новости | Juk.kg </title>
            </Helmet>
            <div className={s.container}>
                {
                    error ?
                        <div className={s.errorDiv}>
                            <span className={s.error_text}>{error}</span>
                        </div>
                        :
                        news && news?.length > 0 ?
                            news?.slice((newsPage * pageNews) - pageNews, newsPage * pageNews).map((el) => (
                                <div key={el?.id} onClick={() => setId(el.id === id ? '' : el.id)} className={el.id === id ? s.bbb : s.aaa}>
                                    {el.id !== id ?
                                        <div className={s.display}>
                                            <div className={s.imgAAA}>
                                                <img className='animate__backOutLeft' src={(el?.image && el?.image.startsWith('http')) ? `https${el?.image.slice(4)}` : ''} alt="img" />
                                            </div>
                                            <div className={s.column}>
                                                <p className={s.title}>{el?.title}</p>
                                                <p>{el.text.length >= 80 ? `${el.text.slice(0, 80)}...` : el.text}</p>
                                                <span className={s.pod}>Подробнее...</span>
                                            </div>
                                        </div>
                                        :
                                        <div className={s.www}>
                                            {/* <p className={s.title}>{el}</p> */}
                                            <p className={s.opis}>
                                                <span className={s.title_news}>{el?.title}</span>
                                                <img className={`animate__backInRight ${s.news_img}`} src={(el?.image && el?.image.startsWith('http')) ? `https${el?.image.slice(4)}` : ''} alt="img" />
                                                <br />
                                                <p className={s.text}>{el?.text}</p>
                                            </p>
                                            <div className={s.mobile}>
                                                <img src={(el?.image && el?.image.startsWith('http')) ? `https${el?.image.slice(4)}` : ''} alt="img" />
                                                <h2>{el?.title}</h2>
                                                <p>{el?.text}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                            :
                            <div className={s.errorDiv}>
                                <p className={s.error_text}>{error}</p>
                            </div>
                }
                <div className={s.pagination}>
                    {news.length > 0 && <Pagination onChange={pag} count={Math.ceil(news.length / 3)} />}
                </div>
            </div>
        </div>
    );
};

export default News;