import React, { ChangeEventHandler, FC, FormEventHandler, useEffect, useRef, useState, } from 'react'
import s from './ChangingUserData.module.scss'
import photo from '../../../assets/PersonalArea/photo.png'
import { ChangeUserData } from '../../../store/modules'
import { validateEmail } from '../../../other'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks'
import { fetchByChangeUserData, setErrorChange, setLocalChangeUser, } from '../../../store/slice/userSlice'
import Loading from '../../../components/Loading/Loading'
interface ChangingUserDataProps {
	setChangeUserData: (e: boolean) => void
	changeUserData: boolean
}
const ChangingUserData: FC<ChangingUserDataProps> = ({ changeUserData, setChangeUserData, }) => {
	const dispatch = useAppDispatch()
	const { token, loading, error, localChangeUser, user } = useAppSelector(state => state.user)
	const [file, setFile] = useState<File | null>(null)
	const [errorText, setErrorText] = useState<string>('')
	const [changeUD, setChangeUD] = useState<ChangeUserData>({
		city: '',
		country: '',
		email: '',
		phone: '',
		username: '',
		img: null,
		telegram: '',
		viber: '',
		whatsapp: '',
	})

	if (errorText.includes('Введите username!')) {
		changeUD.username && setErrorText('')
	} else if (errorText.includes('Некоректный email')) {
		!validateEmail(changeUD.email) && setErrorText('')
	} else if (errorText.includes('Введите номер')) {
		changeUD.phone && setErrorText('')
	} else if (errorText.includes('Введите город')) {
		changeUD.city && setErrorText('')
	} else if (errorText.includes('Введите страну')) {
		changeUD.country && setErrorText('')
	}

	const handleChangeUserData: FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault()
		if (!validateEmail(changeUD.email) && changeUD.phone && changeUD.username && changeUD.city && changeUD.country) {
			dispatch(setLocalChangeUser({ ...changeUD, img: file }))
			token &&
				dispatch(fetchByChangeUserData({ token: token, changeUD: { ...changeUD, img: file }, }))
			!error && setChangeUserData(false)
			!error && dispatch(setErrorChange(null))
		} else if (!changeUD.username) {
			setErrorText('Введите username!')
		} else if (validateEmail(changeUD.email)) {
			setErrorText('Некоректный email!')
		} else if (!changeUD.phone) {
			setErrorText('Введите номер!')
		} else if (!changeUD.city) {
			setErrorText('Введите город!')
		} else if (!changeUD.country) {
			setErrorText('Введите страну!')
		} else if (!validateEmail(changeUD.email)) {
			setErrorText('')
		}
	}

	const getChangeUD = (key: string, value: string) => {
		setChangeUD({ ...changeUD, [key]: value })
	}

	const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
		if (e.target.files) {
			setFile(e.target.files[0])
		}
	}

	const hideCloseModal = () => {
		setChangeUserData(false)
		dispatch(setErrorChange(null))
		dispatch(setLocalChangeUser(null))
	}

	useEffect(() => {
		if (user) {
			setChangeUD({ ...changeUD, username: user?.username, email: user?.email, phone: user?.phone, city: user?.city ? user?.city : '', country: user?.country ? user?.country : '', whatsapp: user?.whatsapp ? user?.whatsapp : '', telegram: user?.telegram ? user?.telegram : '', viber: user?.viber ? user?.viber : '' })
		}
	}, [localChangeUser, error])
	useEffect(() => {
		if (error) {
			!localChangeUser?.username.includes(changeUD?.username) &&
				dispatch(setErrorChange(null))
		} else if (error) {
			file &&
				dispatch(setErrorChange(null))
		}
	}, [error, changeUD.username])

	useEffect(() => {
		// При рождении убрать скрол
		document.body.style.overflow = 'hidden'
		// При нажатии на ESC закрыть модальное окно
		document.addEventListener('keydown', e => {
			e.code === 'Escape' && hideCloseModal()
		})
		// При рождении навесит другое событие на кнопку назад у браузера
		if (changeUserData) {
			window.history.pushState(null, '', window.location.href)
			window.onpopstate = () => hideCloseModal()
		}
		return () => {
			// При закрытии  модального окна вернуть скролл
			document.body.style.overflow = 'auto'
			// При закрытии убрать действия с кнопки ESC
			document.removeEventListener('keydown', () => { })
			// При закрытии вернуть действие по умолчанию на кнопку назад в браузере
			if (!changeUserData) window.history.back()
			window.onpopstate = () => { }
		}
	}, [])

	return (
		<div onClick={hideCloseModal} className={s.ChangingUserData}>
			<div onClick={e => e.stopPropagation()} className={s.card_ChangingUserData}>
				<span onClick={hideCloseModal} className={s.closed}>&#10006;</span>
				<form className={s.form_ChangingUserData} onSubmit={handleChangeUserData}>
					<div className={s.file_dowload}>
						<h2 className={error?.includes('Упс что то вы заполнили не так!') ? s.errorText : ''}>Фото контакта</h2>
						<label htmlFor='avatar' className={s.pictures}>
							<input id='avatar' onChange={handleChange} className={s.hidden} type='file' accept='image/*,.png,.jpg,.jpeg,.gif,.wep' />
							<img src={photo} alt='photocamera' />
						</label>
					</div>
					<div className={s.info_field}>
						<h2 className={errorText.includes('Введите username') ? s.error_name : error ? s.error_name : s.all_style}>
							{error?.includes('Значения поля должны быть уникальны') ? error : 'Введите Имя'}{' '}
						</h2>
						<input value={changeUD.username} onChange={e => getChangeUD('username', e.target.value)} type='text' placeholder='Имя' />
					</div>
					<div className={s.info_field}>
						<h2 className={errorText.includes('Некоректный email') ? s.error_Email : s.all_style}>Введите Email</h2>
						<input value={changeUD.email} onChange={e => getChangeUD('email', e.target.value)} type='text' placeholder='Email' />
					</div>
					<div className={s.info_field}>
						<h2 className={errorText.includes('Введите номер') ? s.error_phone : s.all_style}>Введите Телефон</h2>
						<input value={changeUD.phone} onChange={e => getChangeUD('phone', e.target.value)} type='text' placeholder='Телефон ' />
					</div>
					<div className={s.info_field}>
						<h2 className={errorText.includes('Введите город') ? s.error_city : s.all_style}>Введите Город</h2>
						<input value={changeUD.city} onChange={e => getChangeUD('city', e.target.value)} type='text' placeholder='Город' />
					</div>
					<div className={s.info_field}>
						<h2 className={errorText.includes('Введите страну') ? s.error_country : s.all_style}>Введите Страну</h2>
						<input value={changeUD.country} onChange={e => getChangeUD('country', e.target.value)} type='text' placeholder='Страна' />
					</div>
					<div className={s.info_field}>
						<h2 className={s.all_style}>WhatsApp</h2>
						<input value={changeUD.whatsapp} onChange={e => getChangeUD('whatsapp', e.target.value)} type='text' placeholder='WhatsApp' />
					</div>
					<div className={s.info_field}>
						<h2 className={s.all_style}>Telegram</h2>
						<input value={changeUD.telegram} onChange={e => getChangeUD('telegram', e.target.value)} type='text' placeholder='Telegram' />
					</div>
					<div className={s.info_field}>
						<h2 className={s.all_style}>Viber</h2>
						<input value={changeUD.viber} onChange={e => getChangeUD('viber', e.target.value)} type='text' placeholder='Viber' />
					</div>
					<button className={s.save_data}>Сохранить</button>
				</form>
			</div >
			{loading && <Loading />}
		</div >
	)
}

export default ChangingUserData
