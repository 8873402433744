import React, { FC, useEffect, useState } from 'react'
import s from './PersonalData.module.scss'
import { useAppSelector } from '../../../../store/hooks/hooks'
import ChangingUserData from '../../../../modal/PAModal/ChangingUserData/ChangingUserData'
import SuccessfulRequest from './SuccessfulRequest/SuccessfulRequest'
import Loading from '../../../../components/Loading/Loading'
import AddCarModal from '../../../../modal/PAModal/AddCarModal/AddCarModal'
import UserInformation from './UserInformation/UserInformation'
import CarParkPA from './CarParkPA/CarParkPA'
import Rattings from './Rattings/Rattings'
import Comments from './Comments/Comments'
import SuccesfullCreation from '../CreateOrder/SuccesfullCreation/SuccesfullCreation'

interface PersonalDataProps {
	open: boolean
}

const PersonalData: FC<PersonalDataProps> = ({ open }) => {
	const { error, loading, redirectTracks } = useAppSelector(state => state.user)
	const [changeUserData, setChangeUserData] = useState(false)
	const [changeComments, setChangeComments] = useState(false)
	const [carsPark, setCarsPark] = useState(false)
	useEffect(() => {
		error?.includes('Значения поля должны быть уникальны') &&
			setChangeUserData(true)
	}, [error])

	return (
		<div className={!open ? `${s.PersonalData_open} ${s.PersonalData}` : s.PersonalData}>
			<UserInformation setChangeUserData={setChangeUserData} />
			<div className={s.other_data}>
				<CarParkPA setCarsPark={setCarsPark} />
				<div className={s.additional_info}>
					<Rattings />
					<Comments setChangeComments={setChangeComments} changeComments={changeComments} />
				</div>
			</div>
			{changeUserData && (<ChangingUserData changeUserData={changeUserData} setChangeUserData={setChangeUserData} />)}
			{(redirectTracks || error?.includes('Упс что то пошло не так попробуйте снова!')) && <SuccessfulRequest />}
			{carsPark && (<AddCarModal carsPark={carsPark} setCarsPark={setCarsPark} />)}
			{loading && <Loading />}
		</div >
	)
}

export default PersonalData
