import React, { FC } from 'react';
import PaymentHistory from './PaymentHistory/PaymentHistory';
import HistoryOfOrders from './HistoryOfOrders/HistoryOfOrders';
import CreateOrder from './CreateOrder/CreateOrder';
import Document from './Document/Document';
import CarPark from './CarPark/CarPark';
import PersonalData from './PersonalData/PersonalData';
import s from './Output.module.scss'


interface OutputProps {
    value: string
    open: boolean
}
const Output: FC<OutputProps> = ({ value, open }) => {
    return (
        <div className={s.Output}>
            {
                value.includes('payment_history') ? <PaymentHistory /> :
                    value.includes('order_history') ? < HistoryOfOrders /> :
                        value.includes('create_cargo_order') ? <CreateOrder value={value} /> :
                            value.includes('create_car_order') ? <CreateOrder value={value} /> :
                                value.includes('documents') ? < Document /> :
                                    value.includes('car_park') ? < CarPark value={value} /> :
                                        value.includes('detail_card') ? < CarPark value={value} /> :
                                            value.includes('personal_area') ? <PersonalData open={open} /> : ''
            }
        </div>
    );
};

export default Output;