import React, { FC } from 'react';
import s from './HeaderTop.module.scss'
import tochka from '../../../assets/Header/tochka.png';
import mail from '../../../assets/Header/mail.png';
import WhatsApp from '../../../assets/Header/WhatsApp.png';
import Telegram from '../../../assets/Header/Telegram.png';
import instagram from '../../../assets/Header/instagram.png';
import { Link } from 'react-router-dom';

const HeaderTop: FC = () => {
    return (
        <div className={s.header_top}>
            <div className={s.navbar}>
                <div className={s.box}>
                    <div className={s.address}>
                        <img src={tochka} alt="dot" />
                        <p>г. Бишкек</p>
                        <p>Ул. Пригородная-2я. Дом 55 </p>
                    </div>
                    <div className={s.mail_address}>
                        <Link target='_blank' to="mailto:/jukkg24@gmail.com" className={s.mail}>
                            <img src={mail} alt="mail" />
                            <p>jukkg24@gmail.com</p>
                        </Link>
                        <div className={s.ikons}>
                            <Link target='_blank' to="https://wa.me/996555888565?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.">
                                <img src={WhatsApp} alt="WhatsApp" />
                            </Link>
                            <Link target='_blank' to="https://t.me/jukkg24?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.">
                                <img src={Telegram} alt="Telegram" />
                            </Link>
                            <Link target='_blank' to={'https://www.instagram.com/p/C5YCSB5NWn0/?igsh=N25xcG8yOTI4d2g2'}>
                                <img src={instagram} alt="instagram" />
                            </Link>
                            {/* <Link target='_blank' to={'https://taplink.cc/juk.kg'}>
                                <p>TapLink</p>
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderTop;