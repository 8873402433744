import React, { ChangeEventHandler, FC, FormEventHandler, useEffect, useState, } from 'react'
import s from './CreateCargoOrder.module.scss'
import CargoCard from './CargoCard/CargoCard'
import WhenCard from './WhenCard/WhenCard'
import LoadingCard from './LoadingCard/LoadingCard'
import UnloadingCard from './UnloadingCard/UnloadingCard'
import BodyCard from './BodyCard/BodyCard'
import AdditionalInfo from './AdditionalInfo/AdditionalInfo'
import NoteCard from './NoteCard/NoteCard'
import { CargoCreation, ErrorText, LoadingType } from '../../../../../store/modules'
import { useAppDispatch, useAppSelector, } from '../../../../../store/hooks/hooks'
import { fetchByDataForCargoCreation } from '../../../../../store/slice/personalDataSlice'
import SuccesfullCreation from '../SuccesfullCreation/SuccesfullCreation'
import Loading from '../../../../../components/Loading/Loading'
import { clearForm } from '../../../../../other'

const CreateCargoOrder: FC = () => {
	const { token, user } = useAppSelector(state => state.user)
	const { loading, error, dataCreation } = useAppSelector(state => state.personalArea)
	const [file, setFile] = useState<File | null>(null)
	const [currency, setCurrency] = useState('Сом')
	const [price, setPrice] = useState('Договорная')
	const dispatch = useAppDispatch()
	const [fromWhere, setFromWhere] = useState('')
	const [toWhere, setToWhere] = useState('')
	const [errorText, setErrorText] = useState<ErrorText>({
		all: '',
		weight: '',
		volume: '',
		lifting_capacity: '',
	})
	const [loadings, setLoadings] = useState<LoadingType[]>([])
	const [unLoading, setUnLoading] = useState<LoadingType[]>([])
	const [dop, setDop] = useState('')
	const [cargoCreation, setCargoCreation] = useState<CargoCreation>({
		type_cargo: '',
		type_body: '',
		weight: '',
		weight_unit: 'ton',
		volume: '',
		length: '',
		width: '',
		height: '',
		readiness: 'Всегда',
		date: '',
		work_day: '',
		city_country: '',
		address: '',
		time_loading: '',
		city_country_u: '',
		address_u: '',
		time_unloading: '',
		body: '',
		loading: '',
		unloading: '',
		payment: '',
		note: '',
		name: '',
		phone_number: '',
		price: '',
		user: null,
		image: null,
		telegram: '',
		whatsapp: '',
		additionally: '',
		viber: '',
		loading_address1: null,
		loading_address2: null,
		loading_address3: null,
		loading_address4: null,
		loading_address5: null,
		unloading_address1: null,
		unloading_address2: null,
		unloading_address3: null,
		unloading_address4: null,
		unloading_address5: null,
	})

	const getCargoCreation = (key: string, value: string) => {
		setCargoCreation({ ...cargoCreation, [key]: value })
	}

	const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
		if (e.target.files) {
			setFile(e.target.files[0])
		}
	}

	if (errorText.all.includes('Вы не выбрали груз!')) {
		cargoCreation.type_cargo && setErrorText({ ...errorText, all: '' })
	} else if (errorText.all.includes('Вы не указали вес!')) {
		cargoCreation.weight && setErrorText({ ...errorText, all: '' })
	} else if (errorText.all.includes('Вы не указали объём!')) {
		cargoCreation.volume && setErrorText({ ...errorText, all: '' })
	} else if (errorText.all.includes('Вы не указали тип прицепа!')) {
		cargoCreation.body && setErrorText({ ...errorText, all: '' })
	} else if (errorText.all.includes('Вы не указали город отбывание!')) {
		fromWhere && setErrorText({ ...errorText, all: '' })
	} else if (errorText.all.includes('Вы не указали город прибывание!')) {
		toWhere && setErrorText({ ...errorText, all: '' })
	} else if (errorText.weight.includes('Вес должен быть менее 60т!')) {
		+cargoCreation.weight <= 60 && setErrorText({ ...errorText, weight: '' })
	} else if (errorText.volume.includes('Объём должно быть менее 200м³!')) {
		+cargoCreation.volume <= 200 && setErrorText({ ...errorText, volume: '' })
	}

	const handleChangeUserData: FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault()
		if (token && cargoCreation.type_cargo && cargoCreation.body && (cargoCreation.weight && +cargoCreation.weight >= 0.2 && +cargoCreation.weight <= 60) && (cargoCreation.volume && +cargoCreation.volume >= 1 && +cargoCreation.volume <= 200) && fromWhere && toWhere) {
			dispatch(fetchByDataForCargoCreation({
				token,
				cargoCreation: { ...cargoCreation, city_country: fromWhere, city_country_u: toWhere, additionally: cargoCreation.additionally.includes('Несколько авто') ? `Несколько авто ${dop}` : cargoCreation.additionally, image: file, price: (price.includes('Договорная') ? 'Договорная' : `${price} ${currency}`) },
			}))
			// console.log({ ...cargoCreation, city_country: fromWhere, city_country_u: toWhere, additionally: cargoCreation.additionally.includes('Несколько авто') ? `Несколько авто ${dop}` : cargoCreation.additionally, images: [{ image: file }], price: (price.includes('Договорная') ? 'Договорная' : `${price} ${currency}`) })
		} else if (!cargoCreation.type_cargo) {
			setErrorText({ ...errorText, all: 'Вы не выбрали груз!' })
		} else if (!cargoCreation.weight && +cargoCreation.weight < 0.2) {
			setErrorText({ ...errorText, all: 'Вы не указали вес!' })
		} else if (!cargoCreation.volume) {
			setErrorText({ ...errorText, all: 'Вы не указали объём!' })
		} else if (!cargoCreation.body) {
			setErrorText({ ...errorText, all: 'Вы не указали тип прицепа!' })
		} else if (!fromWhere) {
			setErrorText({ ...errorText, all: 'Вы не указали город отбывание!' })
		} else if (!toWhere) {
			setErrorText({ ...errorText, all: 'Вы не указали город прибывание!' })
		}
	}

	useEffect(() => {
		loadings.length > 0 && loadings.map((el, i) => {
			setCargoCreation({ ...cargoCreation, [`loading_address${i + 1}`]: JSON.stringify(el) })
		})
	}, [loadings])
	useEffect(() => {
		unLoading.length > 0 && unLoading.map((el, i) => {
			setCargoCreation({ ...cargoCreation, [`unloading_address${i + 1}`]: JSON.stringify(el) })
		})
	}, [unLoading])

	useEffect(() => {
		user && setCargoCreation({ ...cargoCreation, user: user?.id, name: user?.username, phone_number: user?.phone, })
	}, [user])

	useEffect(() => {
		if (cargoCreation.weight_unit.includes('ton') && +cargoCreation.weight > 60) {
			setErrorText({ ...errorText, weight: 'Вес должен быть менее 60т!' })
		}
		if (+cargoCreation.volume > 200) {
			setErrorText({ ...errorText, volume: 'Объём должно быть менее 200м³!' })
		}
	}, [cargoCreation.weight, cargoCreation.volume])

	return (
		<div className={s.CreateCargoOrder}>
			<div className={s.add_cargo}>
				<h2>Добавить груз</h2>
				<button onClick={clearForm}>Очистить форму</button>
			</div>
			<form className={s.form_create_cargo} onSubmit={handleChangeUserData}>
				<div className={s.field_to_fill}>
					<CargoCard cargoCreation={cargoCreation} errorText={errorText} getCargoCreation={getCargoCreation} />
					<BodyCard cargoCreation={cargoCreation} errorText={errorText} getCargoCreation={getCargoCreation} />
					<WhenCard errorText={errorText} getCargoCreation={getCargoCreation} cargoCreation={cargoCreation} />
					<LoadingCard loadings={loadings} setLoadings={setLoadings} cargoCreation={cargoCreation} setFromWhere={setFromWhere} errorText={errorText} getCargoCreation={getCargoCreation} />
					<UnloadingCard unLoading={unLoading} setUnLoading={setUnLoading} cargoCreation={cargoCreation} setToWhere={setToWhere} getCargoCreation={getCargoCreation} errorText={errorText} />
					<AdditionalInfo setDop={setDop} setPrice={setPrice} price={price} setCurrency={setCurrency} currency={currency} cargoCreation={cargoCreation} getCargoCreation={getCargoCreation} errorText={errorText} />
					<NoteCard file={file} handleChange={handleChange} cargoCreation={cargoCreation} getCargoCreation={getCargoCreation} errorText={errorText} />
				</div>
				<div className={s.buttons}>
					<h5 className={s.error_text}>{errorText.weight ? errorText.weight : errorText.volume ? errorText.volume : errorText.all ? errorText.all : ''}</h5>
					<button>Опубликовать Груз</button>
				</div>
			</form>
			{(error?.includes('Упс что то пошло не так попробуйте снова!') || error?.includes('Данные не правильно введены!') || dataCreation) && (
				<SuccesfullCreation text={'Груз успешно создан!'} />
			)}
			{loading && <Loading />}
		</div>
	)
}

export default CreateCargoOrder
