import React, { FC, useEffect } from 'react';
import s from './OpenImgCargo.module.scss'

interface OpenAvatarProps {
    setSwitchAvatar: (e: boolean) => void
    switchAvatar: boolean
    image: string
}
const OpenImgCargo: FC<OpenAvatarProps> = ({ setSwitchAvatar, switchAvatar, image }) => {
    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && setSwitchAvatar(false)
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (switchAvatar) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => setSwitchAvatar(false);
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!switchAvatar) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])

    return (
        <div onClick={() => setSwitchAvatar(false)} className={s.open_avatar}>
            <div onClick={(e) => e.stopPropagation()} className={s.when_open_avatar}>
                <img src={(image && image.startsWith('http')) ? `https${image.slice(4)}` : ''} alt="avatar" />
            </div>
        </div>
    );
};

export default OpenImgCargo;