import React, { FC, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './SwiperHomeFirst.css'
import { Pagination, Autoplay, Navigation, Keyboard } from 'swiper/modules';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { fetchByHomeAddDown } from '../../../../store/slice/homeSlice';

const SwiperHomeFirst: FC = () => {

    const dispatch = useAppDispatch()
    const { homeAddDown } = useAppSelector(state => state.home)
    useEffect(() => {
        dispatch(fetchByHomeAddDown())
    }, [dispatch])

    return (
        <div className='containerSwiperHome'>
            <Swiper
                slidesPerView={window.screen.width <= 768 ? 2 : 4}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                }}
                mousewheel={true}
                navigation={true}
                keyboard={true}
                autoplay={{
                    delay: 2500,
                    pauseOnMouseEnter: true,
                }}
                modules={[Pagination, Navigation, Keyboard, Autoplay]}
                className="mySwiper2"
            >
                {homeAddDown.map((el, i) => (
                    <SwiperSlide key={el.id}>
                        <a target='blank' href={el.link}>
                            <img className='img_in_swiper' src={(el.image && el.image.startsWith('http')) ? `https${el.image.slice(4)}` : ''} alt="img" />
                        </a>
                    </SwiperSlide>
                ))
                }
            </Swiper>
        </div >

    );
};

export default SwiperHomeFirst;