import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NewsData } from "../modules";
import { authApi } from "../../axios";

type NewsState = {
    loading: boolean
    error: null | string
    news: NewsData[]
}

const initialState: NewsState = {
    error: null,
    loading: false,
    news: [],
}

export const fetchByAllNews = createAsyncThunk<NewsData[], void, { rejectValue: string }>(
    'news/fetchByAllNews',
    async (_, { rejectWithValue }) => {
        const res = await authApi.getAllNews()
        // console.log(res);
        if (res.status !== 200) {
            return rejectWithValue('Server Error')
        }
        return res.data.results
    }
)

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        togglePagNews(state, action: PayloadAction<NewsData[]>) {
            state.news = action.payload
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchByAllNews.pending, (state) => {
            state.loading = true
            state.error = null
        })

        addCase(fetchByAllNews.fulfilled, (state, action) => {
            state.news = action.payload.reverse()
            state.loading = false
        })

        addCase(fetchByAllNews.rejected, (state, action) => {
            state.loading = false
            if (action.error.message?.includes('40')) {
                state.error = 'К сожалению новостей нет!'
            }
            else if (action.error.message?.includes('50')) {
                state.error = 'Сервер на данный момент не работает!'
            }
        })
    }
})

export const {
    togglePagNews
} = newsSlice.actions
export default newsSlice.reducer
