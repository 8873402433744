import React, { FC } from 'react';
import s from './CargoCard.module.scss'
import { popularCargo, typeOfCargo } from '../../assets';
import { CargoCreation, ErrorText } from '../../../../../../store/modules';
interface CargoCardProps {
    getCargoCreation: (key: string, value: string) => void
    errorText: ErrorText
    cargoCreation: CargoCreation
}
const CargoCard: FC<CargoCardProps> = ({ errorText, getCargoCreation, cargoCreation }) => {
    return (
        <div className={s.CargoCard}>
            <h2 className={s.title_cargo}><span>&#9913;</span>Груз</h2>
            <div className={s.input_field}>
                <div className={s.required_fields}>
                    <select value={cargoCreation.type_cargo} onChange={(e) => getCargoCreation('type_cargo', e.target.value)} className={errorText.all.includes('Вы не выбрали груз!') ? `${s.name_cargo} ${s.errorText}` : s.name_cargo}>
                        <option disabled value="">Выбрать груз</option>
                        <option disabled className={s.chapter} value="">Популярные</option>
                        {popularCargo.map((el, i) => <option key={i} value={el}>{el} </option>)}
                        <option disabled className={s.chapter} value="">ВСЕ</option>
                        {typeOfCargo.map((el, i) => <option key={i} value={el}>{el} </option>)}
                    </select>
                    <div className={s.add_info}>
                        <input onChange={(e) => getCargoCreation('weight', e.target.value)} className={errorText.all.includes('Вы не указали вес!') ? `${s.weight} ${s.errorText}` : errorText.weight.includes('Вес должен быть менее 60') ? `${s.weight} ${s.errorText}` : s.weight} type="number" placeholder='Вес' />
                        <p className={s.unit}>T</p>
                        <input className={errorText.all.includes('Вы не указали объём!') ? `${s.volume} ${s.errorText}` : errorText.volume.includes('Объём должно быть менее 200м³!') ? `${s.volume} ${s.errorText}` : s.volume} value={cargoCreation.volume} onChange={(e) => getCargoCreation('volume', e.target.value)} type="number" placeholder='до 200м³' />
                    </div>
                </div>
                <div className={s.not_required_fields}>
                    <div className={s.description}><h2>Габариты Д/Ш/В</h2></div>
                    <div className={s.inputs}>
                        <input value={cargoCreation.length} onChange={e => getCargoCreation('length', e.target.value)} type="number" placeholder='до 15м' />
                        <input value={cargoCreation.width} onChange={e => getCargoCreation('width', e.target.value)} type="number" placeholder='до 4м' />
                        <input value={cargoCreation.height} onChange={e => getCargoCreation('height', e.target.value)} type="number" placeholder='до 4м' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CargoCard;