import React, { FC } from 'react'
import s from '../PersonalData.module.scss'
import { useAppSelector } from '../../../../../store/hooks/hooks'
import pen from '../../../../../assets/PersonalArea/pen.png'
import empty_avatar from '../../../../../assets/PersonalArea/empty_avatar.png'
import viber from '../../../../../assets/DetailView/viber.png'
import whatsapp from '../../../../../assets/Footer/whatsapp.png'
import instagram from '../../../../../assets/Footer/instagram.png'

interface UserInformationProps {
	setChangeUserData: (e: boolean) => void
}
const UserInformation: FC<UserInformationProps> = ({ setChangeUserData }) => {
	const { user } = useAppSelector(state => state.user)

	return (
		<div className={s.only_user_information}>
			<div className={s.user_information}>
				<div className={s.title_user_information}>
					<h2>Профиль пользователя</h2>
					<img onClick={() => setChangeUserData(true)} src={pen} alt='ikon' />
				</div>
				<div className={s.personal_data}>
					<div className={s.user_data}>
						<h3>Имя </h3>
						<p>{user?.username} </p>
					</div>
					<div className={s.user_data}>
						<h3>Страна</h3>
						<p>{user?.country ? user?.country : 'Страна не указано'} </p>
					</div>
					<div className={s.user_data}>
						<h3>Город</h3>
						<p>{user?.city ? user?.city : 'Город не указано'} </p>
					</div>
					<div className={s.user_data}>
						<h3>Email</h3>
						<p>{user?.email} </p>
					</div>
					<div className={s.user_data}>
						<h3>Телефон</h3>
						<p>{user?.phone} </p>
					</div>
					<div className={s.ikons}>
						<img src={viber} alt="viber" /><img src={whatsapp} alt="whatsapp" /><img src={instagram} alt="instagram" />
					</div>
				</div>
			</div>
			<div className={s.avatar_field}>
				<div className={s.avatar}>
					<img src={(user?.img && (user?.img.startsWith('http')) && !user.img.includes('profile/')) ? `https${user?.img.slice(4)}` : empty_avatar} alt='avatar' />
				</div>
			</div>
		</div>
	)
}

export default UserInformation
