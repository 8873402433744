import React, { FC, useState } from 'react';
import s from './LoadingCard.module.scss'
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { CargoCreation, ErrorText, LoadingType } from '../../../../../../store/modules';
import { options } from '../../../../../../other';
interface LoadingCardProps {
    getCargoCreation: (key: string, value: string) => void
    setLoadings: (e: LoadingType[]) => void
    loadings: LoadingType[]
    errorText: ErrorText
    setFromWhere: (e: string) => void
    cargoCreation: CargoCreation
}
const LoadingCard: FC<LoadingCardProps> = ({ cargoCreation, errorText, getCargoCreation, setFromWhere, loadings, setLoadings }) => {
    const [addAddress, setAddAddress] = useState(false)
    const [loadingType, setLoadingType] = useState<LoadingType>({
        city_country: '',
        address: '',
        time: '',
    })
    const handleOk = () => {
        if (loadingType.address || loadingType.city_country || loadingType.time) {
            setLoadings([...loadings, loadingType])
            setLoadingType({ ...loadingType, address: '', time: '' })
        }
    }
    const handleDelete = (index: number) => {
        setLoadings([...loadings.filter((el, i) => i !== index)])
    }

    return (
        <div className={s.LoadingCard}>
            <h2 className={s.title_loading}><span>&#9913;</span>Загрузка</h2>
            <div className={s.field_for_info}>
                <div className={s.input_field}>
                    <div className={s.address}>
                        <ReactGoogleAutocomplete
                            options={options}
                            className={errorText.all.includes('Вы не указали город отбывание!') ? `${s.country_city} ${s.errorText} ` : s.country_city}
                            onPlaceSelected={(place) => setFromWhere(place.formatted_address)}
                            language='ru'
                        />
                        <input value={cargoCreation.address} onChange={e => getCargoCreation('address', e.target.value)} type="text" placeholder='Адрес в населенном пункте' />
                    </div>
                    <div className={s.loading_info}>
                        <div className={s.loading_time}>
                            <h2>Время Загрузки</h2>
                            <input value={cargoCreation.time_loading} onChange={e => getCargoCreation('time_loading', e.target.value)} type="time" />
                        </div>
                        <span onClick={() => setAddAddress(true)} className={addAddress ? s.add_address_none : s.add_address} >Добавить адрес</span>
                    </div>
                </div>
                <div className={addAddress ? s.input_field : s.input_field_none}>
                    <div className={s.address}>
                        <ReactGoogleAutocomplete
                            options={options}
                            className={s.country_city}
                            onPlaceSelected={(place) => setLoadingType((prev) => ({ ...prev, city_country: place.formatted_address }))}
                            language='ru'
                        />
                        <input value={loadingType.address} onChange={e => setLoadingType({ ...loadingType, address: e.target.value.trimStart() })} type="text" placeholder='Адрес в населенном пункте' />
                    </div>
                    <div className={s.loading_info}>
                        <div className={s.loading_time}>
                            <h2>Время Загрузки</h2>
                            <input value={loadingType.time} onChange={e => setLoadingType({ ...loadingType, time: e.target.value.trimStart() })} type="time" />
                        </div>
                        {loadings.length < 5 && <span onClick={handleOk} className={s.add_address} >Добавить</span>}
                    </div>
                    {loadings.map((el, i) => (
                        <div className={s.card_places} key={i}>
                            <span onClick={() => handleDelete(i)} className={s.closed}>&#10006;</span>
                            <span className={s.bird}>✓</span>
                            <div className={s.places}>
                                <p className={s.title_in_places}><span className={s.span_in_places}>Страна,город:</span> {el.city_country ? el.city_country : 'не указано'} </p>
                                <p className={s.title_in_places}><span className={s.span_in_places}>Адрес:</span> {el.address ? el.address : 'адрес не указано'} </p>
                                <p className={s.title_in_places}><span className={s.span_in_places}>Время загрузки:</span> {el.time ? el.time : 'время не указано'} </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LoadingCard;