import React, { FC, useEffect, useState } from 'react';
import s from './Document.module.scss'
import ShowDocument from './ShowDocument/ShowDocument';
import AddDocument from './AddDocument/AddDocument';
import AddDocumentModal from '../../../../modal/PAModal/AddDocument/AddDocument';
import { fetchByGetDocument } from '../../../../store/slice/personalDataSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import Loading from '../../../../components/Loading/Loading';

const Document: FC = () => {
    const [addDoc, setAddDoc] = useState(false)
    const dispatch = useAppDispatch()
    const { user } = useAppSelector(state => state.user)
    const { documents, loading, error, loadingPlace } = useAppSelector(state => state.personalArea)
    useEffect(() => {
        if (error?.includes('Упс что то пошло не так!')) {
            setAddDoc(true)
        }
    }, [error])
    useEffect(() => {
        user?.id &&
            dispatch(fetchByGetDocument(user.id))
    }, [dispatch, user?.id, loadingPlace])
    return (
        <div className={s.Document}>
            {documents.length > 0 ? <ShowDocument setAddDoc={setAddDoc} /> : <AddDocument setAddDoc={setAddDoc} />}
            {addDoc && <AddDocumentModal addDoc={addDoc} setAddDoc={setAddDoc} />}
            {loading && <Loading />}
        </div>
    );
};

export default Document;