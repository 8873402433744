import React, { FC } from 'react';
import s from './BodyCard.module.scss'
import { typeLoadingBoxTrailer, typeLoadingFlatbedTrailer, typeLoadingLowboyTrailer, typeLoadingRefrigeratedTrailer, typeOfTrailer } from '../../assets';
import { CargoCreation, ErrorText } from '../../../../../../store/modules';
interface BodyCardProps {
    getCargoCreation: (key: string, value: string) => void
    cargoCreation: CargoCreation
    errorText: ErrorText
}
const BodyCard: FC<BodyCardProps> = ({ errorText, getCargoCreation, cargoCreation }) => {

    return (
        <div className={s.BodyCard}>
            <h2 className={s.title_body}><span>&#9913;</span>Прицеп</h2>
            <div className={s.paid_info}>
                <select value={cargoCreation.body} className={errorText.all.includes('Вы не указали тип прицепа!') ? s.errorText : ''} onChange={(e) => getCargoCreation('body', e.target.value)} >
                    <option disabled value=''>Тип прицепа</option>
                    {
                        typeOfTrailer.map((el, i) => (<option key={i} value={el}>{el} </option>))
                    }
                </select>
                {(cargoCreation.body && !cargoCreation.body.includes('Автовоз')) && !cargoCreation.body.includes('Рефрижератор') && <select onChange={(e) => getCargoCreation('type_body', e.target.value)} className={s.active_select}>
                    {cargoCreation.body.includes('Крытое') && typeLoadingBoxTrailer.map((el, i) => <option key={i} value={el}>{el} </option>)}
                    {cargoCreation.body.includes('Открытое') && typeLoadingRefrigeratedTrailer.map((el, i) => <option key={i} value={el}>{el} </option>)}
                    {cargoCreation.body.includes('Цистерна') && typeLoadingFlatbedTrailer.map((el, i) => <option key={i} value={el}>{el} </option>)}
                    {cargoCreation.body.includes('Спец техника') && typeLoadingLowboyTrailer.map((el, i) => <option key={i} value={el}>{el} </option>)}
                </select>}
            </div>
        </div>
    );
};

export default BodyCard;