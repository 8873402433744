import React, { FC, useEffect } from 'react';
import s from './SuccessfulRequest.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hooks';
import { fetchByUserData, setErrorChange, toggleRedirectTracks } from '../../../../../store/slice/userSlice';

const SuccessfulRequest: FC = () => {
    const dispatch = useAppDispatch()
    const { redirect, token, error } = useAppSelector(state => state.user)
    useEffect(() => {
        return () => {
            if (token) {
                dispatch(fetchByUserData(token))
            }
        }
    }, [])

    const handleChangeRedirect = () => {
        dispatch(toggleRedirectTracks(false))
        dispatch(setErrorChange(null))
    }
    useEffect(() => {
        // При рождении убрать скрол
        document.body.style.overflow = 'hidden'
        // При нажатии на ESC закрыть модальное окно
        document.addEventListener('keydown', (e) => {
            e.code === 'Escape' && handleChangeRedirect()
        })
        // При рождении навесит другое событие на кнопку назад у браузера
        if (redirect) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = () => handleChangeRedirect();
        }
        return () => {
            // При закрытии  модального окна вернуть скролл
            document.body.style.overflow = 'auto'
            // При закрытии убрать действия с кнопки ESC
            document.removeEventListener('keydown', () => { })
            // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
            if (!redirect) window.history.back();
            window.onpopstate = () => { };
        }
    }, [])
    return (
        <div onClick={handleChangeRedirect} className={s.SuccessfulRequest}>
            <div onClick={(e) => e.stopPropagation()} className={error?.includes('Упс что то пошло не так попробуйте снова!') ? s.error_div : s.card_succ}>
                <span onClick={handleChangeRedirect} className={s.closed}>&#10006;</span>
                {error?.includes('Упс что то пошло не так попробуйте снова!') ? <h2 className={s.error_text}>{error}</h2> :
                    <h2 className={s.title}>Вы успешно стали грузоперевозчиком</h2>}
            </div>
        </div>
    );
};

export default SuccessfulRequest;