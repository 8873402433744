import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DeletedDocument, OrderStatus } from '../modules'
import { authApi } from '../../axios'

type NewsState = {
	loading: boolean
	error: null | string | undefined
	comentsStatus: OrderStatus[]
}

const initialState: NewsState = {
	error: null,
	loading: false,
	comentsStatus: [],
}

export const fetchByDetailOrder = createAsyncThunk<
	OrderStatus[],
	DeletedDocument,
	{ rejectValue: string }
>('comentsStatus/fetchByDetailView', async (orderHis, { rejectWithValue }) => {
	const res = await authApi.getOrdersStatus(orderHis)
	// console.log(res)
	if (res.status !== 200) {
		return rejectWithValue('Server Error')
	}
	return res.data.results
})

const comentsStatus = createSlice({
	name: 'comentsStatus',
	initialState,
	reducers: {},
	extraReducers: ({ addCase }) => {
		addCase(fetchByDetailOrder.pending, state => {
			state.loading = true
			state.error = null
		})

		addCase(fetchByDetailOrder.fulfilled, (state, action) => {
			state.comentsStatus = action.payload.reverse()
			state.loading = false
		})

		addCase(fetchByDetailOrder.rejected, (state, action) => {
			state.loading = false
			if (action.payload?.includes('404')) {
				state.error = 'No Broouuuu,No News!'
			} else {
				state.error = action.payload
			}
		})
	},
})

export default comentsStatus.reducer
