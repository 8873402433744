import React, { FC } from 'react';
import s from './Navigate.module.scss'
import { Link, NavLink } from 'react-router-dom';
import juk from '../../../assets/Header/Group.png';

const Navigate: FC = () => {

    const onClick = () => {
        window.scrollTo({
            top: 4000,
            behavior: "smooth",
        });
    }

    return (
        <div className={s.navigate}>
            <Link className={s.fame_logo} to={'/'}>
                <img className={s.logo} src={juk} alt="logo" /> </Link>
            <nav className={s.nav}>
                <ul className={s.ul}>
                    <li ><NavLink className={({ isActive }) =>
                        isActive ? `${s.avtive} ${s.menu_item}` : `${s.menu_item}`
                    } to={'/'} >Главная</NavLink></li>
                    <span></span>
                    <li><NavLink className={({ isActive }) =>
                        isActive ? `${s.avtive} ${s.menu_item}` : `${s.menu_item}`
                    } to={'/cargo'} >Грузы</NavLink></li>
                    <span></span>
                    <li><NavLink className={({ isActive }) =>
                        isActive ? `${s.avtive} ${s.menu_item}` : `${s.menu_item}`
                    } to={'/cars'} >Транспорты</NavLink></li>
                    <span></span>
                    <li><NavLink className={({ isActive }) =>
                        isActive ? `${s.avtive} ${s.menu_item}` : `${s.menu_item}`
                    } to={'/news'}>Новости</NavLink></li>
                    <span></span>
                    <li><NavLink className={({ isActive }) =>
                        isActive ? `${s.avtive} ${s.menu_item}` : `${s.menu_item}`
                    } to={'/tariff'} >Тарифы</NavLink></li>
                    <span></span>
                    <li onClick={onClick}><p className={s.menu_item}>Контакты</p></li>
                </ul>
            </nav>
        </div>
    );
};

export default Navigate;