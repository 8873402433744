import React, {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import s from './EditCar.module.scss'
import photo from '../../../assets/PersonalArea/photo.png'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks'
import { AddCar, GetAutoPark } from '../../../store/modules'
import { fetchByDataForChangeCreation, fetchByGetBrand, setErrorChange, } from '../../../store/slice/personalDataSlice'
import Loading from '../../../components/Loading/Loading'
import { typeOfTrailer } from '../../../pages/PersonalArea/Output/CreateOrder/assets'
import SuccesfullCreation from '../../../pages/PersonalArea/Output/CreateOrder/SuccesfullCreation/SuccesfullCreation'
interface EditCarProps {
  setCarsPark: (e: boolean) => void
  carsPark: boolean
  detailCar: GetAutoPark | null
}

const EditCar: FC<EditCarProps> = ({ carsPark, setCarsPark, detailCar }) => {
  const { brand, loading, error, changeAuto } = useAppSelector(
    state => state.personalArea
  )
  const { user, token } = useAppSelector(state => state.user)
  const filePicker = useRef<HTMLInputElement>(null)
  const [errorText, setErrorText] = useState<string>('')
  const dispatch = useAppDispatch()
  const [addCar, setAddCar] = useState<AddCar>({
    model: '',
    state_number: '',
    truck_user: user?.truck_user_data?.id
      ? user?.truck_user_data?.id
      : 0,
    brand: 0,
    type_truck: '',
    image: '',
    docs: '',
  })
  useEffect(() => {
    detailCar &&
      setAddCar({ ...addCar, model: detailCar.model, type_truck: detailCar.type_truck, state_number: detailCar.state_number, brand: +detailCar.brand })
  }, [])
  const [file, setFile] = useState<File | string>('')
  useEffect(() => {
    dispatch(fetchByGetBrand())
  }, [dispatch])

  const getAddCar = (key: string, value: string) => {
    setAddCar({ ...addCar, [key]: value.trimStart() })
  }
  // console.log(file)

  if (errorText.includes('Введите марку!')) {
    addCar.brand && setErrorText('')
  } else if (errorText.includes('Введите тип прицепа')) {
    addCar.type_truck && setErrorText('')
  } else if (errorText.includes('Введите модел марки')) {
    addCar.model && setErrorText('')
  } else if (errorText.includes('Введите гос номер')) {
    addCar.state_number && setErrorText('')
  }

  const handleAddCar: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()
    if (token && detailCar && addCar.model && addCar.state_number && addCar.type_truck && addCar.brand) {
      dispatch(fetchByDataForChangeCreation({ token: token, id: detailCar?.id }))
    } else if (!addCar.brand) {
      setErrorText('Введите марку!')
    } else if (!addCar.model) {
      setErrorText('Введите модел марки!')
    } else if (!addCar.type_truck) {
      setErrorText('Введите тип прицепа')
    } else if (!addCar.state_number) {
      setErrorText('Введите гос номер!')
    }
  }

  useEffect(() => {
    // При рождении убрать скрол
    document.body.style.overflow = 'hidden'
    // При нажатии на ESC закрыть модальное окно
    document.addEventListener('keydown', e => {
      e.code === 'Escape' && setCarsPark(false)
    })
    // При рождении навесит другое событие на кнопку назад у браузера
    if (carsPark) {
      window.history.pushState(null, '', window.location.href)
      window.onpopstate = () => setCarsPark(false)
    }
    return () => {
      // При закрытии  модального окна вернуть скролл
      document.body.style.overflow = 'auto'
      // При закрытии убрать действия с кнопки ESC
      document.removeEventListener('keydown', () => { })
      // При закрытии вернуть действие по умолчанию на кнопку назад в браузере
      if (!carsPark) window.history.back()
      window.onpopstate = () => { }
    }
  }, [])
  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }
  const handlePick = () => {
    if (filePicker.current) {
      // использование filePicker.current без предупреждений о типе
      filePicker.current.focus()
      filePicker.current.click()
    }
  }
  useEffect(() => {
    return () => {
      dispatch(setErrorChange(null))
    }
  }, [])

  return (
    <div onClick={() => setCarsPark(false)} className={s.AddCarModal}>
      <div onClick={e => e.stopPropagation()} className={s.card_AddCarModal}>
        <span onClick={() => setCarsPark(false)} className={s.closed}>
          &#10006;
        </span>
        <form className={s.form_AddCarModal} onSubmit={handleAddCar}>
          <div className={s.file_dowload}>
            <h2>Добавить машину</h2>
            <div className={s.pictures}>
              <input onChange={handleChange} className={s.hidden} type='file' ref={filePicker} accept='image/*,.png,.jpg,.jpeg,.gif,.wep' />
              <img onClick={handlePick} src={photo} alt='photocamera' />
            </div>
          </div>
          <div className={s.info_field}>
            <h2
              className={
                errorText.includes('Введите марку!') ? s.red_Text_brand : ''
              }
            >
              Введите Марку Машины
            </h2>
            <select value={addCar.brand} onChange={e => getAddCar('brand', e.target.value)}>
              <option value="0" disabled className={s.option_default}>Выбрать марка машины</option>
              {brand.length > 0 &&
                brand.map(el => (
                  <option value={el.id} key={el.id}>
                    {el.title}{' '}
                  </option>
                ))}
            </select>
          </div>
          <div className={s.info_field}>
            <h2
              className={
                errorText.includes('Введите модел марки')
                  ? s.red_Text_model
                  : ''
              }
            >
              Введите Модель Машины
            </h2>
            <input
              value={addCar.model}
              onChange={e => getAddCar('model', e.target.value)}
              type='text'
              placeholder='Модель'
            />
          </div>
          <div className={s.info_field}>
            <h2
              className={
                errorText.includes('Введите тип прицепа')
                  ? s.red_Text_model
                  : ''
              }
            >
              Введите тип прицепа
            </h2>
            <select value={addCar.type_truck} onChange={e => getAddCar('type_truck', e.target.value)}>
              <option value="" disabled className={s.option_default}>Выбрать марку машины</option>
              {typeOfTrailer.map((el, i) => (
                <option key={i} value={el}>
                  {el}{' '}
                </option>
              ))}
            </select>
          </div>
          <div className={s.info_field}>
            <h2
              className={
                errorText.includes('Введите гос номер') ? s.red_Text_nom : ''
              }
            >
              Введите Гос номер
            </h2>
            <input
              value={addCar.state_number}
              onChange={e => getAddCar('state_number', e.target.value)}
              type='text'
              placeholder='Гос номер '
            />
          </div>

          <button className={s.save_data}>Редактировать</button>
          {error?.includes('Упс что то пошло не так!') && (
            <h5 className={s.error_text}>{error}</h5>
          )}
        </form>
      </div>
      {loading && <Loading />}
      {(error?.includes('Упс что то пошло не так попробуйте снова!') || changeAuto) && (
        <SuccesfullCreation text={'Машина успешно редактирован!'} />
      )}
    </div>
  )
}

export default EditCar
