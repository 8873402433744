import React, { FC, useEffect } from 'react';
import s from './Tariff.module.scss'
import Price from './Price/Price';
import { Helmet } from 'react-helmet-async';
const Tariff: FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section className={s.Tariff}>
            <Helmet>
                <link rel='canonical' href='https://juk.kg/tariff' />
                <title>Тариф | Juk.kg </title>
            </Helmet>
            <div className={s.box}>
                <Price />
            </div>
        </section>
    )
};

export default Tariff;