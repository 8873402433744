import React, { FC, FormEventHandler, useEffect, useState } from 'react';
import s from './RegistrationForm.module.scss'
import InputText from './InputText/InputText';
import InputPassword from './InputPassword/InputPassword';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { UserData } from '../../../store/modules';
import { fetchByAddNewUser, setEnter, setErrorChange, setRegistLocalUser } from '../../../store/slice/userSlice';
import { validateEmail } from '../../../other';
import Login from '../../../modal/authOffice/Login/Login';
import RestorePassword from '../../../modal/authOffice/RestorePassword/RestorePassword';
import Confirmation from '../../../modal/authOffice/Confirmation/Confirmation';
import NewPassword from '../../../modal/authOffice/NewPassword/NewPassword';
import Loading from '../../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';

const RegistrationForm: FC = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { loading, error, registrationLocalUser, redirect, enter, restorePassword, confirmation, newPassword, } = useAppSelector(state => state.user)

    const [errorText, setErrorText] = useState('')
    const [userData, setUserData] = useState<UserData>({
        username: '',
        password: '',
        password2: '',
        email: '',
        phone: '',
    })
    const handleLogin = () => {
        dispatch(setEnter(!enter))
    }
    const [showPassword1, setShowPassword1] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)


    const getUserData = (key: string, value: string) => {
        if (key === 'email') {
            setUserData({ ...userData, [key]: value.trim().toLowerCase() })
        } else {
            setUserData({ ...userData, [key]: value.trim() })
        }
    }

    useEffect(() => {
        redirect && navigate('/', { replace: true })
    }, [redirect])

    useEffect(() => {
        if (registrationLocalUser !== null) {
            setUserData({ ...userData, 'username': registrationLocalUser.username, 'password': registrationLocalUser.password, 'password2': registrationLocalUser.password2, 'phone': registrationLocalUser.phone, 'email': registrationLocalUser.email })
        }
    }, [registrationLocalUser])

    if (errorText.includes('Введите username!')) {
        userData.username && setErrorText('')
    } else if (errorText.includes('Некоректный email!')) {
        !validateEmail(userData.email) && setErrorText('')
    } else if (errorText.includes('Введите номер!')) {
        userData.phone && setErrorText('')
    } else if (errorText.includes('Длина пароля должна')) {
        userData.password.length >= 8 && setErrorText('')
    } else if (errorText.includes('Пароли не совпадают')) {
        userData.password === userData.password2 && setErrorText('')
    }

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        // console.log(userData)
        if (!validateEmail(userData.email) && userData.password.length >= 8 && userData.password2.length >= 8 && userData.password === userData.password2 && userData.phone && userData.username) {
            dispatch(setRegistLocalUser(userData))
            dispatch(fetchByAddNewUser(userData))
        } else if (!userData.username) {
            setErrorText('Введите username!')
        } else if (!userData.phone) {
            setErrorText('Введите номер!')
        } else if (validateEmail(userData.email)) {
            setErrorText('Некоректный email!')
        } else if (userData.password.length < 8 && userData.password2.length < 8) {
            setErrorText('Длина пароля должна быть не менее 8 символов!')
        } else if (userData.password.length !== userData.password2.length) {
            setErrorText('Пароли не совпадают!')
        } else if (!validateEmail(userData.email)) {
            setErrorText('')
        }
    }

    useEffect(() => {
        if (error?.includes('Значения должны быть уникальны!')) {
            !registrationLocalUser?.username.includes(userData?.username) && dispatch(setErrorChange(null))
        }
    }, [error, userData.username])

    if (loading) {
        return <Loading />
    }

    return (
        <div className={s.RegistrationForm}>
            <form onSubmit={handleSubmit} className={s.form}>
                <h2 className={s.title}>Регистрация</h2>
                <InputText value={userData.username} getUserData={getUserData} keys={'username'} text={error?.includes('Значения должны быть уникальны!') ? error : 'Введите логин'} meaning={errorText.includes('Введите username') ? errorText : 'Логин'} />
                <InputText value={userData.phone} getUserData={getUserData} keys={'phone'} text={'Введите телефон'} meaning={errorText.includes('Введите номер!') ? errorText : 'Телефон'} />
                <InputText value={userData.email} getUserData={getUserData} keys={'email'} text={'Введите почту'} meaning={errorText.includes('Некоректный email') ? errorText : 'Email'} />
                <InputPassword value={userData.password} showPassword={showPassword1} setShowPassword={setShowPassword1} getUserData={getUserData} keys={'password'} text={errorText.includes('Длина пароля должна') ? errorText : 'Введите пароль'} meaning={'Пароль'} />
                <InputPassword value={userData.password2} showPassword={showPassword2} setShowPassword={setShowPassword2} getUserData={getUserData} keys={'password2'} text={errorText.includes('Пароли не совпадают') ? errorText : 'Повторите пароль'} meaning={'Пароль'} />
                <div className={s.error}>
                    <button>Зарегистрироваться</button>
                </div>
                <div className={s.question}>
                    <p>Вы уже зарегистрированы?</p>
                    <p className={s.pointer} onClick={handleLogin}>Войти</p>
                </div>
            </form>
            {enter && <Login />}
            {restorePassword && <RestorePassword />}
            {confirmation && <Confirmation />}
            {newPassword && <NewPassword />}
        </div>
    );
};

export default RegistrationForm;