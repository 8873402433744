import React, { FC } from 'react';
import s from './CardFree.module.scss'

const CardFree: FC = () => {
  return (
    <div className={s.CardFree}>
      <div className={s.in_card}>
        <h2>Бесплатно 6 месяцев</h2>
        <div className={s.card_price}>
          <span>$</span>
          <h2>0</h2>
          <div className={s.right_field}>
            <p>per user</p>
            <p>per month</p>
          </div>
        </div>
        <p>В связи с открытием 6 месяцев бесплатно</p>
        <button>Купить <span> </span></button>
      </div>
    </div>
  );
};

export default CardFree;