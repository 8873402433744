import React, { FC } from 'react';
import s from './NavigateCarsHome.module.scss'
import { Link } from 'react-router-dom';
import gruzOne from '../../../assets/Home/1Gruz.png'
import gruzTwo from '../../../assets/Home/2Gruz.png'
const NavigateCarsHome: FC = () => {
    return (
        <div className={s.box}>
            <div className={s.twoNavigate}>
                <div className={s.gruzOne}>
                    <div className={s.gruz_one}>
                        <img src={gruzOne} alt="фура" />
                    </div>
                    <div className={s.aaa}>
                        <p>Грузы в большом ассортименте
                            из разных стран.
                            На бирже зарегистрировано много грузовладельцев и экспедиторов — это настоящая база потенциальных партнеров.</p>
                        <Link to={'/cars'}>
                            <button>Найти транспорт</button>
                        </Link>
                    </div>
                </div>
                <div className={s.gruzTwo}>
                    <div className={s.bbb}>
                        <p>Прямой контакт с грузовладельцем
                            Общайтесь напрямую с грузовладельцем.
                            Договаривайтесь, оставляйте и получайте отзывы реальных людей.</p>
                        <Link to={'/cargo'}>
                            <button>Найти груз</button>
                        </Link>
                    </div>
                    <div className={s.gruz_two}>
                        <img src={gruzTwo} alt="фура" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavigateCarsHome;