import React, {
	ChangeEventHandler,
	FC,
	FormEventHandler,
	useEffect,
	useRef,
	useState,
} from 'react'
import s from './AddDocument.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks'
import { AddDocument } from '../../../store/modules'
import Loading from '../../../components/Loading/Loading'
import {
	fetchByAddDocs,
	setErrorChange,
} from '../../../store/slice/personalDataSlice'
import SuccesfullCreation from '../../../pages/PersonalArea/Output/CreateOrder/SuccesfullCreation/SuccesfullCreation'
interface AddDocumentModalProps {
	setAddDoc: (e: boolean) => void
	addDoc: boolean
}

const AddDocumentModal: FC<AddDocumentModalProps> = ({ addDoc, setAddDoc }) => {
	const { user, token } = useAppSelector(state => state.user)
	const filePicker = useRef<HTMLInputElement>(null)
	const { error, loading, dataCreation } = useAppSelector(
		state => state.personalArea
	)
	const [errorText, setErrorText] = useState<string>('')
	const dispatch = useAppDispatch()
	const [addDocs, setAddDocs] = useState<AddDocument>({
		document: '',
		title: '',
		user: user?.id ? +user?.id : 0,
	})
	const getAddDocs = (key: string, value: string) => {
		setAddDocs({ ...addDocs, [key]: value.trimStart() })
	}
	const [file, setFile] = useState<File | string>('')
	if (errorText.includes('Введите название!')) {
		addDocs.title && setErrorText('')
	} else if (errorText.includes('Выберите документ!')) {
		file && setErrorText('')
	}
	const handleAddDocs: FormEventHandler<HTMLFormElement> = e => {
		e.preventDefault()
		// console.log(addDocs)

		if (addDocs.title && addDocs.user && file && token) {
			dispatch(
				fetchByAddDocs({ token, addDocs: { ...addDocs, document: file } })
			)
		} else if (!addDocs.title) {
			setErrorText('Введите название!')
		} else if (!file) {
			setErrorText('Выберите документ!')
		}
	}
	useEffect(() => {
		// При рождении убрать скрол
		document.body.style.overflow = 'hidden'
		// При нажатии на ESC закрыть модальное окно
		document.addEventListener('keydown', e => {
			e.code === 'Escape' && setAddDoc(false)
		})
		// При рождении навесит другое событие на кнопку назад у браузера
		if (addDoc) {
			window.history.pushState(null, '', window.location.href)
			window.onpopstate = () => setAddDoc(false)
		}
		return () => {
			// При закрытии  модального окна вернуть скролл
			document.body.style.overflow = 'auto'
			// При закрытии убрать действия с кнопки ESC
			document.removeEventListener('keydown', () => { })
			// При закрытии вернуть действие по умолчанию на кнопку назад в браузере
			if (!addDoc) window.history.back()
			window.onpopstate = () => { }
		}
	}, [])

	const handlePick = () => {
		if (filePicker.current) {
			// использование filePicker.current без предупреждений о типе
			filePicker.current.focus()
			filePicker.current.click()
		}
	}
	useEffect(() => {
		return () => {
			dispatch(setErrorChange(null))
		}
	}, [])

	const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
		if (e.target.files) {
			setFile(e.target.files[0])
		} else {
			setFile('')
		}
	}
	return (
		<div onClick={() => setAddDoc(false)} className={s.AddDocument}>
			<div onClick={e => e.stopPropagation()} className={s.card_AddDocsModal}>
				<span onClick={() => setAddDoc(false)} className={s.closed}>
					&#10006;
				</span>
				<form className={s.form_AddDocsModal} onSubmit={handleAddDocs}>
					<div className={s.info_field}>
						<h2
							className={
								errorText.includes('Введите название') ? s.red_Text_model : ''
							}
						>
							Введите название документа
						</h2>
						<input
							className={s.text_field}
							value={addDocs.title}
							onChange={e => getAddDocs('title', e.target.value)}
							type='text'
							placeholder='Документ'
						/>
					</div>
					<div className={s.info_field}>
						<h2 className={errorText.includes('Выберите документ!') ? s.red_Text_model : ''}>Выберите подходящие документы</h2>
						<div className={s.file_input}>
							<input
								className={s.input_doc}
								onClick={handlePick}
								type='button'
								value={'Выберите файл'}
							/>
							{file ? <h2>файл загружен </h2> : ''}
							<input className={s.hidden} onChange={handleChange} ref={filePicker} type='file' accept='.docs, .pdf' />
						</div>
					</div>
					<button className={s.save_data}>Добавить</button>
					{error?.includes('Упс что то пошло не так!') && (
						<h5 className={s.error_text}>{error}</h5>
					)}
				</form>
				{(error?.includes('Упс что то пошло не так попробуйте снова!') || dataCreation) && (
					<SuccesfullCreation text={'Документ успешно создан!'} />
				)}
			</div>
			{loading && <Loading />}
		</div>
	)
}

export default AddDocumentModal
