import React, { FC } from 'react';
import s from './AddCar.module.scss'
import urban from '../../../../../assets/PersonalArea/urban.png'
interface AddCarProps {
    setCarsPark: (e: boolean) => void
}
const AddCar: FC<AddCarProps> = ({ setCarsPark }) => {
    return (
        <div className={s.AddCar}>
            <div className={s.block_in_AddCar}>
                <img src={urban} alt="photo" />
                <p>Добавьте свою первую машину прямо сейчас!
                    Не упустите шанс начать использовать все преимущества нашего сервиса.</p>
                <button onClick={() => setCarsPark(true)} className={s.add_car}>Добавить транспорт</button>
            </div>
        </div>
    );
};

export default AddCar;