import React, { FC } from 'react';
import s from './MobileFooter.module.scss'
import { Link } from 'react-router-dom';
import instagram from '../../../assets/Footer/instagram.png'
import whatsapp from '../../../assets/Footer/whatsapp.png'
import telegram from '../../../assets/Footer/telegram.png'
import tochka from '../../../assets/Footer/tochka.png'
import ikon from '../../../assets/Footer/ikon.png'
import phone from '../../../assets/Footer/phone.png'

interface MobileFooterProps {
    setPrivacyPolicy: (e: boolean) => void
    setTermsOfUse: (e: boolean) => void
}
const MobileFooter: FC<MobileFooterProps> = ({ setPrivacyPolicy, setTermsOfUse }) => {
    return (
        <footer className={s.mobile_footer}>
            <Link to={'/'}><img className={s.ikon} src={ikon} alt="logo" /> </Link>
            <div className={s.contacts}>
                <div className={s.phone_number}><img src={phone} alt="phone" /><a href="tel:+996555888565">+996 555 888 565</a></div>
                <div className={s.address}>
                    <img src={tochka} alt="dot" />
                    <p>г. Бишкек</p>
                    <p>Ул. Пригородная-2я. Дом 55</p>
                </div>
                <div className={s.mobile_ikons}>
                    <Link target='_blank' to='https://wa.me/996555888565?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.'>
                        <img src={whatsapp} alt='WhatsApp' />
                    </Link>
                    <Link target='_blank' to='https://t.me/jukkg24?text=Здравствуйте,%20я%20на%20счет%20груза\транспорта%20на%20сайте%20juk.kg.'>
                        <img src={telegram} alt='Telegram' />
                    </Link>
                    <Link target='_blank' to={'https://www.instagram.com/p/C5YCSB5NWn0/?igsh=N25xcG8yOTI4d2g2'}>
                        <img src={instagram} alt='instagram' />
                    </Link>
                    {/* <Link target='_blank' to={'https://taplink.cc/juk.kg'}>
                        <p>TapLink</p>
                    </Link> */}
                </div>
            </div>
            <div className={s.description}>
                <h3 onClick={() => setPrivacyPolicy(true)} >Политика конфиденциальность</h3>
                <h3 onClick={() => setTermsOfUse(true)} >Условия использования</h3>
            </div>
            <p>2024© Все права защищены</p>
        </footer>
    );
};

export default MobileFooter;